import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import { ContractsPage } from "../../../pages/OrganizationContracts";

export default function OrganizationContractsForm({ open, handleClose, rowData }) {
    return (
        <Dialog
            maxWidth="xl"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title" style={{ paddingBottom: "0px" }}>Organization {rowData ? " (" + rowData.org_name + ")" : ""}</DialogTitle>
            <DialogContent style={{ paddingBottom: "0px" }}>
                <ContractsPage rowData={rowData} />
            </DialogContent>
            <DialogActions style={{ marginBottom: "12px" }}>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "32px", color: "white" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
