import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, MenuItem, Button, Grid, Typography, ButtonGroup, Dialog, CardHeader, Autocomplete, IconButton, Tooltip } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Countries, ServiceStatus } from "../utils/Lookup";
import { GroupHeader, GroupItems } from "../utils/Styled";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import DateRangePicker from "../components/common/DateRangePicker";
import CreateServiceForm from "../components/forms/services/Create";
import EditServiceForm from "../components/forms/services/Edit";
import DeleteServiceForm from "../components/forms/services/Delete";
import ViewContractForm from "../components/forms/contracts/View";

export const ContractServicesPage = ({ rowData }) => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const organizationInputRef = useRef(null);
    const ProductInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({ start_date: null, end_date: null, status: "", contract_id: rowData ? rowData.contract_id : "", user_id: identity.user.role_id === 3 ? identity.user.user_id : "" });
    const [sort, setSort] = useState({});

    const [open, setOpen] = useState({ create: false, edit: false, viewContract: false, });
    const [selectedRow, setSelectedRow] = useState({});

    const [usersData, setUsersData] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleOpen = (type, row) => {
        if (type === "viewContract") {
            axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/${row.contract_id}`)
            .then((response) => {
                setSelectedRow(response.data.data);
                setOpen({ ...open, [type]: true });
            })
            .catch((error) => console.log(error));
        } else {
            setOpen({ ...open, [type]: true });
            setSelectedRow(row);
        }
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ start_date: null, end_date: null, status: "", contract_id: rowData ? rowData.contract_id : "", user_id: identity.user.role_id === 3 ? identity.user.user_id : "" });
        setStartDate(null);
        setEndDate(null);
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const handleUserFilterChange = (selectedValue) => {
        setPage(0);
        setFilter({ ...filter, user_id: selectedValue });
    };

    const fetchData = useCallback(async () => {
        try {
            let customFilter = { ...filter };
            customFilter.start_date = moment(customFilter.start_date).isValid() ? moment(customFilter.start_date).format("YYYY-MM-DD") : "";
            customFilter.end_date = moment(customFilter.end_date).isValid() ? moment(customFilter.end_date).format("YYYY-MM-DD") : "";
            customFilter.user_id = customFilter.user_id ? customFilter.user_id : "";

            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/services`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(customFilter),
                    sort,
                },
            });
            setData(data.data.services);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    useEffect(() => {
        setPage(0);
        setFilter(prevFilter => ({ ...prevFilter, start_date: startDate, end_date: endDate }));
    }, [startDate, endDate]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/select")
            .then((response) => setUsersData(response.data.data.users))
            .catch((error) => console.log(error));
    }, []);

    const columns = [
        {
            title: "Service Date",
            field: "service_date",
            cellStyle: { width: 130, maxWidth: 130 },
            headerStyle: { width: 130, maxWidth: 130 },
            render: (rowData) => moment(rowData.service_date).format("YYYY-MM-DD"),
        },
        {
            title: "Organization",
            field: "org_name",
            cellStyle: { width: 500, maxWidth: 500 },
            headerStyle: { width: 500, maxWidth: 500 },
            render: (rowData) => rowData.org_name,
        },
        {   title: "Product", field: "product_description", width: "50%" },
        {
            title: "Status",
            field: "status",
            cellStyle: { width: 95, maxWidth: 95 },
            headerStyle: { width: 95, maxWidth: 95 },
            render: (rowData) => ServiceStatus.find((x) => x.id === rowData.status).name,
        },
        {   title: "Support By", field: "support_name", width: "50%" },
        {   
            title: "Contract Detail", field: "contract_id", 
            align: "center",
            cellStyle: { width: 135, maxWidth: 135 }, headerStyle: { width: 135, maxWidth: 135 },
            render: (rowData) => rowData.contract_id > 0 && 
            <Tooltip title="View Contract Detail">
                <IconButton color="primary" aria-label="view contract detail" component="label" style={{ padding: 0 }} onClick={() => handleOpen("viewContract", rowData)}>
                    <Icons.Summarize />
                </IconButton>
            </Tooltip>,
        },
    ];

    const actions = [];

    actions.push({
        icon: () => <Icons.Edit />,
        tooltip: "Edit Service Information",
        onClick: (event, rowData) => handleOpen("edit", rowData),
    });
    actions.push({
        icon: () => <Icons.Delete />,
        tooltip: "Delete Service",
        onClick: (event, rowData) => DeleteServiceForm(rowData, fetchData),
    });


    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <MaterialTable
                    title="Services List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "24px" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false,
                        rowStyle: (rowData) => {
                            let backgroundColor = "";
                            switch (rowData.status) {
                                case 1:
                                    backgroundColor = "#ffffcc";
                                    break;
                                case 2:
                                    backgroundColor = "#ebebeb";
                                    break;
                                case 3:
                                    backgroundColor = "#fbddea";
                                    break;
                                default:
                                    break;
                            }

                            return { backgroundColor };
                        },
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={3}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        Services List
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => handleOpen("create", null)}
                                                    variant="outlined"
                                                    startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 800,
                                                        paddingTop: 0.5,
                                                        paddingBottom: 0.5,
                                                    }}
                                                >
                                                    Create
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </div>

                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                    <Grid item xs={12} sm={2} md={1.4} xl={1.4}>
                                            <TextField
                                                label="Service Date (Start)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={startDate ? formatDate(startDate) : ""}
                                                // onChange={(event) => setStartDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                                                <DateRangePicker onClose={handleDialogClose} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                                            </Dialog>
                                        </Grid>
                                        <Grid item xs={12} sm={2} md={1.4} xl={1.4}>
                                            <TextField
                                                label="Service Date (End)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={endDate ? formatDate(endDate) : ""}
                                                // onChange={(event) => setEndDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2.5} xl={2.5}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Organization"
                                                name="org_name"
                                                variant="outlined"
                                                autoFocus={organizationInputRef.current === document.activeElement}
                                                value={filter.org_name}
                                                onChange={handleFilterChange}
                                                inputRef={organizationInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2.5} xl={2.5}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Product"
                                                name="product_description"
                                                variant="outlined"
                                                autoFocus={ProductInputRef.current === document.activeElement}
                                                value={filter.product_description}
                                                onChange={handleFilterChange}
                                                inputRef={ProductInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>            
                                        <Grid item xs={12} sm={3} md={1.4} xl={1.4}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Status"
                                                variant="outlined"
                                                name="status"
                                                value={filter.status}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {ServiceStatus.map((status) => (
                                                    <MenuItem key={status.id} value={status.id}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        {identity.user.role_id < 3 && (<Grid item xs={12} sm={4} md={1.8} xl={1.8} style={{ marginTop: "-8px"}}>
                                            <Autocomplete
                                                size="small"
                                                name="user_id"
                                                options={usersData}
                                                groupBy={(option) => {
                                                    const country = Countries.find((country) => country.id === option.country_id);
                                                    return country ? country.name : "Unknown";
                                                }}
                                                value={filter.user_id}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(_, selectedValue) => handleUserFilterChange(selectedValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        id="user_id"
                                                        label="Support By"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                renderGroup={(params) => {
                                                    return (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>{params.children}</GroupItems>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Grid>)}
                                        <Grid item xs={12} sm={1}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "2px",
                                                }}
                                                onClick={handleResetFilter}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <CreateServiceForm open={open.create} handleClose={() => handleClose("create")} rowData={rowData} fetchData={fetchData} />
                <EditServiceForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
                <ViewContractForm open={open.viewContract} handleClose={() => handleClose("viewContract")} rowData={selectedRow} />
            </Container>
        </div>
    );
};
