import { Typography, Link } from "@mui/material";

export function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href="https://rockbell.com.my/" target="_blank" rel="noopener noreferrer" sx={{ textDecoration: "none", fontWeight: "bold" }}>
                Rockbell Software Sdn Bhd
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
        </Typography>
    );
}
