import { useEffect, useState } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, MenuItem, Divider, Chip, Checkbox, Autocomplete } from "@mui/material";
import { Countries } from "../../../utils/Lookup";
import { Icons } from "../../../utils/Icon";

import axios from "axios";

export default function ViewOrganizationForm({ open, handleClose, rowData }) {
    const [productsData, setProductsData] = useState([]);
    const [selectedProductValues, setSelectedProductValues] = useState([]);

    useEffect(() => {
        if (open) {
            setSelectedProductValues([]);

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/select")
                .then((response) => {
                    setProductsData(response.data.data.products);
                    setSelectedProductValues(
                        rowData.selectedProducts.split(",").map((productId) => {
                            return response.data.data.products.find((productData) => productData.product_id === parseInt(productId, 10));
                        })
                    );
                })
                .catch((error) => console.log(error));
        }
    }, [open, rowData]);

    if (!rowData) return null;

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">View Organization Information</DialogTitle>
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Organization Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="org_code"
                            label="Code"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.org_code}
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="org_name"
                            label="Name"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.org_name}
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="email" 
                            label="Email" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.email} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="office_phone" 
                            label="Office Phone" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.office_phone} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="mobile_phone" 
                            label="Mobile Phone" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.mobile_phone} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="pic_name" 
                            label="PIC Name" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.pic_name} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                </Grid>
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Address Details" />
                </Divider>
                <TextField 
                    autoComplete="off" 
                    margin="dense" 
                    id="address" 
                    label="Address" 
                    type="text" 
                    fullWidth 
                    defaultValue={rowData.address} 
                    InputProps={{ readOnly: true }} 
                />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField 
                            margin="dense" 
                            autoComplete="off" 
                            id="city" 
                            label="City" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.city} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="state" 
                            label="State" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.state} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="postal_code" 
                            label="Postal Code" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.postal_code} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="business_type" 
                            label="Nature Of Business" 
                            type="text" 
                            fullWidth 
                            defaultValue={rowData.business_type} 
                            InputProps={{ readOnly: true }} 
                        />
                    </Grid>
                </Grid>
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Product Existing Details" />
                </Divider>
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    options={productsData}
                    getOptionLabel={(option) => option.product_description}
                    defaultValue={selectedProductValues}
                    value={selectedProductValues}
                    readOnly
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox icon={Icons.CheckBoxBlankIcon} checkedIcon={Icons.CheckBoxCheckedIcon} style={{ marginRight: 8 }} checked={selected} />
                            {option.product_description}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} margin="dense" id="product_id" label="Product" fullWidth />}
                />
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <TextField 
                    autoComplete="off" 
                    margin="dense" 
                    id="remarks" 
                    label="Remark" 
                    type="text" 
                    multiline 
                    rows={3} 
                    fullWidth 
                    defaultValue={rowData.remarks} 
                    InputProps={{ readOnly: true }} 
                />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField 
                            autoComplete="off" 
                            margin="dense" 
                            id="country_id" 
                            select 
                            label="Manage By" 
                            type="number" 
                            fullWidth 
                            required 
                            defaultValue={rowData.country_id} 
                            InputProps={{ readOnly: true }}
                        >
                            {Countries.map((country) => (
                                <MenuItem key={country.id} value={country.id}>
                                    {country.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
