import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { GenerateToken, DecodeJWTToken, SetAxiosAuthToken } from "../utils/Token";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage("token", null);
    const navigate = useNavigate();

    const value = useMemo(() => {
        const login = (data) => {
            const auth = data.token;
            const user = DecodeJWTToken(auth);

            let identity = {
                user: {
                    user_id: user.user_id,
                    user_name: user.user_name,
                    user_email: user.user_email,
                    role_id: user.role_id,
                    country_id: user.country_id,
                },
            };

            // Store Token to Local Storage
            setToken(GenerateToken(auth, identity));

            // Set Authorization Token to Axios Default Headers
            SetAxiosAuthToken(auth);

            navigate("/dashboard", { replace: true });
        };

        const logout = (message) => {
            setToken(null);
            navigate("/", { replace: true, state: { message: message } });
        };

        return {
            token,
            setToken,
            login,
            logout,
        };
    }, [token, setToken, navigate]);

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
