import axios from "axios";
import CryptoJS from "crypto-js";
import jwt_decode from "jwt-decode";

export const GenerateToken = (auth, identity) => {
    let encryptedAuthData = CryptoJS.AES.encrypt(JSON.stringify(auth), process.env.REACT_APP_AUTH_SECRET_KEY).toString();
    let encryptedIdentityData = CryptoJS.AES.encrypt(JSON.stringify(identity), process.env.REACT_APP_AUTH_SECRET_KEY).toString();
    let token = { auth: encryptedAuthData, identity: encryptedIdentityData };
    return token;
};

export const GetTokenAuth = (token) => {
    let bytes = CryptoJS.AES.decrypt(token.auth, process.env.REACT_APP_AUTH_SECRET_KEY);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const GetTokenIdentity = (token) => {
    let bytes = CryptoJS.AES.decrypt(token.identity, process.env.REACT_APP_AUTH_SECRET_KEY);
    let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
};

export const DecodeJWTToken = (token) => {
    let decoded = jwt_decode(token);
    return decoded;
};

export const SetAxiosAuthToken = (token) => {
    if (token) {
        axios.defaults.headers.common["Authorization"] = `${token}`;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
};
