import { useEffect, useState, useCallback } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, MenuItem, Alert, Divider, Chip, Checkbox, Autocomplete } from "@mui/material";
import { GroupHeader, GroupItems } from "../../../utils/Styled";
import { Countries } from "../../../utils/Lookup";
import { Icons } from "../../../utils/Icon";

import axios from "axios";
import Swal from "sweetalert2";

export default function EditOrganizationForm({ open, handleClose, rowData, fetchData, userCountry }) {
    const [editData, setEditData] = useState({});

    const [errors, setErrors] = useState({ org_code: false, org_name: false, country_id: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [productsData, setProductsData] = useState([]);
    const [businessTypeData, setBusinessTypeData] = useState([]);
    const [codeData, setCodeData] = useState([]);
    const [nameData, setNameData] = useState([]);

    const [selectedProductValues, setSelectedProductValues] = useState([]);
    const [userBusinessTypeInput, setUserBusinessTypeInput] = useState("");
    const [userCodeInput, setUserCodeInput] = useState("");
    const [userNameInput, setUserNameInput] = useState("");

    useEffect(() => {
        if (rowData) {
            setEditData(rowData);
        }
        if (open) {
            setErrors({ org_code: false, org_name: false, country_id: false });
            setHelperText({});
            setSelectedProductValues([]);
            setErrorMessage("");

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/select")
                .then((response) => {
                    setProductsData(response.data.data.products);
                    setSelectedProductValues(
                        rowData.selectedProducts.split(",").map((productId) => {
                            return response.data.data.products.find((productData) => productData.product_id === parseInt(productId, 10));
                        })
                    );
                })
                .catch((error) => console.log(error));
        }
    }, [open, rowData]);

    const fetchBusinessTypeData = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/organizations/get-business-type?search=${userBusinessTypeInput}`);
            setBusinessTypeData(response.data.data.texts);
        } catch (error) {
            console.error(error);
        }
    }, [userBusinessTypeInput]);
    
    useEffect(() => {
        fetchBusinessTypeData();
    }, [fetchBusinessTypeData]);

    const fetchCodeData = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/organizations/get-code?search=${userCodeInput}&country=${editData.country_id}`);
            setCodeData(response.data.data.texts);
        } catch (error) {
            console.error(error);
        }
    }, [userCodeInput, editData.country_id]);
    
    useEffect(() => {
        fetchCodeData();
    }, [fetchCodeData]);

    const fetchNameData = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/organizations/get-name?search=${userNameInput}&country=${editData.country_id}`);
            setNameData(response.data.data.texts);
        } catch (error) {
            console.error(error);
        }
    }, [userNameInput, editData.country_id]);
    
    useEffect(() => {
        fetchNameData();
    }, [fetchNameData]);

    if (!rowData) return null;

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "org_code" && value === "") {
            await setError("org_code", "Code is required.");
        } else if (field === "org_name" && value === "") {
            await setError("org_name", "Name is required.");
        } else if (field === "country_id" && value === "") {
            await setError("country_id", "Manage By is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    const handleEditOrganization = async () => {
        const { org_code, org_name, address, postal_code, city, state, country_id, email, office_phone, mobile_phone, pic_name, business_type, remarks } = editData;
        const selected_products = selectedProductValues.map((product) => product.product_id);
        try {
            const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/organizations/${rowData.org_id}`, {
                org_code, org_name, address, postal_code, city, state, country_id, email, office_phone, mobile_phone, pic_name, business_type, remarks, selected_products,
            });

            if (response.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Organization information updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while updating the organization information. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit Organization Information</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Organization Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={codeData}
                            getOptionLabel={(option) => option.org_code || ""}
                            inputValue={editData.org_code || ""}
                            onInputChange={(event, newInputValue) => {
                                setUserCodeInput(newInputValue.toUpperCase());
                                setEditData({ ...editData, org_code: newInputValue ? newInputValue.toUpperCase() : '' });
                                validate('org_code', newInputValue.toUpperCase());
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="Code"
                                    required
                                    fullWidth
                                    error={errors.org_code}
                                    helperText={helperText.org_code}
                                    InputProps={{
                                        ...params.InputProps, type: "search",
                                    }}
                                />
                            )}
                        />
                        {/* <TextField
                            autoComplete="off"
                            margin="dense"
                            id="org_code"
                            label="Code"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.org_code}
                            error={errors.org_code}
                            helperText={helperText.org_code}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            onChange={(e) => {
                                setEditData({ ...editData, org_code: e.target.value ? e.target.value.toUpperCase() : "", });
                                validate("org_code", e.target.value);
                            }}
                        /> */}
                    </Grid>
                    <Grid item xs={9}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={nameData}
                            getOptionLabel={(option) => option.org_name || ""}
                            inputValue={editData.org_name || ""}
                            onInputChange={(event, newInputValue) => {
                                setUserNameInput(newInputValue.toUpperCase());
                                setEditData({ ...editData, org_name: newInputValue ? newInputValue.toUpperCase() : '' });
                                validate('org_name', newInputValue.toUpperCase());
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="Name"
                                    required
                                    fullWidth
                                    error={errors.org_name}
                                    helperText={helperText.org_name}
                                    InputProps={{
                                        ...params.InputProps, type: "search",
                                    }}
                                />
                            )}
                        />
                        {/* <TextField
                            autoComplete="off"
                            margin="dense"
                            id="org_name"
                            label="Name"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.org_name}
                            error={errors.org_name}
                            helperText={helperText.org_name}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            onChange={(e) => {
                                setEditData({ ...editData, org_name: e.target.value ? e.target.value.toUpperCase() : "", });
                                validate("org_name", e.target.value);
                            }}
                        /> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            defaultValue={rowData.email}
                            error={errors.email}
                            helperText={helperText.email}
                            onChange={(e) => {
                                setEditData({ ...editData, email: e.target.value, });
                                validate("email", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="office_phone"
                            label="Office Phone"
                            type="text"
                            fullWidth
                            defaultValue={rowData.office_phone}
                            error={errors.office_phone}
                            helperText={helperText.office_phone}
                            onChange={(e) => {
                                setEditData({ ...editData, office_phone: e.target.value, });
                                validate("office_phone", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="mobile_phone"
                            label="Mobile Phone"
                            type="text"
                            fullWidth
                            defaultValue={rowData.mobile_phone}
                            error={errors.mobile_phone}
                            helperText={helperText.mobile_phone}
                            onChange={(e) => {
                                setEditData({ ...editData, mobile_phone: e.target.value, });
                                validate("mobile_phone", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pic_name"
                            label="PIC Name"
                            type="text"
                            fullWidth
                            defaultValue={rowData.pic_name}
                            error={errors.pic_name}
                            helperText={helperText.pic_name}
                            onChange={(e) => {
                                setEditData({ ...editData, pic_name: e.target.value, });
                                validate("pic_name", e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Address Details" />
                </Divider>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="address"
                    label="Address"
                    type="text"
                    fullWidth
                    defaultValue={rowData.address}
                    error={errors.address}
                    helperText={helperText.address}
                    onChange={(e) => {
                        setEditData({ ...editData, address: e.target.value, });
                        validate("address", e.target.value);
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="city"
                            label="City"
                            type="text"
                            fullWidth
                            defaultValue={rowData.city}
                            error={errors.city}
                            helperText={helperText.city}
                            onChange={(e) => {
                                setEditData({ ...editData, city: e.target.value, });
                                validate("city", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="state"
                            label="State"
                            type="text"
                            fullWidth
                            defaultValue={rowData.state}
                            error={errors.state}
                            helperText={helperText.state}
                            onChange={(e) => {
                                setEditData({ ...editData, state: e.target.value, });
                                validate("state", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="postal_code"
                            label="Postal Code"
                            type="text"
                            fullWidth
                            defaultValue={rowData.postal_code}
                            error={errors.postal_code}
                            helperText={helperText.postal_code}
                            onChange={(e) => {
                                setEditData({ ...editData, postal_code: e.target.value, });
                                validate("postal_code", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={businessTypeData}
                            getOptionLabel={(option) => option.business_type || ""}
                            inputValue={editData.business_type || ""}
                            onInputChange={(event, newInputValue) => {
                                setUserBusinessTypeInput(newInputValue.toUpperCase());
                                setEditData({ ...editData, business_type: newInputValue ? newInputValue.toUpperCase() : '' });
                                validate('business_type', newInputValue.toUpperCase());
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="Nature Of Business"
                                    fullWidth
                                    error={errors.business_type}
                                    helperText={helperText.business_type}
                                    InputProps={{
                                        ...params.InputProps, type: "search",
                                    }}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Product Existing Details" />
                </Divider>
                <Autocomplete
                    multiple
                    disableCloseOnSelect
                    groupBy={(option) => {
                        const country = Countries.find((country) => country.id === option.country_id);
                        return country ? country.name : "Unknown";
                    }}
                    options={productsData}
                    getOptionLabel={(option) => option.product_description ?? ""}
                    defaultValue={selectedProductValues}
                    value={selectedProductValues}
                    onChange={(e, value) => setSelectedProductValues(value)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox icon={Icons.CheckBoxBlankIcon} checkedIcon={Icons.CheckBoxCheckedIcon} style={{ marginRight: 8 }} checked={selected} />
                            {option.product_description}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} margin="dense" id="product_id" label="Product" fullWidth error={errors.product_id} helperText={helperText.product_id} />}
                    renderGroup={(params) => {
                        return (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        );
                    }}
                />
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="remarks"
                    label="Remark"
                    type="text"
                    multiline
                    rows={3}
                    fullWidth
                    defaultValue={rowData.remarks}
                    error={errors.remarks}
                    helperText={helperText.remarks}
                    onChange={(e) => {
                        setEditData({ ...editData, remarks: e.target.value, });
                        validate("remarks", e.target.value);
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="country_id"
                            select
                            label="Manage By"
                            type="number"
                            fullWidth
                            required
                            defaultValue={rowData.country_id}
                            error={errors.country_id}
                            helperText={helperText.country_id}
                            onChange={(e) => {
                                setEditData({ ...editData, country_id: e.target.value, });
                                validate("country_id", e.target.value);
                            }}
                        >
                            {userCountry.map((country) => (
                                <MenuItem key={country.country_id} value={country.country_id}>
                                    {country.country_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleEditOrganization} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Update
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
