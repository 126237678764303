import { useEffect, useState } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert, Autocomplete, Checkbox } from "@mui/material";
import { Icons } from "../../../utils/Icon";

import axios from "axios";
import Swal from "sweetalert2";

export default function EditGroupForm({ open, handleClose, rowData, fetchData }) {
    const [editData, setEditData] = useState({});

    const [errors, setErrors] = useState({ group_name: false, group_description: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [users, setUsers] = useState([]);
    const [selectedUserValues, setSelectedUserValues] = useState([]);

    useEffect(() => {
        if (rowData) {
            setEditData(rowData);
        }
        if (open) {
            setErrors({ group_name: false, group_description: false });
            setHelperText({});
            setErrorMessage("");
            setSelectedUserValues([]);
            
            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/select").then((response) => {
                setUsers(response.data.data.users);
                setSelectedUserValues(
                    rowData.selectedUsers.split(",").map((userId) => {
                        return response.data.data.users.find((user) => user.user_id === parseInt(userId, 10));
                })
            );
            });
        }
    }, [rowData, open]);

    if (!rowData) return null;

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "group_name" && value === "") {
            await setError("group_name", "Name is required.");
        } else if (field === "group_description" && value === "") {
            await setError("group_description", "Description is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    const handleEditGroup = async () => {
        const { group_name, group_description } = editData;
        const selected_users = selectedUserValues.map((user) => user.user_id);
        try {
            const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/groups/${rowData.group_id}`, {
                group_name,
                group_description,
                selected_users,
            });

            if (response.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Group information updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while updating the Group information. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit Group Information</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="group_name"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    defaultValue={rowData.group_name}
                    error={errors.group_name}
                    helperText={helperText.group_name}
                    onChange={(e) => {
                        setEditData({ ...editData, group_name: e.target.value, });
                        validate("group_name", e.target.value);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="group_description"
                    label="Description"
                    type="text"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    defaultValue={rowData.group_description}
                    error={errors.group_description}
                    helperText={helperText.group_description}
                    onChange={(e) => {
                        setEditData({ ...editData, group_description: e.target.value, });
                        validate("group_description", e.target.value);
                    }}
                />
                <Autocomplete
                    disableCloseOnSelect
                    multiple
                    options={users}
                    getOptionLabel={(users) => users.name}
                    defaultValue={selectedUserValues}
                    value={selectedUserValues}
                    onChange={(e, newValue) => setSelectedUserValues(newValue)}
                    renderOption={(props, option, { selected }) => (
                        <li {...props}>
                            <Checkbox icon={Icons.CheckBoxBlankIcon} checkedIcon={Icons.CheckBoxCheckedIcon} style={{ marginRight: 8 }} checked={selected} />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params) => <TextField {...params} margin="dense" variant="outlined" label="Users" />}
                />
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleEditGroup} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Update
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
