import React, { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Grid, MenuItem, Divider, Chip, Autocomplete, FormControlLabel, Checkbox, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { GetTokenIdentity } from "../../../utils/Token";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GroupHeader, GroupItems } from "../../../utils/Styled";
import { Countries, ServiceStatus } from "../../../utils/Lookup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export default function CreateServiceForm({ open, handleClose, rowData, fetchData }) {
    const { token } = useAuth();
    const identity = GetTokenIdentity(token);

    // const [newData, setNewData] = useState({ service_date: moment().toDate(), start_from: "", end_from: moment().toDate(), status: 0, is_custom_version: 0, is_upgrade: 0 });
    const [newData, setNewData] = useState({});

    const [newDataDetails, setNewDataDetails] = useState([]);

    const [errors, setErrors] = useState({ doc_no_id: false, service_date: false, service_no: false, org_id: false, status: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [organizationsData, setOrganizationsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [systemPluginsData, setSystemPluginsData] = useState([]);
    const [contractsData, setContractsData] = useState([]);
    const [docNoSettingData, setDocNoSettingData] = useState([]);

    const [selectedOrganizationValue, setSelectedOrganizationValue] = useState(null);
    const [selectedContractValue, setSelectedContractValue] = useState(null);

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const handleDetailChange = (index, field, value) => {
        const newDetails = [...newDataDetails];
        newDetails[index][field] = value;
        setNewDataDetails(newDetails);
    };
    
    const handleAddDetail = () => {
        setNewDataDetails([...newDataDetails, {
            product_id: "", 
            is_custom_version: 0, 
            is_upgrade: 0,
            version_date: "", 
            from_version_date: "", 
            version_text: "",
            from_version_text: "",
            is_system_plugin: 0, 
            system_plugin_id: "", 
            system_plugin_note: "", 
            ref_product: ""
        },
    ]);
    };
    
    const handleRemoveDetail = (index) => {
        const newDetails = [...newDataDetails];
        newDetails.splice(index, 1);
        setNewDataDetails(newDetails);
    };

    const validate = async (field, value) => {
        if (field === "service_date" && value === "") {
            await setError("service_date", "Service Date is required.");
        } else if (field === "doc_no_id" && value === "") {
            await setError("doc_no_id", "Service No Prefix is required.");
        } else if (field === "service_no" && value === "") {
            await setError("service_no", "Service No is required.");
        } else if (field === "org_id" && value === "") {
            await setError("org_id", "Organization is required.");
        } else if (field === "status" && value === "") {
            await setError("status", "Status is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (open) {
            setSelectedOrganizationValue(null);
            setSelectedContractValue(null);
            setNewData({ 
                doc_no_id: "", 
                doc_no_prefix: "", 
                service_date: moment().toDate(), 
                service_no: "", 
                org_id: rowData ? rowData.org_id : "", 
                email: "", 
                office_phone: "", 
                mobile_phone: "", 
                pic_name: "", 
                contract_id: rowData ? rowData.contract_id : "", 
                ref_org: "",
                ref_service_id: rowData ? (rowData.ref_service_id > 0 ? rowData.ref_service_id : rowData.service_id) : 0,
                pending_remarks: "",
                remarks: "",
                status: 0, 
            });
            setNewDataDetails([]);
            setErrors({ doc_no_id: false, service_date: false, service_no: false, org_id: false, status: false });
            setHelperText({});
            setErrorMessage("");

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/organizations/select")
                .then((response) => {
                    setOrganizationsData(response.data.data.organizations);
                    if (rowData) {
                        setSelectedOrganizationValue(response.data.data.organizations.find((option) => option.org_id === rowData.org_id));
                    }
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/contracts/select")
                .then((response) => {
                    setContractsData(response.data.data.contracts);
                    if (rowData) {
                        setSelectedContractValue(response.data.data.contracts.find((option) => option.contract_id === rowData.contract_id));
                    }
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/select")
                .then((response) => setProductsData(response.data.data.products))
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/system-plugins/select")
                .then((response) => setSystemPluginsData(response.data.data.systemPlugins))
                .catch((error) => console.log(error));
            
            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/doc-no-settings/select?type=1")
                .then((response) => {
                    setDocNoSettingData(response.data.data.doc_no_settings);
                })
                .catch((error) => console.log(error));
        }
    }, [open, rowData, identity.user.role_id, identity.user.user_id]);

    const handleCreateService = async () => {
        let isValid = true;

        if (newData["doc_no_id"] === "") isValid = false; 
        else if (newData["doc_no_id"] !== "" && newData["doc_no_prefix"] === "" && newData["service_no"] === "") isValid = false;
        else if (newData["org_id"] === "" && newData["org_id"] > 0) isValid = false;
        else if (newData["status"] === "") isValid = false;

        if (newDataDetails.length > 0) {
            for (let index = 0; index < newDataDetails.length; index++) {
                const detailElement = newDataDetails[index];
                if (detailElement.product_id === "") {
                    isValid = false;
                    break;
                } else if (detailElement.is_system_plugin === 1 && detailElement.system_plugin_id === "") {
                    isValid = false;
                    break;
                }
            }
        }

        Object.keys(newData).forEach(async (field) => {
            await validate(field, newData[field]);
        });

        if (isValid) {
            await setErrorMessage("");
            await createService();
        } else {
            await setErrorMessage(`Please fill all the required fields.`);
        }
    };

    const createService = async () => {
        try {
            const { doc_no_id, service_no, org_id, email, office_phone, mobile_phone, pic_name, ref_org, ref_service_id, pending_remarks, remarks, status } = newData;
            const service_date = moment(newData.service_date).format("YYYY-MM-DD");
            const contract_id = selectedContractValue ? selectedContractValue.contract_id : 0;

            const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/services/create", {
                doc_no_id,
                service_date,
                service_no,
                org_id,
                email,
                office_phone,
                mobile_phone,
                pic_name,
                contract_id: contract_id ? contract_id : 0,
                ref_org,
                ref_service_id,
                pending_remarks,
                remarks,
                status,
                details: newDataDetails,
            });

            if (response.status === 201) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Service created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while creating the service. Please try again later.");
            }
        }
    };

    // const fetchContractData = useCallback(async () => {
    //     try {
    //         setSelectedContractValue(null);
    //         const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/get-service-contract?org_id=${newData.org_id}&product_id=${newData.product_id}`);
    //         setContractsData(response.data.data.contracts);
    //         if (response.data.data.contracts.length > 0 && rowData && rowData.contract_id && rowData.contract_id > 0) {
    //             setSelectedContractValue(response.data.data.contracts.find((option) => option.contract_id === rowData.contract_id));
    //         } else {
    //             setSelectedContractValue(null);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }, [rowData, newData.org_id, newData.product_id]);
    
    // useEffect(() => {
    //     fetchContractData();
    // }, [fetchContractData]);

    const setOrganizationInfo = async (value) => {
        if (value > 0) {
            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/organizations/${value}`)
                .then((response) => {
                    if (response.data.data) {
                        setNewData({
                            ...newData,
                            org_id: value,
                            email: response.data.data.email,
                            mobile_phone: response.data.data.mobile_phone,
                            office_phone: response.data.data.office_phone,
                            pic_name: response.data.data.pic_name
                        });
                    }
                })
                .catch((error) => {
                console.error(error);
            });
        } else {
            setNewData({
                ...newData,
                org_id: 0,
                email: "",
                mobile_phone: "",
                office_phone: "",
                pic_name: ""
            });
        }
    };      

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create New Service</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Service Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Service Date"
                                inputFormat="YYYY-MM-DD"
                                value={newData.service_date}
                                onChange={(newValue) => {
                                    setNewData({ ...newData, service_date: newValue, });
                                    validate("service_date", newValue);
                                }}
                                renderInput={(params) => <TextField {...params} required fullWidth margin="dense" error={errors.service_date} helperText={helperText.service_date} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            options={docNoSettingData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.prefix === "" ? option.description : option.prefix + ' - ' + option.description}
                            onChange={(event, value) => {
                                setNewData({ ...newData, doc_no_id: value ? value.doc_no_setting_id : "", doc_no_prefix: value ? value.prefix : ""});
                                validate("doc_no_id", value ? value.doc_no_setting_id : "");
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="doc_no_id" label="Service No Prefix" fullWidth required error={errors.doc_no_id} helperText={helperText.doc_no_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                    { newData.doc_no_id !== "" && newData.doc_no_prefix === "" &&
                        <Grid item xs={3}>
                            <TextField
                                autoFocus
                                autoComplete="off"
                                margin="dense"
                                id="service_no"
                                label="Service No"
                                type="text"
                                fullWidth
                                required
                                error={errors.service_no}
                                helperText={helperText.service_no}
                                inputProps={{
                                    style: { textTransform: "uppercase" },
                                }}
                                onChange={(e) => {
                                    setNewData({ ...newData, service_no: e.target.value ? e.target.value.toUpperCase() : "", });
                                    validate("service_no", e.target.value);
                                }}
                            />
                        </Grid>
                    }
                    <Grid item xs={3}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            label="Status"
                            type="text"
                            select
                            fullWidth
                            required
                            value={newData.status || 0}
                            error={errors.status}
                            helperText={helperText.status}
                            onChange={(e) => {
                                setNewData({ ...newData, status: e.target.value, });
                                validate("status", e.target.value);
                            }}
                        >
                            {ServiceStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={organizationsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.org_name}
                            onChange={(event, value) => {
                                setSelectedOrganizationValue(value);
                                setNewData({ ...newData, org_id: value ? value.org_id : "", });
                                validate("org_id", value ? value.org_id : "");
                                setOrganizationInfo(value ? value.org_id : 0);
                            }}
                            isOptionEqualToValue={(option, value) => option.org_id === value.org_id}
                            value={selectedOrganizationValue}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.org_id}>
                                        {option.org_name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="org_id" label="Organization" fullWidth required error={errors.org_id} helperText={helperText.org_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            value={newData.email}
                            error={errors.email}
                            helperText={helperText.email}
                            onChange={(e) => {
                                setNewData({ ...newData, email: e.target.value, });
                                validate("email", e.target.value);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="office_phone"
                            label="Office Phone"
                            type="text"
                            fullWidth
                            value={newData.office_phone}
                            error={errors.office_phone}
                            helperText={helperText.office_phone}
                            onChange={(e) => {
                                setNewData({ ...newData, office_phone: e.target.value, });
                                validate("office_phone", e.target.value);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="mobile_phone"
                            label="Mobile Phone"
                            type="text"
                            fullWidth
                            value={newData.mobile_phone}
                            error={errors.mobile_phone}
                            helperText={helperText.mobile_phone}
                            onChange={(e) => {
                                setNewData({ ...newData, mobile_phone: e.target.value, });
                                validate("mobile_phone", e.target.value);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pic_name"
                            label="PIC Name"
                            type="text"
                            fullWidth
                            value={newData.pic_name}
                            error={errors.pic_name}
                            helperText={helperText.pic_name}
                            onChange={(e) => {
                                setNewData({ ...newData, pic_name: e.target.value, });
                                validate("pic_name", e.target.value);
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={contractsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.contract_description}
                            onChange={(event, value) => {
                                setSelectedContractValue(value);
                                setNewData({ ...newData, contract_id: value ? value.contract_id : "", });
                                validate("contract_id", value ? value.contract_id : "");
                            }}
                            // isOptionEqualToValue={(option, value) => option.contract_id === value.contract_id}
                            value={selectedContractValue}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.contract_id}>
                                        {option.contract_description}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="contract_id" label="Contract" fullWidth error={errors.contract_id} helperText={helperText.contract_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_org"
                            label="Old Organization (Import)"
                            type="text"
                            fullWidth
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                                setNewData({ ...newData, ref_org: e.target.value ? e.target.value.toUpperCase() : "", });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {/* <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_service_id"
                            label="Reference Service Id"
                            type="text"
                            fullWidth
                            onChange={(e) => {
                                setNewData({ ...newData, ref_service_id: e.target.value, });
                            }}
                        /> */}
                        {/* <Autocomplete
                            options={contractsData}
                            getOptionLabel={(option) => option.contract_description}
                            onChange={(event, value) => {
                                setSelectedContractValue(value);
                                setNewData({ ...newData, contract_id: value ? value.contract_id : "", });
                                validate("contract_id", value ? value.contract_id : "");
                            }}
                            isOptionEqualToValue={(option, value) => option.contract_id === value.contract_id}
                            value={selectedContractValue}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.contract_id}>
                                        {option.contract_description}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="contract_id" label="Reference Service" fullWidth error={errors.contract_id} helperText={helperText.contract_id} />}
                        /> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button variant="contained" style={{ backgroundColor: "#1e73dd", color: "white" }} onClick={handleAddDetail}>
                            Add Service Detail
                        </Button>
                    </Grid>
                </Grid>
                {newDataDetails.map((detail, index) => (
                    <Grid item container spacing={2} key={index} sx={{ pt: 0, mt: 1.5, pb: 1.5, border: "1px solid #c4c4c4" }}>
                        <Grid item xs={11}>
                            <Accordion defaultExpanded={true} sx={{ backgroundColor: "#565656" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography sx={{ color: "#ffffff" }}>Product #{index + 1}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: "#f3f3f3" }}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                        <Autocomplete
                                            options={productsData}
                                            groupBy={(option) => {
                                                const country = Countries.find((country) => country.id === option.country_id);
                                                return country ? country.name : "Unknown";
                                            }}
                                            getOptionLabel={(option) => option.product_description}
                                            onChange={(e, value) => {
                                                handleDetailChange(index, "product_id", value ? value.product_id : "")
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.product_id}>
                                                        {option.product_description}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField 
                                                {...params} 
                                                id="product_id" 
                                                label="Product" 
                                                fullWidth 
                                                required 
                                                error={detail.product_id === "" ? true : false} 
                                                helperText={detail.product_id === "" ? "Product is required." : ""} 
                                            />}
                                            renderGroup={(params) => {
                                                return (
                                                    <li key={params.key}>
                                                        <GroupHeader>{params.group}</GroupHeader>
                                                        <GroupItems>{params.children}</GroupItems>
                                                    </li>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                id={`is_custom_version_${index}`}
                                                margin="dense"
                                                onChange={(e) => {
                                                    handleDetailChange(index, "is_custom_version", e.target.checked ? 1 : 0)
                                                }}
                                            />}
                                            label="Is Custom Version"
                                            sx={{ mt: 1.8 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                id={`is_upgrade_${index}`}
                                                margin="dense"
                                                onChange={(e) => {
                                                    handleDetailChange(index, "is_upgrade", e.target.checked ? 1 : 0)
                                                }}
                                            />}
                                            label="Is Upgrade"
                                            sx={{ mt: 1.8 }}
                                        />
                                    </Grid>
                                    { detail.is_custom_version === 0 &&
                                        <Grid item xs={12} sm={3}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="System Version"
                                                    inputFormat="YYYY-MM-DD"
                                                    value={detail.version_date}
                                                    onChange={(newValue) => {
                                                        handleDetailChange(index, "version_date", newValue === "" ? "" : moment(newValue).format("YYYY-MM-DD"));
                                                    }}
                                                    renderInput={(params) => <TextField {...params} fullWidth margin="dense" error={false} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 0 && detail.is_upgrade === 1 &&
                                        <Grid item xs={12} sm={3}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="From System Version"
                                                    inputFormat="YYYY-MM-DD"
                                                    value={detail.from_version_date}
                                                    onChange={(newValue) => {
                                                        handleDetailChange(index, "from_version_date",  newValue === "" ? "" : moment(newValue).format("YYYY-MM-DD"));
                                                    }}
                                                    renderInput={(params) => <TextField {...params} fullWidth margin="dense" error={false} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 1 && 
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id={`version_text_${index}`}
                                                label="System Version (String)"
                                                type="text"
                                                fullWidth
                                                onChange={(e) => {
                                                    handleDetailChange(index, "version_text", e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 1 && detail.is_upgrade === 1 && 
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id={`from_version_text_${index}`}
                                                label="From System Version (String)"
                                                type="text"
                                                fullWidth
                                                onChange={(e) => {
                                                    handleDetailChange(index, "from_version_text", e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={9}>
                                        <TextField
                                            autoComplete="off"
                                            id={`ref_product_${index}`}
                                            label="Old Product (Import)"
                                            type="text"
                                            fullWidth
                                            inputProps={{
                                                style: { textTransform: "uppercase" },
                                            }}
                                            value={detail.ref_org}
                                            onChange={(e) => {
                                                handleDetailChange(index, "ref_product",  e.target.value ? e.target.value.toUpperCase() : "")
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            id={`is_system_plugin_${index}`}
                                            checked={detail.is_system_plugin === 1 ? true : false}
                                            onChange={(e) => {
                                                handleDetailChange(index, "is_system_plugin",  e.target.checked ? 1 : 0)
                                            }}
                                        />
                                        }
                                        label="Is Use System Plugin"
                                        sx={{ mt: 1 }}
                                    />
                                    </Grid>
                                    {detail.is_system_plugin === 1 &&
                                        <>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    options={systemPluginsData}
                                                    groupBy={(option) => {
                                                        const country = Countries.find((country) => country.id === option.country_id);
                                                        return country ? country.name : "Unknown";
                                                    }}
                                                    getOptionLabel={(option) => option.system_plugin_description}
                                                    onChange={(e, value) => {
                                                        handleDetailChange(index, "system_plugin_id", value ? value.system_plugin_id : "")
                                                    }}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.system_plugin_id}>
                                                                {option.system_plugin_description}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField 
                                                        {...params} 
                                                        id="system_plugin_id" 
                                                        label="System Plugin" 
                                                        fullWidth 
                                                        required 
                                                        error={detail.system_plugin_id === "" ? true : false} 
                                                        helperText={detail.system_plugin_id === "" ? "System Plugin is required." : ""} 
                                                    />}
                                                    renderGroup={(params) => {
                                                        return (
                                                            <li key={params.key}>
                                                                <GroupHeader>{params.group}</GroupHeader>
                                                                <GroupItems>{params.children}</GroupItems>
                                                            </li>
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField fullWidth label="System Plugin Note" multiline rows={4} value={detail.system_plugin_note} onChange={(e) => handleDetailChange(index, "system_plugin_note", e.target.value)} />
                                            </Grid>
                                        </>
                                    }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" color="secondary" onClick={() => handleRemoveDetail(index)} >
                                X
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="remarks"
                            label="Remark (Job Description)"
                            type="text"
                            multiline
                            rows={8}
                            fullWidth
                            onChange={(e) => {
                                setNewData({ ...newData, remarks: e.target.value, });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pending_remarks"
                            label="Pending Remark (Outstanding Jobs)"
                            type="text"
                            multiline
                            rows={8}
                            fullWidth
                            onChange={(e) => {
                                setNewData({ ...newData, pending_remarks: e.target.value, });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleCreateService} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Create
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
