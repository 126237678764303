import { useEffect, useState } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, MenuItem, Autocomplete, Divider, Chip, } from "@mui/material";
import { GroupHeader, GroupItems } from "../../../utils/Styled";
import { ContractStatus, IsFreeOfCharge } from "../../../utils/Lookup";

import axios from "axios";
import moment from "moment";

export default function ViewContractForm({ open, handleClose, rowData }) {
    // const [organizationsData, setOrganizationsData] = useState([]);
    // const [productsData, setProductsData] = useState([]);
    const [usersData, setUsersData] = useState([]);

    // const [selectedOrganizationValue, setSelectedOrganizationValue] = useState(null);
    // const [selectedProductValue, setSelectedProductValue] = useState(null);
    const [selectedUserValue, setSelectedUserValue] = useState(null);

    useEffect(() => {
        if (open) {
            // axios
            //     .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/organizations/select")
            //     .then((response) => {
            //         setOrganizationsData(response.data.data.organizations);
            //         setSelectedOrganizationValue(
            //             response.data.data.organizations.find(
            //                 (option) => option.org_id === rowData.org_id
            //             )
            //         );
            //     })
            //     .catch((error) => console.log(error));

            // axios
            //     .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/select")
            //     .then((response) => {
            //         setProductsData(response.data.data.products);
            //         setSelectedProductValue(
            //             response.data.data.products.find(
            //                 (option) => option.product_id === rowData.product_id
            //             )
            //         );
            //     })
            //     .catch((error) => console.log(error));

            axios
                .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/groups/select")
                .then((response) => {
                    setUsersData(response.data.data.users);
                    setSelectedUserValue(
                        response.data.data.users.find(
                            (option) =>
                                option.id === rowData.assigned_to &&
                                option.is_group === rowData.is_assigned_to_group
                        )
                    );
                })
                .catch((error) => console.log(error));
        }
    }, [rowData, open]);

    if (!rowData || Object.keys(rowData).length === 0) return null;

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                View Contract Information
            </DialogTitle>
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Contract Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="contract_subject"
                            label="Contract No"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.contract_subject}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="invoice_no"
                            label="Invoice No"
                            type="text"
                            fullWidth
                            defaultValue={rowData.invoice_no}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="org_name"
                            label="Organization"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.org_name}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            InputProps={{ readOnly: true }}
                        />
                        {/* <Autocomplete
                            options={organizationsData}
                            getOptionLabel={(option) =>
                                option ? option.org_name : "Select Organization"
                            }
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.org_id}>
                                        {option.org_name}
                                    </li>
                                );
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.org_id === value.org_id
                            }
                            value={selectedOrganizationValue}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    id="org_id"
                                    label="Organization"
                                    fullWidth
                                    required
                                />
                            )}
                            readOnly
                        /> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            defaultValue={rowData.email}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="office_phone"
                            label="Office Phone"
                            type="text"
                            fullWidth
                            defaultValue={rowData.office_phone}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="mobile_phone"
                            label="Mobile Phone"
                            type="text"
                            fullWidth
                            defaultValue={rowData.mobile_phone}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pic_name"
                            label="PIC Name"
                            type="text"
                            fullWidth
                            defaultValue={rowData.pic_name}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="product_id"
                            label="Product"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.product_description}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            InputProps={{ readOnly: true }}
                        />
                        {/* <Autocomplete
                            options={productsData}
                            getOptionLabel={(option) =>
                                option ? option.product_description : "Select product"
                            }
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.product_id}>
                                        {option.product_description}
                                    </li>
                                );
                            }}
                            isOptionEqualToValue={(option, value) =>
                                option.product_id === value.product_id
                            }
                            value={selectedProductValue}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    id="product_id"
                                    label="Product"
                                    fullWidth
                                    required
                                />
                            )}
                            readOnly
                        /> */}
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="start_date"
                            label="Start Date"
                            type="text"
                            fullWidth
                            defaultValue={moment(rowData.start_date).format("YYYY-MM-DD")}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="end_date"
                            label="End Date"
                            type="text"
                            fullWidth
                            defaultValue={moment(rowData.end_date).format("YYYY-MM-DD")}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            label="Status"
                            type="text"
                            select
                            fullWidth
                            required
                            defaultValue={rowData.status}
                            InputProps={{ readOnly: true }}
                        >
                            {ContractStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid
                        container
                        item
                        xs={3}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="is_foc"
                            label="Is Free Of Charge"
                            type="text"
                            select
                            fullWidth
                            required
                            defaultValue={rowData.is_foc}
                            InputProps={{ readOnly: true }}
                        >
                            {IsFreeOfCharge.map((element) => (
                                <MenuItem key={element.id} value={element.id}>
                                    {element.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_org"
                            label="Old Organization (Import)"
                            type="text"
                            fullWidth
                            defaultValue={rowData.ref_org}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={usersData}
                            getOptionLabel={(option) =>
                                option ? option.name : "Select user or group"
                            }
                            groupBy={(option) => option.is_group}
                            isOptionEqualToValue={(option, value) =>
                                option.id === value.id &&
                                option.is_group === value.is_group
                            }
                            value={selectedUserValue}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    id="assigned_to"
                                    label="Assigned To"
                                    fullWidth
                                    required
                                />
                            )}
                            renderGroup={(params) => (
                                <li key={params.key}>
                                    <GroupHeader>
                                        {params.group === 1
                                            ? "Groups"
                                            : "Users"}
                                    </GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                </li>
                            )}
                            readOnly
                        />
                    </Grid>
                </Grid>
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="remarks"
                    label="Remark"
                    type="text"
                    multiline
                    rows={4}
                    fullWidth
                    defaultValue={rowData.remarks}
                    InputProps={{ readOnly: true }}
                />
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button
                    onClick={handleClose}
                    variant="contained"
                    color="error"
                    style={{ marginRight: "6px", color: "white" }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
