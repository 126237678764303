import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, Button, Grid, Typography, CardHeader, ButtonGroup } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { GetTokenIdentity } from "../../utils/Token";
// import { Countries } from "../../utils/Lookup";
import { Icons } from "../../utils/Icon";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import EditContractForm from "../../components/forms/contracts/Edit";
import ViewContractForm from "../../components/forms/contracts/View";

import CountrySelect from "../../components/common/CountrySelect";

export const ExpiringThisMonth = () => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const organizationInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({ country_id: identity.user.country_id });
    const [sort, setSort] = useState({});

    const [open, setOpen] = useState({ create: false, edit: false, view: false });
    const [selectedRow, setSelectedRow] = useState({});

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ country_id: identity.user.country_id });
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const fetchData = useCallback(async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/this-month-expire`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(filter),
                    sort,
                },
            });
            setData(data.data.contracts);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    const downloadExcelFile = () => {
        const now = moment().format("YYYY-MM-DD_HH-mm-ss");
        const fileName = `Contract_Expiring_This_Month_${now}.xlsx`;

        axios({
            url: process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/contracts/export/this-month-expire",
            method: "GET",
            responseType: "blob", // important
            params: {
                filter: JSON.stringify(filter),
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        });
    };

    const columns = [
        {
            title: "Organization",
            field: "org_name",
            width: "50%",
            render: (rowData) => (rowData.org_id === 1 || rowData.org_id === 2 ? `${rowData.org_name} (${rowData.ref_org})` : rowData.org_name),
        },
        {
            title: "Office Phone",
            field: "office_phone",
            cellStyle: { width: 145, maxWidth: 145 },
            headerStyle: { width: 145, maxWidth: 145 },
        },
        {
            title: "End Date",
            field: "end_date",
            render: (rowData) => moment(rowData.end_date).format("YYYY-MM-DD"),
            align: "center",
            cellStyle: { width: 120, maxWidth: 120 },
            headerStyle: { width: 120, maxWidth: 120 },
        },
    ];

    const actions = [];

    if (identity.user.role_id === 1 || identity.user.role_id === 2) {
        actions.push({
            icon: () => <Icons.Edit />,
            tooltip: "Edit Contract Information",
            onClick: (event, rowData) => handleOpen("edit", rowData),
        });
    } else {
        actions.push({
            icon: () => <Icons.Preview />,
            tooltip: "View Contract Information",
            onClick: (event, rowData) => handleOpen("view", rowData),
        });
    }

    return (
        <Container maxWidth="xl" style={{ padding: 6 }}>
            <MaterialTable
                title="Contracts List"
                icons={Icons}
                columns={columns}
                data={data}
                page={page}
                totalCount={count}
                style={{ padding: "0px" }}
                options={{
                    pageSize: perPage,
                    pageSizeOptions: [10, 20, 30, 50],
                    search: false,
                    filtering: false,
                    sorting: false,
                    actionsColumnIndex: -1,
                    headerStyle: {
                        fontWeight: "bold",
                        backgroundColor: "#f1f1f1",
                        color: "#212122",
                    },
                    maxBodyHeight: "600px",
                    paginationType: "stepped",
                    padding: "dense",
                    draggable: false,
                    rowStyle: (rowData) => ({
                        backgroundColor: selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                    }),
                }}
                localization={{
                    pagination: {
                        labelRowsPerPage: "",
                        labelDisplayedRows: "{from}-{to} of {count}",
                        labelRowsSelect: "rows",
                    },
                    toolbar: {
                        nRowsSelected: "{0} row(s) selected",
                    },
                    header: {
                        actions: "",
                    },
                    body: {
                        emptyDataSourceMessage: "No data to display",
                        filterRow: {
                            filterTooltip: "Filter",
                        },
                    },
                }}
                actions={actions}
                onChangePage={(page) => setPage(page)}
                onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
                components={{
                    Toolbar: (props) => (
                        <>
                            <div>
                                <Grid container spacing={2} sx={{ px: 1.6, mt: 0, pb: 1.2 }}>
                                    <Grid item xs={12} sm={8}>
                                        <CardHeader
                                            sx={{ p: 0 }}
                                            title={
                                                <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                    Contract Expiring This Month
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} style={{ textAlign: "right" }}>
                                        {(identity.user.role_id === 1 || identity.user.role_id === 2) && (
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => downloadExcelFile()}
                                                    variant="outlined"
                                                    startIcon={<Icons.FileDownload fontWeight="bold" />}
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 800,
                                                        paddingTop: 0.5,
                                                        paddingBottom: 0.5,
                                                    }}
                                                >
                                                    Export
                                                </Button>
                                            </ButtonGroup>
                                        )}
                                    </Grid>
                                </Grid>
                            </div>
                            <form
                                style={{
                                    borderStyle: "solid",
                                    borderWidth: "thin",
                                    borderColor: "#e1e1e1",
                                }}
                            >
                                <Grid container spacing={2} sx={{ padding: 1.4, paddingTop: 1.9 }}>
                                    <Grid item xs={12} sm={6} md={5.7} xl={6}>
                                        <TextField
                                            autoComplete="off"
                                            fullWidth
                                            label="Organization"
                                            name="org_name"
                                            variant="outlined"
                                            autoFocus={organizationInputRef.current === document.activeElement}
                                            value={filter.org_name}
                                            onChange={handleFilterChange}
                                            inputRef={organizationInputRef}
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4} md={3.7} xl={4}>
                                        {/* <TextField
                                            fullWidth
                                            select
                                            label="Manage By"
                                            variant="outlined"
                                            name="country_id"
                                            value={filter.country_id}
                                            onChange={handleFilterChange}
                                            size="small"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        >
                                            <MenuItem value={""}>All</MenuItem>
                                            {Countries.map((country) => (
                                                <MenuItem key={country.id} value={country.id}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </TextField> */}
                                        <CountrySelect 
                                            value={filter.country_id === undefined ? "" : filter.country_id} 
                                            onChange={handleFilterChange} 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2} xl={2}>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="error"
                                            sx={{
                                                color: "#ffffff",
                                                marginTop: "2px",
                                            }}
                                            onClick={handleResetFilter}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    ),
                }}
            />
            <EditContractForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
            <ViewContractForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
        </Container>
    );
};
