import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, TextField, Button, Grid, Typography, CardHeader, Autocomplete, Checkbox, Backdrop } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { GetTokenIdentity } from "../../utils/Token";
import { Countries } from "../../utils/Lookup";
import { GroupHeader, GroupItems, CustomStyles } from "../../utils/Styled";
import { Icons } from "../../utils/Icon";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";
import HashLoader from "react-spinners/HashLoader";
import Swal from "sweetalert2";

import CountrySelect from "../../components/common/CountrySelect";

export const OrganizationProductAnalysisReport = () => {
    const navigate = useNavigate();

    const classes = CustomStyles;
    
    const [backdrop, setBackdrop] = useState(false);
    const showBackdrop = (visible) => setBackdrop(visible);
    
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const [filter, setFilter] = useState({
        country_id: identity.user.country_id,
        product_columns: [],
        product_includes: [],
        product_excludes: [],
    });

    const [productsData, setProductsData] = useState([]);
    const [productsIncludeExcludeData, setProductsIncludeExcludeData] = useState([]);

    const isProductContainExcluded = (productIncludes, productExcludes) => {
        return productIncludes.some((productId) => productExcludes.includes(productId));
    };

    const handleFilterChange = (event) => {
        setFilter({ ...filter, [event.target.name]: event.target.value });
        setFilter((prevFilter) => {
            if (prevFilter.country_id !== filter.country_id) {
                return { ...prevFilter, 
                    product_columns: [],
                    product_includes: [],
                    product_excludes: [],
                };
            }
            return prevFilter;
        });
    };

    const handleProductColumnFilterChange = (selectedValue) => {
        setFilter({
            ...filter,
            product_columns: selectedValue,
            product_includes: selectedValue.length > 0 ? filter.product_includes.filter((value) => selectedValue.includes(value)) : [],
            product_excludes: selectedValue.length > 0 ? filter.product_excludes.filter((value) => selectedValue.includes(value)) : [],
        });
    };

    const handleProductIncludeFilterChange = (selectedValue) => {
        setFilter({ ...filter, product_includes: selectedValue });
    };

    const handleProductExcludeFilterChange = (selectedValue) => {
        setFilter({ ...filter, product_excludes: selectedValue });
    };

    const downloadExcelFile = () => {
        showBackdrop(true);

        try {
            const now = moment().format("YYYY-MM-DD_HH-mm-ss");
            const fileName = `Organization_Product_Analysis_Report_${now}.xlsx`;

            let customFilter = { ...filter };

            if (customFilter.product_columns && customFilter.product_columns.length > 0) {
                const isValid = !isProductContainExcluded(customFilter.product_includes, customFilter.product_excludes);

                if (!isValid) {
                    showBackdrop(false);
                    Swal.fire({
                        icon: "info",
                        title: "Invalid Value",
                        text: `Product Includes and Product Excludes cannot have the same value.`,
                        confirmButtonColor: "#fe7320",
                    });
                    return;
                }

                if (customFilter.product_columns && customFilter.product_columns.length > 0) customFilter.product_columns = customFilter.product_columns.map((v) => v.product_id);
                if (customFilter.product_includes && customFilter.product_includes.length > 0) customFilter.product_includes = customFilter.product_includes.map((v) => v.product_id);
                if (customFilter.product_excludes && customFilter.product_excludes.length > 0) customFilter.product_excludes = customFilter.product_excludes.map((v) => v.product_id);

                axios({
                    url: process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/reports/organization-product-analysis-report/export",
                    method: "GET",
                    responseType: "blob", // important
                    params: {
                        filter: JSON.stringify(customFilter),
                    },
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", fileName);
                    document.body.appendChild(link);
                    link.click();
                    showBackdrop(false);
                });
            } else {
                showBackdrop(false);
                Swal.fire({
                    icon: "info",
                    title: "Fill Required Product Columns Before Downloading",
                    text: "Please complete the mandatory product columns before proceeding with the download. Accurate completion of this field is crucial for proper processing of your request.",
                    confirmButtonColor: "#fe7320",
                });
            }
        } catch (error) {
            showBackdrop(false);
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                showBackdrop(false);
                Swal.fire({
                    icon: "error",
                    title: "Internal Server Error",
                    text: "Sorry, we encountered an error while exporting the report. Please try again later or contact support if the issue persists.",
                    confirmButtonColor: "#fe7320",
                });
            }
        }
    };
    
    useEffect(() => {
        setProductsIncludeExcludeData(filter.product_columns);
    }, [filter]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/products/select?country=${filter.country_id}`)
            .then((response) => {
                setProductsData(response.data.data.products);
            })
            .catch((error) => console.log(error));
    }, [filter.country_id]);

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <MaterialTable
                    title="Organization Product Analysis Report"
                    columns={[]}
                    data={[]}
                    page={0}
                    totalCount={0}
                    style={{ padding: "24px" }}
                    options={{ paging: false }}
                    localization={{ body: { emptyDataSourceMessage: "" } }}
                    actions={[]}
                    onChangePage={(page) => 0}
                    onChangeRowsPerPage={(perPage) => 10}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={8}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        Organization Product Analysis Report
                                                    </Typography>
                                                }
                                            />
                                            <Button color="error" onClick={() => navigate("/reports")}>
                                                Back to Report List
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>

                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                        <Grid item xs={12} sm={9} md={10.5} style={{ paddingTop: 8 }}>
                                            <Autocomplete
                                                multiple
                                                disableCloseOnSelect
                                                size="small"
                                                name="product_columns"
                                                options={productsData}
                                                value={filter.product_columns}
                                                groupBy={(option) => {
                                                    const country = Countries.find((country) => country.id === option.country_id);
                                                    return country ? country.name : "Unknown";
                                                }}
                                                getOptionLabel={(option) => option.product_description}
                                                onChange={(_, selectedValue) => handleProductColumnFilterChange(selectedValue)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={Icons.CheckBoxBlankIcon}
                                                            checkedIcon={Icons.CheckBoxCheckedIcon}
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                            onClick={(e) => e.preventDefault()}
                                                        />
                                                        {option.product_description}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        id="product_columns"
                                                        label="Product Columns"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                renderGroup={(params) => {
                                                    return (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>{params.children}</GroupItems>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={1.5} xl={1.5}>
                                            <CountrySelect 
                                                value={filter.country_id === undefined ? "" : filter.country_id} 
                                                onChange={handleFilterChange}
                                            />
                                            {/* <TextField
                                                fullWidth
                                                select
                                                label="Manage By"
                                                variant="outlined"
                                                name="country_id"
                                                value={filter.country_id}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {Countries.map((country) => (
                                                    <MenuItem key={country.id} value={country.id}>
                                                        {country.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                        </Grid>
                                        <Grid item xs={12} sm={9} md={10.5} style={{ paddingTop: 7 }}>
                                            <Autocomplete
                                                multiple
                                                disableCloseOnSelect
                                                noOptionsText={"Please select the Product Columns first"}
                                                size="small"
                                                name="product_includes"
                                                options={productsIncludeExcludeData}
                                                value={filter.product_includes}
                                                groupBy={(option) => {
                                                    const country = Countries.find((country) => country.id === option.country_id);
                                                    return country ? country.name : "Unknown";
                                                }}
                                                getOptionLabel={(option) => option.product_description}
                                                onChange={(_, selectedValue) => handleProductIncludeFilterChange(selectedValue)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={Icons.CheckBoxBlankIcon}
                                                            checkedIcon={Icons.CheckBoxCheckedIcon}
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                            onClick={(e) => e.preventDefault()}
                                                        />
                                                        {option.product_description}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        id="product_includes"
                                                        label="Product Includes"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                renderGroup={(params) => {
                                                    return (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>{params.children}</GroupItems>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} md={10.5} style={{ paddingTop: 7 }}>
                                            <Autocomplete
                                                multiple
                                                disableCloseOnSelect
                                                noOptionsText={"Please select the Product Columns first"}
                                                size="small"
                                                name="product_excludes"
                                                options={productsIncludeExcludeData}
                                                value={filter.product_excludes}
                                                groupBy={(option) => {
                                                    const country = Countries.find((country) => country.id === option.country_id);
                                                    return country ? country.name : "Unknown";
                                                }}
                                                getOptionLabel={(option) => option.product_description}
                                                onChange={(_, selectedValue) => handleProductExcludeFilterChange(selectedValue)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox
                                                            icon={Icons.CheckBoxBlankIcon}
                                                            checkedIcon={Icons.CheckBoxCheckedIcon}
                                                            style={{
                                                                marginRight: 8,
                                                            }}
                                                            checked={selected}
                                                            onClick={(e) => e.preventDefault()}
                                                        />
                                                        {option.product_description}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        id="product_excludes"
                                                        label="Product Excludes"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                renderGroup={(params) => {
                                                    return (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>{params.children}</GroupItems>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={1.5}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "0px",
                                                }}
                                                onClick={downloadExcelFile}
                                            >
                                                Download
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdrop}>
                    <HashLoader color="#ffffff" loading={true} size={150} />
                </Backdrop>
            </Container>
        </div>
    );
};
