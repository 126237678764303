import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Grid, Typography, CardHeader } from "@mui/material";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import MaterialTable from "material-table";

export const ReportsPage = () => {
    const navigate = useNavigate();
    const classes = CustomStyles;

    const [data] = useState([
        {
            report_id: 1,
            name: "Organization Product Analysis Report",
            description: "This report provides a comprehensive analysis of organizations and their products to determine the best fit for further analysis.",
            url: "/reports/organization-product-analysis-report",
        },
    ]);

    const [count] = useState(1);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);

    const columns = [
        {
            title: "Name",
            field: "name",
            cellStyle: { width: 348, maxWidth: 348 },
            headerStyle: { width: 348, maxWidth: 348 },
        },
        { title: "Description", field: "description", witdh: "100%" },
    ];

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <MaterialTable
                    title="Report List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "24px" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false,
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                        },
                    }}
                    actions={[
                        {
                            icon: () => <Icons.Preview />,
                            tooltip: "View Report",
                            onClick: (event, rowData) => navigate(rowData.url),
                        },
                    ]}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={6}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        Report List
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        ),
                    }}
                />
            </Container>
        </div>
    );
};
