import React, { useEffect } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { Container } from "@mui/material";
import { useAuth } from "../../hooks/useAuth";
import { AppBar } from "../../layouts/AppBar";

export const ProtectedLayout = () => {
    const { token, logout } = useAuth();
    const outlet = useOutlet();

    useEffect(() => {
        const handleStorage = (event) => {
            if (event.key === "token" && event.newValue === "null" && event.oldValue !== "null") {
                const pathname = window.location.pathname;
                if (pathname !== "/") {
                    logout("Your account has been logged out of a session.");
                }
            }
        };

        window.addEventListener("storage", handleStorage);

        return () => {
            window.removeEventListener("storage", handleStorage);
        };
    }, [logout]);

    if (!token) {
        return <Navigate to="/" />;
    }

    return (
        <div>
            <AppBar
                pages={[
                    { label: "Dashboard", path: "dashboard", role: [1, 2, 3], maintenance: false },
                    { label: "Organizations", path: "organizations", role: [1, 2, 3], maintenance: false },
                    { label: "Contracts", path: "contracts", role: [1, 2, 3], maintenance: false },
                    { label: "Services", path: "services", role: [1, 2, 3], maintenance: false },
                    { label: "Products", path: "products", role: [1, 2, 3], maintenance: true },
                    { label: "System Plugins", path: "system-plugins", role: [1, 2, 3], maintenance: true },
                    { label: "Reports", path: "reports", role: [1, 2], maintenance: false },
                    { label: "Announcements", path: "announcements", role: [1], maintenance: true },
                    { label: "Doc No Settings", path: "doc-no-settings", role: [1], maintenance: true },
                    { label: "Users", path: "users", role: [1], maintenance: true },
                    { label: "Groups", path: "groups", role: [1], maintenance: true },
                    { label: "Logs", path: "logs", role: [1], maintenance: true },          
                ]}
            />
            <Container maxWidth="xl" sx={{ p: 0 }} md={{ p: 0 }}>
                {outlet}
            </Container>
        </div>
    );
};
