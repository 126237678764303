import { createTheme, ThemeProvider } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: { main: "#fe7320" },
        blue: { main: "#1976d2" },
    },
    typography: {
        fontFamily: "Open Sans, sans-serif",
    },
});

const Theme = ({ children }) => {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
