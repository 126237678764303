import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import Theme from "./theme";
import App from "./App";
// import Snowfall from 'react-snowfall';
// import Snow from './assets/snow.png';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

// const snowflake1 = document.createElement('img')
// snowflake1.src = Snow

// const images = [snowflake1]

root.render(
    <BrowserRouter>
        <AuthProvider>
            <Theme>
                {/* <Snowfall
                    color="#e91e63"
                    snowflakeCount={100}
                    radius={[10, 30]}
                    speed={[0.5, 3]}
                    images={images}
                /> */}
                <App />
            </Theme>
        </AuthProvider>
    </BrowserRouter>
);
