import React, { useEffect } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, MenuItem, Divider, Chip, FormControlLabel, Checkbox, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { ServiceStatus } from "../../../utils/Lookup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import moment from "moment";

export default function ViewServiceForm({ open, handleClose, rowData }) {

    useEffect(() => {
        if (open) {
            //
        }
    }, [open]);

    if (!rowData) return null;

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">View Service Information</DialogTitle>
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Service Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="service_date"
                            label="Service Date"
                            type="text"
                            fullWidth
                            defaultValue={moment(rowData.service_date).format("YYYY-MM-DD")}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="service_no"
                            label="Service No"
                            type="text"
                            fullWidth
                            required
                            value={rowData.service_no}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            label="Status"
                            type="text"
                            select
                            fullWidth
                            required
                            defaultValue={rowData.status}
                            InputProps={{ readOnly: true }}
                        >
                            {ServiceStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="org_name"
                            label="Organization"
                            type="text"
                            fullWidth
                            required
                            defaultValue={rowData.org_name}
                            inputProps={{ style: { textTransform: 'uppercase' } }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            value={rowData.email}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="office_phone"
                            label="Office Phone"
                            type="text"
                            fullWidth
                            value={rowData.office_phone}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="mobile_phone"
                            label="Mobile Phone"
                            type="text"
                            fullWidth
                            value={rowData.mobile_phone}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pic_name"
                            label="PIC Name"
                            type="text"
                            fullWidth
                            value={rowData.pic_name}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="contract_description"
                            label="Contract No"
                            type="text"
                            fullWidth
                            value={rowData.contract_description !== null ? rowData.contract_description : ""}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_org"
                            label="Old Organization (Import)"
                            type="text"
                            fullWidth
                            value={rowData.ref_org}
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="support_by"
                            label="Support By"
                            type="text"
                            fullWidth
                            value={rowData.support_name}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
                {rowData && rowData.details && rowData.details.length > 0 &&
                    <Divider style={{ paddingTop: "13px", paddingBottom: "0px" }}>
                        <Chip label="Product Details" />
                    </Divider>
                }
                {rowData && rowData.details && rowData.details.map((detail, index) => (
                    <Grid item container spacing={2} key={index} sx={{ pt: 0, mt: 1.5, pb: 1.5, border: "1px solid #c4c4c4" }}>
                        <Grid item xs={11}>
                            <Accordion defaultExpanded={true} sx={{ backgroundColor: "#565656" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography sx={{ color: "#ffffff" }}>Product #{index + 1}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: "#f3f3f3" }}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                        <TextField
                                            autoComplete="off"
                                            margin="dense"
                                            id={`product_id_${index}`}
                                            label="Product"
                                            type="text"
                                            fullWidth
                                            required
                                            defaultValue={detail.product_description}
                                            inputProps={{ style: { textTransform: 'uppercase' } }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                id="is_custom_version"
                                                margin="dense"
                                                checked={detail.is_custom_version === 1 ? true : false}   
                                                readOnly 
                                            />}
                                            label="Is Custom Version"
                                            sx={{ mt: 1.8 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                id="is_upgrade"
                                                margin="dense"
                                                checked={detail.is_upgrade === 1 ? true : false}   
                                                readOnly 
                                            />}
                                            label="Is Upgrade"
                                            sx={{ mt: 1.8 }}
                                        />
                                    </Grid>
                                    { detail.is_custom_version === 0 &&
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id="version_date"
                                                label="System Version"
                                                type="text"
                                                fullWidth
                                                value={detail.version_date !== null && detail.version_date !== "" ? moment(detail.version_date).format("YYYY-MM-DD") : ""}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 0 && detail.is_upgrade === 1 &&
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id="from_version_date"
                                                label="From System Version"
                                                type="text"
                                                fullWidth
                                                value={detail.from_version_date !== null && detail.from_version_date !== "" ? moment(detail.from_version_date).format("YYYY-MM-DD") : ""}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 1 && 
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id="version_text"
                                                label="System Version (String)"
                                                type="text"
                                                fullWidth
                                                value={detail.version_text}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 1 && detail.is_upgrade === 1 && 
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id="from_version_text"
                                                label="From System Version (String)"
                                                type="text"
                                                fullWidth
                                                value={detail.from_version_text}
                                                InputProps={{ readOnly: true }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={9}>
                                        <TextField
                                            autoComplete="off"
                                            id={`ref_product_${index}`}
                                            label="Old Product (Import)"
                                            type="text"
                                            fullWidth
                                            value={detail.ref_product}
                                            inputProps={{
                                                style: { textTransform: "uppercase" },
                                            }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={<Checkbox
                                            id={`is_system_plugin_${index}`}
                                            checked={detail.is_system_plugin === 1 ? true : false}
                                            readOnly
                                        />}
                                        label="Is Use System Plugin"
                                        sx={{ mt: 1 }}
                                    />
                                    </Grid>
                                    {detail.is_system_plugin === 1 &&
                                        <>
                                            <Grid item xs={12}>
                                                <TextField
                                                    autoComplete="off"
                                                    margin="dense"
                                                    id={`system_plugin_id_${index}`}
                                                    label="System Plugin"
                                                    type="text"
                                                    fullWidth
                                                    required
                                                    defaultValue={detail.system_plugin_description}
                                                    InputProps={{ readOnly: true }}
                                                />
                                                {/* <Autocomplete
                                                    options={systemPluginsData}
                                                    groupBy={(option) => {
                                                        const country = Countries.find((country) => country.id === option.country_id);
                                                        return country ? country.name : "Unknown";
                                                    }}
                                                    defaultValue={systemPluginsData.find((option) => option.system_plugin_id === detail.system_plugin_id)}
                                                    isOptionEqualToValue={(option, value) => option.system_plugin_id === value.system_plugin_id}
                                                    getOptionLabel={(option) => option.system_plugin_description}
                                                    onChange={(e, value) => {
                                                        handleDetailChange(index, "system_plugin_id", value ? value.system_plugin_id : "")
                                                    }}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.system_plugin_id}>
                                                                {option.system_plugin_description}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField 
                                                        {...params} 
                                                        id="system_plugin_id" 
                                                        label="System Plugin" 
                                                        fullWidth 
                                                        required 
                                                        error={detail.system_plugin_id === "" || detail.system_plugin_id === null ? true : false} 
                                                        helperText={detail.system_plugin_id === "" || detail.system_plugin_id === null ? "System Plugin is required." : ""} 
                                                    />}
                                                    renderGroup={(params) => {
                                                        return (
                                                            <li key={params.key}>
                                                                <GroupHeader>{params.group}</GroupHeader>
                                                                <GroupItems>{params.children}</GroupItems>
                                                            </li>
                                                        );
                                                    }}
                                                /> */}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField 
                                                    fullWidth 
                                                    label="System Plugin Note" 
                                                    multiline 
                                                    rows={4} 
                                                    value={detail.system_plugin_note}
                                                    InputProps={{ readOnly: true }}
                                                />
                                            </Grid>
                                        </>
                                    }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    </Grid>
                ))}
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="remarks"
                            label="Remark (Job Description)"
                            type="text"
                            multiline
                            rows={8}
                            fullWidth
                            value={rowData.remarks}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pending_remarks"
                            label="Pending Remark (Outstanding Jobs)"
                            type="text"
                            multiline
                            rows={8}
                            fullWidth
                            value={rowData.pending_remarks}
                            InputProps={{ readOnly: true }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
