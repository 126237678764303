import { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Divider, Chip, MenuItem, FormControlLabel, Checkbox, Grid } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { GetTokenIdentity } from "../../../utils/Token";
import { DocNoType, DocNoStatus } from "../../../utils/Lookup";

import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";

export default function EditDocNoSettingForm({ open, handleClose, rowData, fetchData, userCountry }) {
    const { token } = useAuth();
    const identity = GetTokenIdentity(token);

    const [editData, setEditData] = useState({});

    const [errors, setErrors] = useState({ type: false, description: false, current_no: false, length: false, country_id: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "type" && value === "") {
            await setError("type", "Type is required.");
        } else if (field === "description" && value === "") {
            await setError("description", "Description is required.");
        } else if (field === "current_no" && value === "") {
            await setError("current_no", "Current No is required.");
        } else if (field === "length" && value === "") {
            await setError("length", "Length is required.");
        } else if (field === "country_id" && value === "") {
            await setError("country_id", "Manage By is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (rowData) {
            setEditData(rowData);
        }
        if (open) {
            setErrors({ type: false, description: false, current_no: false, length: false, country_id: false });
            setHelperText({});
            setErrorMessage("");
        }
    }, [open, identity.user.country_id, userCountry]);

    if (!rowData) return null;

    const handleEditDocNoSetting = async () => {
        try {
            const { type, description, prefix, current_no, length, is_auto_increment, status, country_id } = editData;
            const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/doc-no-settings/${rowData.doc_no_setting_id}`, {
                type,
                description,
                prefix,
                current_no,
                length,
                is_auto_increment: Boolean(is_auto_increment),
                status,
                country_id,
            });

            if (response.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Doc No Setting information updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while updating the doc no setting information. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create New Doc No Setting</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Doc No Details" />
                </Divider>
                <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="type"
                    select
                    label="Type"
                    type="number"
                    fullWidth
                    required
                    defaultValue={rowData.type}
                    error={errors.type}
                    helperText={helperText.type}
                    onChange={(e) => {
                        setEditData({ ...editData, type: e.target.value, });
                        validate("type", e.target.value);
                    }}
                >
                    {DocNoType.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                            {type.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    defaultValue={rowData.description}
                    error={errors.description}
                    helperText={helperText.description}
                    onChange={(e) => {
                        setEditData({ ...editData, description: e.target.value });
                        validate("description", e.target.value);
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="prefix"
                            label="Prefix"
                            type="text"
                            fullWidth
                            defaultValue={rowData.prefix}
                            error={errors.prefix}
                            helperText={helperText.prefix}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            onChange={(e) => {
                                setEditData({ ...editData, prefix: e.target.value ? e.target.value.toUpperCase() : "", });
                                validate("prefix", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="current_no"
                            label="Current No"
                            type="number"
                            fullWidth
                            required
                            defaultValue={rowData.current_no}
                            error={errors.current_no}
                            helperText={helperText.current_no}
                            onChange={(e) => {
                                setEditData({ ...editData, current_no: e.target.value, });
                                validate("current_no", e.target.value);
                            }}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="length"
                            label="Length"
                            type="number"
                            fullWidth
                            required
                            defaultValue={rowData.length}
                            error={errors.length}
                            helperText={helperText.length}
                            onChange={(e) => {
                                setEditData({ ...editData, length: e.target.value, });
                                validate("length", e.target.value);
                            }}
                        >
                        </TextField>
                    </Grid>
                </Grid>
                <FormControlLabel 
                    control={
                        <Checkbox 
                            id="is_auto_increment"
                            margin="dense"
                            defaultChecked={Boolean(rowData.is_auto_increment)} 
                            onChange={(e) => {
                                setEditData({ ...editData, is_auto_increment: e.target.checked, });
                            }}
                        />
                    }
                    label="Is Auto Increment" 
                    sx={{ my: 0.8 }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            select
                            label="Status"
                            type="number"
                            defaultValue={rowData.status}
                            fullWidth
                            required
                            error={errors.status}
                            helperText={helperText.status}
                            onChange={(e) => {
                                setEditData({ ...editData, status: e.target.value });
                                validate("status", e.target.value);
                            }}
                        >
                            {DocNoStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="country_id"
                            select
                            label="Manage By"
                            type="number"
                            defaultValue={_.findIndex(userCountry, { country_id: rowData.country_id }) < 0 ? "" : rowData.country_id}
                            fullWidth
                            required
                            error={errors.country_id}
                            helperText={helperText.country_id}
                            onChange={(e) => {
                                setEditData({ ...editData, country_id: e.target.value, });
                                validate("country_id", e.target.value);
                            }}
                        >
                            {userCountry.map((country) => (
                                <MenuItem key={country.country_id} value={country.country_id}>
                                    {country.country_name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleEditDocNoSetting} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Update
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
