import { useEffect, useState } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert, Divider, Chip, MenuItem } from "@mui/material";

import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";

export default function EditSystemPluginForm({ open, handleClose, rowData, fetchData, userCountry }) {
    const [editData, setEditData] = useState({});

    const [errors, setErrors] = useState({ system_plugin_name: false, system_plugin_description: false, country_id: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (rowData) {
            setEditData(rowData);
        }
        if (open) {
            setErrors({ system_plugin_name: false, system_plugin_description: false, country_id: false });
            setHelperText({});
            setErrorMessage("");
        }
    }, [rowData, open]);

    if (!rowData) return null;

    const setError = async (field, message) => {
        await setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
        await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "system_plugin_name" && value === "") {
            await setError("system_plugin_name", "Name is required.");
        } else if (field === "system_plugin_description" && value === "") {
            await setError("system_plugin_description", "Description is required.");
        } else if (field === "country_id" && value === "") {
            await setError("country_id", "Manage By is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    const handleEditSystemPlugin = async () => {
        try {
            const { system_plugin_name, system_plugin_description, country_id } = editData;
            const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/system-plugins/${rowData.system_plugin_id}`, {
                system_plugin_name,
                system_plugin_description,
                country_id,
            });

            if (response.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "System plugin information updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while updating the system plugin information. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit System Plugin Information</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="System Plugin Details" />
                </Divider>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="system_plugin_name"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    defaultValue={rowData.system_plugin_name}
                    error={errors.system_plugin_name}
                    helperText={helperText.system_plugin_name}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                        setEditData({ ...editData, system_plugin_name: e.target.value ? e.target.value.toUpperCase() : "", });
                        validate("system_plugin_name", e.target.value);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="system_plugin_description"
                    label="Description"
                    type="text"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    defaultValue={rowData.system_plugin_description}
                    error={errors.system_plugin_description}
                    helperText={helperText.system_plugin_description}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                        setEditData({ ...editData, system_plugin_description: e.target.value ? e.target.value.toUpperCase() : "", });
                        validate("system_plugin_description", e.target.value);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="country_id"
                    select
                    label="Manage By"
                    type="number"
                    fullWidth
                    required
                    defaultValue={_.findIndex(userCountry, { country_id: rowData.country_id }) < 0 ? "" : rowData.country_id}
                    error={errors.country_id}
                    helperText={helperText.country_id}
                    onChange={(e) => {
                        setEditData({ ...editData, country_id: e.target.value, });
                        validate("country_id", e.target.value);
                    }}
                >
                    {userCountry.map((country) => (
                        <MenuItem key={country.country_id} value={country.country_id}>
                            {country.country_name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleEditSystemPlugin} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Update
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
