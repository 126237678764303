import { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Divider, Chip, MenuItem } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { GetTokenIdentity } from "../../../utils/Token";

import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";

export default function CreateProductForm({ open, handleClose, fetchData, userCountry }) {
    const { token } = useAuth();
    const identity = GetTokenIdentity(token);

    const [newData, setNewData] = useState({});

    const [errors, setErrors] = useState({ product_name: false, product_description: false, country_id: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "product_name" && value === "") {
            await setError("product_name", "Name is required.");
        } else if (field === "product_description" && value === "") {
            await setError("product_description", "Description is required.");
        } else if (field === "country_id" && value === "") {
            await setError("country_id", "Manage By is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (open) {
            setNewData({ product_name: "", product_description: "", country_id: _.findIndex(userCountry, { country_id: identity.user.country_id }) < 0 ? "" : identity.user.country_id });
            setErrors({ product_name: false, product_description: false, country_id: false });
            setHelperText({});
            setErrorMessage("");
        }
    }, [open, identity.user.country_id, userCountry]);

    const handleCreateProduct = async () => {
        let isValid = true;

        if (newData["product_name"] === "") isValid = false;
        if (newData["product_description"] === "") isValid = false;
        if (newData["country_id"] === "") isValid = false;

        Object.keys(newData).forEach(async (field) => {
            await validate(field, newData[field]);
        });

        if (isValid) {
            await setErrorMessage("");
            await createProduct();
        } else {
            await setErrorMessage(`Please fill all the required fields.`);
        }
    };

    const createProduct = async () => {
        try {
            const { product_name, product_description, country_id } = newData;
            const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/create", {
                product_name,
                product_description,
                country_id,
            });

            if (response.status === 201) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Product created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while creating the product. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create New Product</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Product Details" />
                </Divider>
                <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="product_name"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    error={errors.product_name}
                    helperText={helperText.product_name}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                        setNewData({ ...newData, product_name: e.target.value ? e.target.value.toUpperCase() : "", });
                        validate("product_name", e.target.value);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="product_description"
                    label="Description"
                    type="product_description"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    error={errors.product_description}
                    helperText={helperText.product_description}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                        setNewData({ ...newData, product_description: e.target.value ? e.target.value.toUpperCase() : "", });
                        validate("product_description", e.target.value);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="country_id"
                    select
                    label="Manage By"
                    type="number"
                    defaultValue={_.findIndex(userCountry, { country_id: identity.user.country_id }) < 0 ? "" : identity.user.country_id}
                    fullWidth
                    required
                    error={errors.country_id}
                    helperText={helperText.country_id}
                    onChange={(e) => {
                        setNewData({ ...newData, country_id: e.target.value, });
                        validate("country_id", e.target.value);
                    }}
                >
                    {userCountry.map((country) => (
                        <MenuItem key={country.country_id} value={country.country_id}>
                            {country.country_name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleCreateProduct} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Create
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
