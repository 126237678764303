import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, MenuItem, Button, Grid, Dialog } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { ContractStatus } from "../utils/Lookup";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import DateRangePicker from "../components/common/DateRangePicker";
import CreateContractForm from "../components/forms/contracts/Create";
import EditContractForm from "../components/forms/contracts/Edit";
import ViewContractForm from "../components/forms/contracts/View";
import DeleteContractForm from "../components/forms/contracts/Delete";
import ImportContractForm from "../components/forms/contracts/Import";

export const ContractsPage = ({ rowData }) => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const subjectInputRef = useRef(null);
    const invoiceNoInputRef = useRef(null);
    const ProductInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({ start_date: null, end_date: null, status: "", org_id: rowData ? rowData.org_id : "", });
    const [sort, setSort] = useState({});

    const [open, setOpen] = useState({ create: false, edit: false, view: false, import: false, });
    const [selectedRow, setSelectedRow] = useState({});

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ start_date: null, end_date: null, status: "", org_id: rowData ? rowData.org_id : "" });
        setStartDate(null);
        setEndDate(null);
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const fetchData = useCallback(async () => {
        try {
            if (filter.org_id === "") {
                setData([]);
                setCount(0);
            }

            let customFilter = { ...filter };
            customFilter.start_date = moment(customFilter.start_date).isValid() ? moment(customFilter.start_date).format("YYYY-MM-DD") : "";
            customFilter.end_date = moment(customFilter.end_date).isValid() ? moment(customFilter.end_date).format("YYYY-MM-DD") : "";
            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(customFilter),
                    sort,
                },
            });
            setData(data.data.contracts);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    useEffect(() => {
        setPage(0);
        setFilter(prevFilter => ({ ...prevFilter, start_date: startDate, end_date: endDate }));
    }, [startDate, endDate]);

    const columns = [
        {   title: "Product", 
            field: "product_description", 
            cellStyle: { width: 900, maxWidth: 900 },
            headerStyle: { width: 900, maxWidth: 900 },
        },
        // {
        //     title: "Organization",
        //     field: "org_name",
        //     cellStyle: { width: 540, maxWidth: 540 },
        //     headerStyle: { width: 540, maxWidth: 540 },
        //     render: (rowData) => (rowData.org_id === 1 ? `${rowData.org_name} (${rowData.ref_org})` : rowData.org_name),
        // },
        {
            title: "Start Date",
            field: "start_date",
            cellStyle: { width: 150, maxWidth: 150 },
            headerStyle: { width: 150, maxWidth: 150 },
            render: (rowData) => moment(rowData.start_date).format("YYYY-MM-DD"),
        },
        {
            title: "End Date",
            field: "end_date",
            cellStyle: { width: 150, maxWidth: 150 },
            headerStyle: { width: 150, maxWidth: 150 },
            render: (rowData) => moment(rowData.end_date).format("YYYY-MM-DD"),
        },
        // { title: "Product", field: "product_description", width: "50%" },
        {
            title: "Status",
            field: "status",
            // cellStyle: { width: 95, maxWidth: 95 },
            // headerStyle: { width: 95, maxWidth: 95 },
            render: (rowData) => ContractStatus.find((x) => x.id === rowData.status).name,
            width: "50%"
        },
    ];

    const actions = [];

    if (identity.user.role_id === 1 || identity.user.role_id === 2) {
        actions.push({
            icon: () => <Icons.Edit />,
            tooltip: "Edit Contract Information",
            onClick: (event, rowData) => handleOpen("edit", rowData),
        });
        actions.push({
            icon: () => <Icons.Delete />,
            tooltip: "Delete Contract",
            onClick: (event, rowData) => DeleteContractForm(rowData, fetchData),
        });
    } else {
        actions.push({
            icon: () => <Icons.Preview />,
            tooltip: "View Contract Information",
            onClick: (event, rowData) => handleOpen("view", rowData),
        });
    }

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 10 }}>
                <MaterialTable
                    title="Contracts List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "0px", boxShadow: "unset" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false,
                        rowStyle: (rowData) => {
                            let backgroundColor = "";
                            switch (rowData.status) {
                                case 1:
                                    backgroundColor = "#ffffcc";
                                    break;
                                case 2:
                                    backgroundColor = "#ebebeb";
                                    break;
                                case 3:
                                    backgroundColor = "#fbddea";
                                    break;
                                default:
                                    break;
                            }

                            return { backgroundColor };
                        },
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3 }}>
                                        <Grid item xs={12} sm={12} style={{ textAlign: "right", paddingTop: "8px" }}>
                                            {(identity.user.role_id === 1 || identity.user.role_id === 2) && (
                                                <Button
                                                    onClick={() => handleOpen("create", null)}
                                                    variant="outlined"
                                                    startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 800,
                                                        paddingTop: 0.5,
                                                        paddingBottom: 0.5,
                                                    }}
                                                >
                                                    Create Contract
                                                </Button>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>
                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1 }}>
                                        <Grid item xs={12} sm={1.3}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Contract No"
                                                name="contract_subject"
                                                variant="outlined"
                                                autoFocus={subjectInputRef.current === document.activeElement}
                                                value={filter.contract_subject}
                                                onChange={handleFilterChange}
                                                inputRef={subjectInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1.3}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Invoice No"
                                                name="invoice_no"
                                                variant="outlined"
                                                autoFocus={invoiceNoInputRef.current === document.activeElement}
                                                value={filter.invoice_no}
                                                onChange={handleFilterChange}
                                                inputRef={invoiceNoInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3.5} md={4.5}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Product"
                                                name="product_description"
                                                variant="outlined"
                                                autoFocus={ProductInputRef.current === document.activeElement}
                                                value={filter.product_description}
                                                onChange={handleFilterChange}
                                                inputRef={ProductInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1.3}>
                                            <TextField
                                                label="End Date (Start)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={startDate ? formatDate(startDate) : ""}
                                                // onChange={(event) => setStartDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                                                <DateRangePicker onClose={handleDialogClose} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                                            </Dialog>
                                        </Grid>
                                        <Grid item xs={12} sm={1.3}>
                                            <TextField
                                                label="End Date (End)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={endDate ? formatDate(endDate) : ""}
                                                // onChange={(event) => setEndDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1.3}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Status"
                                                variant="outlined"
                                                name="status"
                                                value={filter.status}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {ContractStatus.map((status) => (
                                                    <MenuItem key={status.id} value={status.id}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "2px",
                                                }}
                                                onClick={handleResetFilter}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <CreateContractForm open={open.create} handleClose={() => handleClose("create")} rowData={rowData} fetchData={fetchData} />
                <EditContractForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
                <ViewContractForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
                <ImportContractForm open={open.import} handleClose={() => handleClose("import")} fetchData={fetchData} />
            </Container>
        </div>
    );
};
