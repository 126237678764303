import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, Button, Grid, Typography, ButtonGroup, CardHeader } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import CreateSystemPluginForm from "../components/forms/systemPlugins/Create";
import EditSystemPluginForm from "../components/forms/systemPlugins/Edit";
import ViewSystemPluginForm from "../components/forms/systemPlugins/View";
import DeleteSystemPluginForm from "../components/forms/systemPlugins/Delete";
import ImportSystemPluginForm from "../components/forms/systemPlugins/Import";

import CountrySelect from "../components/common/CountrySelect";

export const SystemPluginsPage = () => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const nameInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({ country_id: identity.user.country_id });
    const [sort, setSort] = useState({});

    const [userCountry, setUserCountry] = useState([]);

    useEffect(() => {
        async function fetchCountryData() {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/access-country`);
            setUserCountry(response.data.data.countries);
        }
        fetchCountryData();
    }, []);

    const [open, setOpen] = useState({
        create: false,
        edit: false,
        view: false,
        import: false,
    });

    const [selectedRow, setSelectedRow] = useState({});

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ country_id: identity.user.country_id });
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const downloadExcelFile = () => {
        const now = moment().format("YYYY-MM-DD_HH-mm-ss");
        const fileName = `System_Plugins_${now}.xlsx`;

        axios({
            url: process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/system-plugins/export",
            method: "GET",
            responseType: "blob", // important
            params: {
                filter: JSON.stringify(filter),
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        });
    };

    const fetchData = useCallback(async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/system-plugins`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(filter),
                    sort,
                },
            });
            setData(data.data.systemPlugins);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    const columns = [
        {
            title: "Name",
            field: "system_plugin_name",
            cellStyle: { width: 348, maxWidth: 348 },
            headerStyle: { width: 348, maxWidth: 348 },
        },
        { title: "Description", field: "system_plugin_description", width: "80%" },
    ];

    const actions = [];

    if (identity.user.role_id === 1 || identity.user.role_id === 2) {
        actions.push({
            icon: () => <Icons.Edit />,
            tooltip: "Edit System Plugin Information",
            onClick: (event, rowData) => handleOpen("edit", rowData),
        });
        actions.push({
            icon: () => <Icons.Delete />,
            tooltip: "Delete System Plugin",
            onClick: (event, rowData) => DeleteSystemPluginForm(rowData, fetchData),
        });
    } else {
        actions.push({
            icon: () => <Icons.Preview />,
            tooltip: "View System Plugin Information",
            onClick: (event, rowData) => handleOpen("view", rowData),
        });
    }

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <MaterialTable
                    title="System Plugin List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "24px" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false,
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={3}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        System Plugin List
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                            {(identity.user.role_id === 1 || identity.user.role_id === 2) && (
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => handleOpen("create", null)}
                                                        variant="outlined"
                                                        startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Create
                                                    </Button>
                                                    {/* {identity.user.role_id === 1 && (
                                                        <Button
                                                            onClick={() => handleOpen("import", null)}
                                                            variant="outlined"
                                                            startIcon={<Icons.Import fontWeight="bold" />}
                                                            color="primary"
                                                            sx={{
                                                                fontWeight: 800,
                                                                paddingTop: 0.5,
                                                                paddingBottom: 0.5,
                                                            }}
                                                        >
                                                            Import
                                                        </Button>
                                                    )} */}
                                                    <Button
                                                        onClick={() => downloadExcelFile()}
                                                        variant="outlined"
                                                        startIcon={<Icons.FileDownload fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Export
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>

                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                        <Grid item xs={12} sm={3} md={3}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Name"
                                                name="system_plugin_name"
                                                variant="outlined"
                                                autoFocus={nameInputRef.current === document.activeElement}
                                                value={filter.system_plugin_name}
                                                onChange={handleFilterChange}
                                                inputRef={nameInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={5}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Description"
                                                name="system_plugin_description"
                                                variant="outlined"
                                                autoFocus={descriptionInputRef.current === document.activeElement}
                                                value={filter.system_plugin_description}
                                                onChange={handleFilterChange}
                                                inputRef={descriptionInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={1.5} xl={1.5}>
                                            {/* <TextField
                                                fullWidth
                                                select
                                                label="Manage By"
                                                variant="outlined"
                                                name="country_id"
                                                value={filter.country_id}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {Countries.map((country) => (
                                                    <MenuItem key={country.id} value={country.id}>
                                                        {country.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                            <CountrySelect 
                                                value={filter.country_id === undefined ? "" : filter.country_id} 
                                                onChange={handleFilterChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "2px",
                                                }}
                                                onClick={handleResetFilter}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <CreateSystemPluginForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} userCountry={userCountry} />
                <EditSystemPluginForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} userCountry={userCountry} />
                <ViewSystemPluginForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
                <ImportSystemPluginForm open={open.import} handleClose={() => handleClose("import")} fetchData={fetchData} userCountry={userCountry} />
            </Container>
        </div>
    );
};
