import React, { useState, useEffect } from "react";
import { Container, Box, Grid, Alert, AlertTitle } from "@mui/material";
import { CustomStyles } from "../utils/Styled";

import { ExpiringThisMonth } from "./contracts/ExpiringThisMonth";
import { ExpiringNextMonth } from "./contracts/ExpiringNextMonth";

import axios from "axios";

export const DashboardPage = () => {
    const classes = CustomStyles;

    const [ announcements, setAnnouncements ] = useState("");
    const [ malaysiaAnnouncementContent, setMalaysiaAnnouncementContent ] = useState("");
    const [ malaysiaShowMore, setMalaysiaShowMore ]  = useState(false);
    const [ singaporeAnnouncementContent, setSingaporeAnnouncementContent ] = useState("");
    const [ singaporeShowMore, setSingaporeShowMore ]  = useState(false);

    useEffect(() => {
        async function fetchAnnouncementsData() {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + 'api/v1/announcements/user');
            setAnnouncements(response.data.data);

            const parser = new DOMParser();
            const parsedMalaysiaContent = parser.parseFromString(response.data.data.announcement_my.announcement_content, 'text/html');
            const malaysiaParagraphs = parsedMalaysiaContent.querySelectorAll('p');
            if (malaysiaParagraphs.length > 5) {
                setMalaysiaShowMore(malaysiaParagraphs.length > 5);
                const firstFiveParagraphs = Array.from(malaysiaParagraphs).slice(0, 5);
                setMalaysiaAnnouncementContent(firstFiveParagraphs.map((p) => p.outerHTML).join(''));
            } else {
                setMalaysiaAnnouncementContent(response.data.data.announcement_my.announcement_content);
            }

            const parsedSingaporeContent = parser.parseFromString(response.data.data.announcement_sg.announcement_content, 'text/html');
            const singaporeParagraphs = parsedSingaporeContent.querySelectorAll('p');
            if (singaporeParagraphs.length > 5) {
                setSingaporeShowMore(singaporeParagraphs.length > 5);
                const firstFiveParagraphs = Array.from(singaporeParagraphs).slice(0, 5);
                setSingaporeAnnouncementContent(firstFiveParagraphs.map((p) => p.outerHTML).join(''));
            } else {
                setSingaporeAnnouncementContent(response.data.data.announcement_sg.announcement_content);
            }
        }
        fetchAnnouncementsData();
      }, []);

    return (
        <div>
            <div className={classes.background} />
            <Container component="main" maxWidth="xl" style={{ padding: 0 }}>
                <Grid item xs={12}>
                    { announcements && announcements.system && announcements.system.status === 0 && 
                        <Alert severity="error" sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <AlertTitle>System Announcement</AlertTitle>
                            <div className="announcement-dangerously-html" dangerouslySetInnerHTML={{ __html: announcements.system.announcement_content }}></div>
                        </Alert>
                    }
                    { announcements && announcements.announcement_my && announcements.announcement_my.status === 0 && 
                        <Alert severity="info" sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <AlertTitle>{announcements.announcement_my.announcement_name} @{announcements.announcement_my.user_name}</AlertTitle>
                            <div className="announcement-dangerously-html" dangerouslySetInnerHTML={{ __html: malaysiaAnnouncementContent }}></div>
                            { malaysiaShowMore && (
                                <a href={`/announcement-detail`} className="announcement-more" target="_blank" rel="noreferrer">
                                    View More
                                </a>
                            )}
                        </Alert>
                    }
                    { announcements && announcements.announcement_sg && announcements.announcement_sg.status === 0 && 
                        <Alert severity="info" sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <AlertTitle>{announcements.announcement_sg.announcement_name} @{announcements.announcement_sg.user_name}</AlertTitle>
                            <div className="announcement-dangerously-html" dangerouslySetInnerHTML={{ __html: singaporeAnnouncementContent }}></div>
                            { singaporeShowMore && (
                                <a href={`/announcement-detail`} className="announcement-more" target="_blank" rel="noreferrer">
                                    View More
                                </a>
                            )}
                        </Alert>
                    }
                    <Box component="main" width="100%" sx={{ marginTop: 1 }}>
                        <Grid container spacing={2} columns={12}>
                            <Grid item xs={12} md={6}>
                                <ExpiringThisMonth />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <ExpiringNextMonth />
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Container>
        </div>
    );
};
