import { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Divider, Chip, MenuItem } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { GetTokenIdentity } from "../../../utils/Token";

import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";

export default function CreateSystemPluginForm({ open, handleClose, fetchData, userCountry }) {
    const { token } = useAuth();
    const identity = GetTokenIdentity(token);

    const [newData, setNewData] = useState({});

    const [errors, setErrors] = useState({ system_plugin_name: false, system_plugin_description: false, country_id: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "system_plugin_name" && value === "") {
            await setError("system_plugin_name", "Name is required.");
        } else if (field === "system_plugin_description" && value === "") {
            await setError("system_plugin_description", "Description is required.");
        } else if (field === "country_id" && value === "") {
            await setError("country_id", "Manage By is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (open) {
            setNewData({ system_plugin_name: "", system_plugin_description: "", country_id: _.findIndex(userCountry, { country_id: identity.user.country_id }) < 0 ? "" : identity.user.country_id });
            setErrors({ system_plugin_name: false, system_plugin_description: false, country_id: false });
            setHelperText({});
            setErrorMessage("");
        }
    }, [open, identity.user.country_id, userCountry]);

    const handleCreateSystemPlugin = async () => {
        let isValid = true;

        if (newData["system_plugin_name"] === "") isValid = false;
        if (newData["system_plugin_description"] === "") isValid = false;
        if (newData["country_id"] === "") isValid = false;

        Object.keys(newData).forEach(async (field) => {
            await validate(field, newData[field]);
        });

        if (isValid) {
            await setErrorMessage("");
            await createSystemPlugin();
        } else {
            await setErrorMessage(`Please fill all the required fields.`);
        }
    };

    const createSystemPlugin = async () => {
        try {
            const { system_plugin_name, system_plugin_description, country_id } = newData;
            const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/system-plugins/create", {
                system_plugin_name,
                system_plugin_description,
                country_id,
            });

            if (response.status === 201) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "System plugin created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while creating the system plugin. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create New System Plugin</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="System Plugin Details" />
                </Divider>
                <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="system_plugin_name"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    error={errors.system_plugin_name}
                    helperText={helperText.system_plugin_name}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                        setNewData({ ...newData, system_plugin_name: e.target.value ? e.target.value.toUpperCase() : "", });
                        validate("system_plugin_name", e.target.value);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="system_plugin_description"
                    label="Description"
                    type="system_plugin_description"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    error={errors.system_plugin_description}
                    helperText={helperText.system_plugin_description}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    onChange={(e) => {
                        setNewData({ ...newData, system_plugin_description: e.target.value ? e.target.value.toUpperCase() : "", });
                        validate("system_plugin_description", e.target.value);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="country_id"
                    select
                    label="Manage By"
                    type="number"
                    defaultValue={_.findIndex(userCountry, { country_id: identity.user.country_id }) < 0 ? "" : identity.user.country_id}
                    fullWidth
                    required
                    error={errors.country_id}
                    helperText={helperText.country_id}
                    onChange={(e) => {
                        setNewData({ ...newData, country_id: e.target.value, });
                        validate("country_id", e.target.value);
                    }}
                >
                    {userCountry.map((country) => (
                        <MenuItem key={country.country_id} value={country.country_id}>
                            {country.country_name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleCreateSystemPlugin} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Create
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
