import { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Divider, Chip, MenuItem, FormControlLabel, Checkbox, Grid } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { GetTokenIdentity } from "../../../utils/Token";
import { DocNoType } from "../../../utils/Lookup";

import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";

export default function CreateDocNoSettingForm({ open, handleClose, fetchData, userCountry }) {
    const { token } = useAuth();
    const identity = GetTokenIdentity(token);

    const [newData, setNewData] = useState({});

    const [errors, setErrors] = useState({ type: false, description: false, current_no: false, length: false, country_id: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "type" && value === "") {
            await setError("type", "Type is required.");
        } else if (field === "description" && value === "") {
            await setError("description", "Description is required.");
        } else if (field === "current_no" && value === "") {
            await setError("current_no", "Current No is required.");
        } else if (field === "length" && value === "") {
            await setError("length", "Length is required.");
        } else if (field === "country_id" && value === "") {
            await setError("country_id", "Manage By is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (open) {
            setNewData({ type: "", description: "", prefix: "", current_no: 1, length: 1, is_auto_increment: true, country_id: _.findIndex(userCountry, { country_id: identity.user.country_id }) < 0 ? "" : identity.user.country_id });
            setErrors({ type: false, description: false, current_no: false, length: false, country_id: false });
            setHelperText({});
            setErrorMessage("");
        }
    }, [open, identity.user.country_id, userCountry]);

    const handleCreateDocNoSetting = async () => {
        let isValid = true;

        if (newData["type"] === "") isValid = false;
        if (newData["description"] === "") isValid = false;
        if (newData["current_no"] === "") isValid = false;
        if (newData["length"] === "") isValid = false;
        if (newData["country_id"] === "") isValid = false;

        Object.keys(newData).forEach(async (field) => {
            await validate(field, newData[field]);
        });

        if (isValid) {
            await setErrorMessage("");
            await createDocNoSetting();
        } else {
            await setErrorMessage(`Please fill all the required fields.`);
        }
    };

    const createDocNoSetting = async () => {
        try {
            const { type, description, prefix, current_no, length, is_auto_increment, country_id } = newData;
            const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/doc-no-settings/create", {
                type,
                description,
                prefix,
                current_no,
                length,
                is_auto_increment,
                country_id,
            });

            if (response.status === 201) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Doc No Setting created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while creating the doc no setting. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create New Doc No Setting</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Doc No Details" />
                </Divider>
                <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="type"
                    select
                    label="Type"
                    type="number"
                    fullWidth
                    required
                    defaultValue={""}
                    error={errors.type}
                    helperText={helperText.type}
                    onChange={(e) => {
                        setNewData({ ...newData, type: e.target.value, });
                        validate("type", e.target.value);
                    }}
                >
                    {DocNoType.map((type) => (
                        <MenuItem key={type.id} value={type.id}>
                            {type.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="description"
                    label="Description"
                    type="text"
                    fullWidth
                    error={errors.description}
                    helperText={helperText.description}
                    onChange={(e) => {
                        setNewData({ ...newData, description: e.target.value });
                        validate("description", e.target.value);
                    }}
                />
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="prefix"
                            label="Prefix"
                            type="text"
                            fullWidth
                            error={errors.prefix}
                            helperText={helperText.prefix}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            onChange={(e) => {
                                setNewData({ ...newData, prefix: e.target.value ? e.target.value.toUpperCase() : "", });
                                validate("prefix", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="current_no"
                            label="Current No"
                            type="number"
                            fullWidth
                            required
                            defaultValue={1}
                            error={errors.current_no}
                            helperText={helperText.current_no}
                            onChange={(e) => {
                                setNewData({ ...newData, current_no: e.target.value, });
                                validate("current_no", e.target.value);
                            }}
                        >
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="length"
                            label="Length"
                            type="number"
                            fullWidth
                            required
                            defaultValue={1}
                            error={errors.length}
                            helperText={helperText.length}
                            onChange={(e) => {
                                setNewData({ ...newData, length: e.target.value, });
                                validate("length", e.target.value);
                            }}
                        >
                        </TextField>
                    </Grid>
                </Grid>
                <FormControlLabel 
                    control={
                        <Checkbox 
                            id="is_auto_increment"
                            margin="dense"
                            defaultChecked={true} 
                            onChange={(e) => {
                                setNewData({ ...newData, is_auto_increment: e.target.checked, });
                            }}
                        />
                    }
                    label="Is Auto Increment" 
                    sx={{ my: 0.8 }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="country_id"
                    select
                    label="Manage By"
                    type="number"
                    defaultValue={_.findIndex(userCountry, { country_id: identity.user.country_id }) < 0 ? "" : identity.user.country_id}
                    fullWidth
                    required
                    error={errors.country_id}
                    helperText={helperText.country_id}
                    onChange={(e) => {
                        setNewData({ ...newData, country_id: e.target.value, });
                        validate("country_id", e.target.value);
                    }}
                >
                    {userCountry.map((country) => (
                        <MenuItem key={country.country_id} value={country.country_id}>
                            {country.country_name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleCreateDocNoSetting} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Create
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
