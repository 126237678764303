import React, { useState, useEffect, useRef } from "react";
import { TextField, MenuItem, CircularProgress, ListItemIcon } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import axios from "axios";
import _ from "lodash";

const CountrySelect = ({ value, onChange }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [isMissingValue, setIsMissingValue] = useState(false);

  const initialValueRef = useRef(value);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/access-country`);
        const valueIndex = _.findIndex(response.data.data.countries, { country_id: initialValueRef.current });
        if (valueIndex < 0) {
          setIsMissingValue(true);
        }
        setCountries(response.data.data.countries);
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCountries();
  }, []);

  const menuItems = [];

  if (isLoading) {
    menuItems.push(
      <MenuItem key="loading" value="" disabled>
        <ListItemIcon>
          <CircularProgress size={20} />
        </ListItemIcon>
        Loading ...
      </MenuItem>
    );
  } else if (error) {
    menuItems.push(
      <MenuItem key="error" value="" disabled>
        <ListItemIcon>
          <ErrorOutline color="error" />
        </ListItemIcon>
        Error Fetching Countries
      </MenuItem>
    );
  } else {
    menuItems.push(
      <MenuItem key="all" value="">
        All
      </MenuItem>
    );
    countries.forEach((country) =>
      menuItems.push(
        <MenuItem key={country.country_id} value={country.country_id}>
          {country.country_name}
        </MenuItem>
      )
    );
  }

  return (
    <TextField
      select
      fullWidth
      label="Manage By"
      name="country_id"
      value={isMissingValue || isLoading ? "" : value}
      onChange={onChange}
      size="small"
      variant="outlined"
      InputLabelProps={{
        shrink: true,
      }}
    >
      {menuItems}
    </TextField>
  );
};

export default CountrySelect;
