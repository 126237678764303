import { styled, lighten, darken } from "@mui/system";

export const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.mode === "light" ? lighten(theme.palette.primary.light, 0.85) : darken(theme.palette.primary.main, 0.8),
    zIndex: 10000
}));

export const GroupItems = styled("ul")({
    padding: 0,
});

export const CustomStyles = styled("div")(({ theme }) => ({
    parent: {
        position: "relative",
        minHeight: "100vh",
    },
    background: {
        background: "rgba(239, 243, 246, 1)",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        filter: "blur(6px)",
        zIndex: -1,
    },
    cardTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
}));
