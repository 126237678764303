import { useEffect, useState, useCallback } from "react";
import { Container, Button, Grid, ButtonGroup, IconButton, Tooltip, Chip } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
// import { Countries, ServiceStatus } from "../utils/Lookup";
// import { GroupHeader, GroupItems } from "../utils/Styled";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

// import DateRangePicker from "../components/common/DateRangePicker";
import CreateServiceForm from "../components/forms/services/Create";
import EditServiceForm from "../components/forms/services/Edit";
import ViewServiceForm from "../components/forms/services/View";
import DeleteServiceForm from "../components/forms/services/Delete";
import CloseServiceForm from "../components/forms/services/Close";
import ViewContractForm from "../components/forms/contracts/View";

// import RelationshipServicesForm from "../components/forms/services/Services";

// import CountrySelect from "../components/common/CountrySelect";

export const RelationshipServicesPage = ({ rowData }) => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    // const organizationInputRef = useRef(null);
    // const serviceNoInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({
        start_date: null,
        end_date: null,
        status: "",
        user_id: "",
        country_id: "",
        service_id: rowData ? (rowData.ref_service_id > 0 ? rowData.ref_service_id : rowData.service_id) : "",
    });
    const [sort, setSort] = useState({});

    const [open, setOpen] = useState({
        create: false,
        edit: false,
        view: false,
        viewContract: false,
        viewService: false,
        closeService: false
    });
    const [selectedRow, setSelectedRow] = useState({});

    // const [usersData, setUsersData] = useState([]);
    // const [productsData, setProductsData] = useState([]);

    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    // const [isDialogOpen, setIsDialogOpen] = useState(false);

    // const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    // const handleDialogClose = () => {
    //     setIsDialogOpen(false);
    // };

    const handleOpen = (type, row) => {
        if (type === "viewContract") {
            axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/${row.contract_id}`)
            .then((response) => {
                setSelectedRow(response.data.data);
                setOpen({ ...open, [type]: true });
            })
            .catch((error) => console.log(error));
        } else {
            setOpen({ ...open, [type]: true });
            setSelectedRow(row);
        }
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    // const handleResetFilter = () => {
    //     setPage(0);
    //     setFilter({ start_date: null, end_date: null, status: 0, user_id: "", country_id: identity.user.country_id });
    //     setStartDate(null);
    //     setEndDate(null);
    // };

    // const handleFilterChange = (event) => {
    //     setPage(0);
    //     setFilter({ ...filter, [event.target.name]: event.target.value });
    //     setFilter((prevFilter) => {
    //         if (prevFilter.country_id !== filter.country_id) {
    //             return { ...prevFilter, product_id: [] };
    //         }
    //         return prevFilter;
    //     });
    // };

    // const handleProductFilterChange = (selectedValue) => {
    //     setPage(0);
    //     setFilter({ ...filter, product_id: selectedValue });
    // };

    // const handleUserFilterChange = (selectedValue) => {
    //     setPage(0);
    //     setFilter({ ...filter, user_id: selectedValue ? selectedValue.user_id : "" });
    // };

    const fetchData = useCallback(async () => {
        try {
            let customFilter = { ...filter };
            customFilter.start_date = moment(customFilter.start_date).isValid() ? moment(customFilter.start_date).format("YYYY-MM-DD") : "";
            customFilter.end_date = moment(customFilter.end_date).isValid() ? moment(customFilter.end_date).format("YYYY-MM-DD") : "";
            customFilter.user_id = customFilter.user_id ? customFilter.user_id : "";

            if (customFilter.product_id && customFilter.product_id.length > 0) customFilter.product_id = customFilter.product_id.map((v) => v.product_id);

            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/services`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(customFilter),
                    sort,
                },
            });
            setData(data.data.services);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    // useEffect(() => {
    //     setPage(0);
    //     setFilter(prevFilter => ({ ...prevFilter, start_date: startDate, end_date: endDate }));
    // }, [startDate, endDate]);

    // useEffect(() => {
    //     axios
    //         .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/select/access-country")
    //         .then((response) => {
    //             setUsersData(response.data.data.users);
    //         })
    //         .catch((error) => console.log(error));
    // }, []);

    // useEffect(() => {
    //     axios
    //         .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/products/select?country=${filter.country_id}`)
    //         .then((response) => {
    //             setProductsData(response.data.data.products);
    //         })
    //         .catch((error) => console.log(error));
    // }, [filter.country_id]);

    const columns = [
        {
            title: "Service Date",
            field: "service_date",
            cellStyle: { width: 130, maxWidth: 130 },
            headerStyle: { width: 130, maxWidth: 130 },
            render: (rowData) => moment(rowData.service_date).format("YYYY-MM-DD"),
        },
        {
            title: "Service No",
            field: "service_no",
            cellStyle: { width: 120, maxWidth: 120 },
            headerStyle: { width: 120, maxWidth: 120 }
        },
        {
            title: "Organization",
            field: "org_name",
            cellStyle: { width: 800, maxWidth: 800 },
            headerStyle: { width: 800, maxWidth: 800 },
            render: (rowData) => 
            <>
                {rowData.org_id === 1 || rowData.org_id === 2 ? `${rowData.org_name} (${rowData.ref_org})` : rowData.org_name}
                <br/>
                {rowData.details && rowData.details.map(detail => (
                    <Chip key={detail.product_id} label={detail.product_description} size="small" variant="outlined" sx={{ mx: 0.3, my: 0.1 }}/>
                ))}
            </>
        },
        {   title: "Support By", field: "support_name", width: "50%" },
        {   
            title: "Status", field: "status", 
            align: "center",
            cellStyle: { width: 55, maxWidth: 55 }, headerStyle: { width: 55, maxWidth: 55 },
            render: (rowData) => 
            <>
                { rowData.status === 0 ?
                    <Tooltip title={rowData.is_request_close === 1 ? "Pending & Requested Complete" : "Pending"}>
                        <IconButton color="primary" aria-label="completed" component="label" style={{ padding: 0, marginRight: 2 }} >
                            { rowData.is_request_close === 1 ? <Icons.PendingTwoToneIcon/> : <Icons.AccessTimeTwoToneIcon />}
                        </IconButton>
                    </Tooltip> 
                    :
                    <Tooltip title="Completed">
                        <IconButton color="primary" aria-label="completed" component="label" style={{ padding: 0, marginRight: 2 }} >
                            <Icons.VerifiedTwoToneIcon />
                        </IconButton>
                    </Tooltip> 
                }
            </>
        },
        {   
            title: "Views", field: "status", 
            align: "center",
            cellStyle: { width: 55, maxWidth: 55 }, headerStyle: { width: 55, maxWidth: 55 },
            render: (rowData) => 
            <>
                {rowData.contract_id > 0 && 
                    <Tooltip title="View Contract">
                        <IconButton color="primary" aria-label="view contract detail" component="label" style={{ padding: 0 }} onClick={() => handleOpen("viewContract", rowData)}>
                            <Icons.Summarize />
                        </IconButton>
                    </Tooltip> 
                }
            </>
        },
        {   
            title: "Actions", field: "status", 
            align: "center",
            cellStyle: { width: 100, maxWidth: 100 }, headerStyle: { width: 100, maxWidth: 100 },
            render: (rowData) => 
            <>
                {((rowData.status === 0 && rowData.support_by !== identity.user.user_id) || (rowData.status === 1)) && 
                    <Tooltip title="View Service Information">
                        <IconButton color="primary" aria-label="view service information" component="label" style={{ padding: 0 }} onClick={() => handleOpen("view", rowData)}>
                            <Icons.Preview />
                        </IconButton>
                    </Tooltip> 
                }
                {((rowData.status === 0 && rowData.support_by === identity.user.user_id) || identity.user.role_id === 1) && 
                    <Tooltip title="Edit Service Information">
                        <IconButton color="primary" aria-label="edit service information" component="label" style={{ padding: 0, marginLeft: 8 }} onClick={() => handleOpen("edit", rowData)}>
                            <Icons.Edit />
                        </IconButton>
                    </Tooltip> 
                }
                {rowData.status === 0 && rowData.support_by === identity.user.user_id && 
                    <Tooltip title="Delete Service">
                        <IconButton color="primary" aria-label="edit service information" component="label" style={{ padding: 0, marginLeft: 8 }} onClick={() => DeleteServiceForm(rowData, fetchData)}>
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip> 
                }
            </>
        },
    ];

    const actions = [];

    // actions.push({
    //     icon: () => <Icons.Edit />,
    //     tooltip: "Edit Service Information",
    //     onClick: (event, rowData) => handleOpen("edit", rowData),
    // });
    // actions.push({
    //     icon: () => <Icons.Delete />,
    //     tooltip: "Delete Service",
    //     onClick: (event, rowData) => DeleteServiceForm(rowData, fetchData),
    // });

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 10 }}>
                <MaterialTable
                    title="Services List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "0px", boxShadow: "unset" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 2 }}>
                                        <Grid item xs={12} sm={12} style={{ textAlign: "right", paddingTop: "8px" }}>
                                            <ButtonGroup>          
                                                <Button
                                                    onClick={() => handleOpen("create", null)}
                                                    variant="outlined"
                                                    startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 800,
                                                        paddingTop: 0.5,
                                                        paddingBottom: 0.5,
                                                    }}
                                                >
                                                    Create Relationship Service
                                                </Button>
                                                <Button
                                                    onClick={() => CloseServiceForm(rowData, fetchData)}
                                                    variant="outlined"
                                                    startIcon={<Icons.ClosedCaptionDisabledTwoToneIcon fontWeight="bold" />}
                                                    color="error"
                                                    sx={{
                                                        fontWeight: 800,
                                                        paddingTop: 0.5,
                                                        paddingBottom: 0.5,
                                                    }}
                                                >
                                                    Request Close Related Service
                                                </Button>
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </div>
                            </>
                        ),
                    }}
                />
                <CreateServiceForm open={open.create} handleClose={() => handleClose("create")} rowData={rowData} fetchData={fetchData} />
                <EditServiceForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
                <ViewServiceForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
                <ViewContractForm open={open.viewContract} handleClose={() => handleClose("viewContract")} rowData={selectedRow} />
                {/* <RelationshipServicesForm open={open.viewService} handleClose={() => handleClose("viewService")} rowData={selectedRow} /> */}
            </Container>
        </div>
    );
};
