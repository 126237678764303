import React, { useState, useEffect } from "react";
import { Container, Grid, Alert, AlertTitle } from "@mui/material";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";

export const AnnouncementDetailPage = () => {
    const classes = CustomStyles;

    const [ announcements, setAnnouncements ] = useState("");

    useEffect(() => {
        async function fetchAnnouncementsData() {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + 'api/v1/announcements/user');
            setAnnouncements(response.data.data);
        }
        fetchAnnouncementsData();
      }, []);

    return (
        <div>
            <div className={classes.background} />
            <Container component="main" maxWidth="xl" style={{ padding: 0 }}>
                <Grid item xs={12}>
                    { announcements && announcements.announcement_my && announcements.announcement_my.status === 0 && 
                        <Alert severity="info" sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <AlertTitle>{announcements.announcement_my.announcement_name} @{announcements.announcement_my.user_name}</AlertTitle>
                            <div className="announcement-dangerously-html" dangerouslySetInnerHTML={{ __html: announcements.announcement_my.announcement_content }}></div>
                        </Alert>
                    }

                    { announcements && announcements.announcement_sg && announcements.announcement_sg.status === 0 && 
                        <Alert severity="info" sx={{ mt: 2, mb: 1, mx: 0.5 }}>
                            <AlertTitle>{announcements.announcement_sg.announcement_name} @{announcements.announcement_sg.user_name}</AlertTitle>
                            <div className="announcement-dangerously-html" dangerouslySetInnerHTML={{ __html: announcements.announcement_sg.announcement_content }}></div>
                        </Alert>
                    }
                </Grid>
            </Container>
        </div>
    );
};
