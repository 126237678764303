import React, { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Divider, Chip, CircularProgress, MenuItem } from "@mui/material";
import { Countries } from "../../../utils/Lookup";

import axios from "axios";
import Swal from "sweetalert2";

export default function ImportContractForm({ open, handleClose, fetchData }) {
    const [newData, setNewData] = useState({});

    const [errors, setErrors] = useState({ excel_file: false, country_id: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const validateExcelFile = (file) => {
        const validTypes = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];
        if (validTypes.indexOf(file.type) === -1) {
            return false;
        }
        return true;
    };

    const setError = async (field, message) => {
        await setErrors((prevErrors) => ({ ...prevErrors, [field]: true }));
        await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "excel_file" && !value) {
            await setError("excel_file", "Excel File is required.");
        } else if (field === "excel_file" && !validateExcelFile(value)) {
            await setError("excel_file", "File type must be an Excel file (.xlsx or .xls).");
        } else if (field === "country_id" && value === "") {
            await setError("country_id", "Manage By is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (open) {
            setNewData({ excel_file: "", country_id: "" });
            setErrors({ excel_file: false, country_id: false });
            setHelperText({});
            setErrorMessage("");
        }
    }, [open]);

    const handleImportContract = async () => {
        let isValid = true;

        if (newData["excel_file"] === "") isValid = false;
        if (newData["country_id"] === "") isValid = false;

        Object.keys(newData).forEach(async (field) => {
            await validate(field, newData[field]);
        });

        if (isValid) {
            await setErrorMessage("");
            await importContracts();
        } else {
            await setErrorMessage(`File type must be an Excel file (.xlsx or .xls).`);
        }
    };

    const importContracts = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("file", newData["excel_file"]);
            formData.append("country_id", newData.country_id);

            const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/contracts/import", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 201) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Contract imported successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }

            setIsLoading(false);
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while importing the contract. Please try again later.");
            }

            setIsLoading(false);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Import Contracts</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Excel File" />
                </Divider>
                <TextField
                    autoFocus
                    autoComplete="off"
                    margin="dense"
                    id="excel_file"
                    label="Excel File"
                    type="file"
                    fullWidth
                    required
                    disabled={isLoading}
                    error={errors.excel_file}
                    helperText={helperText.excel_file}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => {
                        setNewData({ ...newData, excel_file: e.target.files[0], });
                        validate("excel_file", e.target.files[0]);
                    }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="country_id"
                    select
                    label="Manage By"
                    type="number"
                    defaultValue={""}
                    fullWidth
                    required
                    disabled={isLoading}
                    error={errors.country_id}
                    helperText={helperText.country_id}
                    onChange={(e) => {
                        setNewData({ ...newData, country_id: e.target.value, });
                        validate("country_id", e.target.value);
                    }}
                >
                    {Countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                            {country.name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <div style={{ display: "inline-block" }}>{isLoading ? <CircularProgress size={24} style={{ marginRight: "18px" }} /> : ""}</div>
                <Button disabled={isLoading} onClick={handleImportContract} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Import
                </Button>
                <Button disabled={isLoading} onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
