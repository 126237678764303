import { useEffect, useState, useCallback } from "react";
import { Container, Grid, Typography, CardHeader, ButtonGroup, Button, TextField, MenuItem } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { DocNoType, DocNoStatus, Countries } from "../utils/Lookup";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import MaterialTable from "material-table";

import CreateDocNoSettingForm from "../components/forms/docNoSettings/Create";
import EditDocNoSettingForm from "../components/forms/docNoSettings/Edit";

import CountrySelect from "../components/common/CountrySelect";

export const DocNoSettingsPage = () => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({ status: 0, country_id: identity.user.country_id });
    const [sort, setSort] = useState({});

    const [userCountry, setUserCountry] = useState([]);

    useEffect(() => {
        async function fetchCountryData() {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/access-country`);
            setUserCountry(response.data.data.countries);
        }
        fetchCountryData();
    }, []);

    const [open, setOpen] = useState({
        create: false,
        edit: false,
    });

    const [selectedRow, setSelectedRow] = useState({});

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ status: 0, country_id: identity.user.country_id });
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const fetchData = useCallback(async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/doc-no-settings`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(filter),
                    sort,
                },
            });
            setData(data.data.doc_no_settings);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    const columns = [
        {
            title: "Type",
            field: "type",
            render: (rowData) => DocNoType.find((x) => x.id === rowData.type).name,
        },
        {
            title: "Prefix",
            field: "prefix",
        },
        {
            title: "Description",
            field: "description",
        },
        {
            title: "Current No",
            field: "current_no",
        },
        {
            title: "Length",
            field: "length",
        },
        {
            title: "Is Auto Increment",
            field: "is_auto_increment",
        },
        {
            title: "Status",
            field: "status",
            render: (rowData) => DocNoStatus.find((x) => x.id === rowData.status).name,
        },
        {
            title: "Manage By",
            field: "country_id",
            render: (rowData) => Countries.find((x) => x.id === rowData.country_id).name,
        },
    ];

    const actions = [];

    if (identity.user.role_id === 1 || identity.user.role_id === 2) {
        actions.push({
            icon: () => <Icons.Edit />,
            tooltip: "Edit Announcement Information",
            onClick: (event, rowData) => handleOpen("edit", rowData),
        });
    } 

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <MaterialTable
                    title="Setting List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "24px" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false,
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={3}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        Doc No Setting List
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                            {(identity.user.role_id === 1 || identity.user.role_id === 2) && (
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => handleOpen("create", null)}
                                                        variant="outlined"
                                                        startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Create
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>

                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                        <Grid item xs={12} sm={3} md={2.4} xl={2.4}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Type"
                                                variant="outlined"
                                                name="type"
                                                value={filter.type >= 0 ? filter.type : ""}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {DocNoType.map((type) => (
                                                    <MenuItem key={type.id} value={type.id}>
                                                        {type.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={2} xl={1.5}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Status"
                                                variant="outlined"
                                                name="status"
                                                value={filter.status}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                {DocNoStatus.map((status) => (
                                                    <MenuItem key={status.id} value={status.id}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={2} xl={1.5}>
                                            <CountrySelect 
                                                value={filter.country_id === undefined ? "" : filter.country_id} 
                                                onChange={handleFilterChange}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "2px",
                                                }}
                                                onClick={handleResetFilter}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <CreateDocNoSettingForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} userCountry={userCountry} />
                <EditDocNoSettingForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} userCountry={userCountry} />
            </Container>
        </div>
    );
};
