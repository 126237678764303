// import { useState } from 'react';
// import { TextField, Button, Grid } from '@mui/material';

// export const CreateServicePage = () => {
//   const [newData, setNewData] = useState({
//     serviceNo: '',
//     email: '',
//     detail: [
//       {
//         productName: '',
//         productCode: '',
//         productDesc: '',
//       },
//     ],
//   });

//   const [serviceNo, setServiceNo] = useState('');
//   const [email, setEmail] = useState('');
//   const [details, setDetails] = useState([
//     {
//       productName: '',
//       productCode: '',
//       productDesc: '',
//     },
//   ]);

//   const handleDetailChange = (index, field, value) => {
//     const newDetails = [...details];
//     newDetails[index][field] = value;
//     setDetails(newDetails);
//   };

//   const handleAddDetail = () => {
//     setDetails([
//       ...details,
//       {
//         productName: '',
//         productCode: '',
//         productDesc: '',
//       },
//     ]);
//   };

//   const handleRemoveDetail = (index) => {
//     const newDetails = [...details];
//     newDetails.splice(index, 1);
//     setDetails(newDetails);
//   };

//   const handleSubmit = (event) => {
//     event.preventDefault();
//     const serviceData = {
//       serviceNo,
//       email,
//       details,
//     };
//     console.log(serviceData); // replace with axios.post to send data to backend
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <Grid container spacing={2}>
//         <Grid item xs={12} sm={6}>
//           <TextField
//             required
//             fullWidth
//             label="Service No"
//             value={serviceNo}
//             onChange={(event) => setServiceNo(event.target.value)}
//           />
//         </Grid>
//         <Grid item xs={12} sm={6}>
//           <TextField
//             fullWidth
//             label="Email"
//             value={email}
//             onChange={(event) => setEmail(event.target.value)}
//           />
//         </Grid>
//       </Grid>
//       {details.map((detail, index) => (
//         <Grid container spacing={2} key={index}>
//           <Grid item xs={12} sm={4}>
//             <TextField
//               required
//               fullWidth
//               label="Product Name"
//               value={detail.productName}
//               onChange={(event) =>
//                 handleDetailChange(index, 'productName', event.target.value)
//               }
//             />
//           </Grid>
//           <Grid item xs={12} sm={4}>
//             <TextField
//               fullWidth
//               label="Product Code"
//               value={detail.productCode}
//               onChange={(event) =>
//                 handleDetailChange(index, 'productCode', event.target.value)
//               }
//             />
//           </Grid>
//           <Grid item xs={12} sm={3}>
//             <TextField
//               fullWidth
//               label="Product Desc"
//               value={detail.productDesc}
//               onChange={(event) =>
//                 handleDetailChange(index, 'productDesc', event.target.value)
//               }
//             />
//           </Grid>
//           <Grid item xs={12} sm={1}>
//             {index === details.length - 1 ? (
//               <Button variant="contained" onClick={handleAddDetail}>
//                 +
//               </Button>
//             ) : (
//               <Button
//                 variant="contained"
//                 onClick={() => handleRemoveDetail(index)}
//               >
//                 -
//               </Button>
//             )}
//           </Grid>
//         </Grid>
//       ))}
//       <Button type="submit" variant="contained">
//         Submit
//       </Button>
//     </form>
//   );
// };

import { useState } from 'react';
import { TextField, Button, Grid } from '@mui/material';

export const CreateServicePage = () => {
  const [newData, setNewData] = useState({
    serviceNo: '',
    email: '',
    details: [
      {
        productName: '',
        productCode: '',
        productDesc: '',
      },
    ],
  });

  const handleDetailChange = (index, field, value) => {
    const newDetails = [...newData.details];
    newDetails[index][field] = value;
    setNewData({ ...newData, details: newDetails });
  };

  const handleAddDetail = () => {
    setNewData({
      ...newData,
      details: [
        ...newData.details,
        {
          productName: '',
          productCode: '',
          productDesc: '',
        },
      ],
    });
  };

  const handleRemoveDetail = (index) => {
    const newDetails = [...newData.details];
    newDetails.splice(index, 1);
    setNewData({ ...newData, details: newDetails });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log(newData); // replace with axios.post to send data to backend
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            fullWidth
            label="Service No"
            value={newData.serviceNo}
            onChange={(event) =>
              setNewData({ ...newData, serviceNo: event.target.value })
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label="Email"
            value={newData.email}
            onChange={(event) =>
              setNewData({ ...newData, email: event.target.value })
            }
          />
        </Grid>
      </Grid>
      {newData.details.map((detail, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} sm={4}>
            <TextField
              required
              fullWidth
              label="Product Name"
              value={detail.productName}
              onChange={(event) =>
                handleDetailChange(index, 'productName', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Product Code"
              value={detail.productCode}
              onChange={(event) =>
                handleDetailChange(index, 'productCode', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Product Desc"
              value={detail.productDesc}
              onChange={(event) =>
                handleDetailChange(index, 'productDesc', event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} sm={1}>
            {index === newData.details.length - 1 ? (
              <Button variant="contained" onClick={handleAddDetail}>
                +
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleRemoveDetail(index)}
              >
                -
              </Button>
            )}
          </Grid>
        </Grid>
      ))}
      <Button type="submit" variant="contained">
        Submit
      </Button>
    </form>
  );
};