import { useEffect, useState } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Alert, MenuItem, Grid } from "@mui/material";

import { EditorState } from "draft-js";
import { stateFromHTML } from 'draft-js-import-html';
import { stateToHTML } from 'draft-js-export-html';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { AnnouncementStatus } from "../../../utils/Lookup";

import axios from "axios";
import Swal from "sweetalert2";

export default function EditAnnouncementForm({ open, handleClose, rowData, fetchData }) {
    const [editData, setEditData] = useState({});

    const [errorMessage, setErrorMessage] = useState("");

    const [editorState, setEditorState] = useState(
        EditorState.createEmpty()
    );
      
    const onEditorStateChange = (newEditorState) => {
        setEditorState(newEditorState);
    };

    useEffect(() => {
        if (rowData) {
            setEditData(rowData);
            const newState = EditorState.createWithContent(stateFromHTML(rowData.announcement_content));
            setEditorState(newState);
        }
        if (open) {
            setErrorMessage("");
        }
    }, [rowData, open]);

    if (!rowData) return null;

    const handleEditAnnouncement = async () => {
        const contentState = editorState.getCurrentContent();

        let options = {
            entityStyleFn: (entity) => {
                const entityType = entity.get('type').toLowerCase();
                if (entityType === 'link') {
                const data = entity.getData();
                return {
                    element: 'a',
                    attributes: {
                    href: data.url,
                    target:'_blank'
                    },
                    style: {},
                };
                } 
            }
        };

        const html = stateToHTML(contentState, options);

        try {
            const { status } = editData;
            const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/announcements/${rowData.announcement_id}`, {
                announcement_content: html,
                status,
            });

            if (response.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Announcement information updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while updating the announcement information. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit Announcement Information</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Grid container spacing={2} sx={{ mb: 1 }}>
                    <Grid item xs={4}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            select
                            label="Status"
                            type="number"
                            defaultValue={rowData.status}
                            fullWidth
                            required
                            onChange={(e) => {
                                setEditData({ ...editData, status: e.target.value });
                            }}
                        >
                            {AnnouncementStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ height: "500px" }}>
                    <Grid item xs={12}>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={{
                                options: ["inline", "link"],
                                inline: {
                                  options: ["bold", "italic", "underline", "strikethrough"],
                                },
                                fontFamily: {
                                  options: ["Arial", "Georgia", "Impact", "Tahoma", "Times New Roman", "Verdana"],
                                },
                                fontSize: {
                                  options: [8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72],
                                },
                                colorPicker: {},
                              }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleEditAnnouncement} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Update
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
