export const DocNoType = [
    { id: 0, name: "Contract"},
    { id: 1, name: "Service"}
];

export const DocNoStatus = [
    { id: 0, name: "Active" },
    { id: 1, name: "In-Active" },
];

export const AnnouncementStatus = [
    { id: 0, name: "Active" },
    { id: 1, name: "In-Active" }
];

export const Countries = [
    { id: 158, name: "Malaysia" },
    { id: 198, name: "Singapore" },
    { id: 101, name: "Indonesia" }
];

export const ContractOrderBy = [
    { id: 0, name: "End Date DESC", field: "end_date", sort: "desc" },
    { id: 1, name: "Create Date DESC", field: "contract_id", sort: "desc" },
];

export const ContractStatus = [
    { id: 0, name: "Active" },
    { id: 1, name: "Unpaid" },
    { id: 2, name: "Expired" },
    { id: 3, name: "Cancel" }
];

export const ServiceStatus = [
    { id: 0, name: "Pending" },
    { id: 1, name: "Completed" },
];

export const IsFreeOfCharge = [
    { id: 0, name: "No" },
    { id: 1, name: "Yes" }
];

export const Roles = [
    { id: 1, name: "Aministrator" },
    { id: 2, name: "Manager" },
    { id: 3, name: "Support" }
];

export const UserStatus = [
    { id: 0, name: "Active" },
    { id: 1, name: "In-Active" }
];