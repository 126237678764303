import { Routes, Route } from "react-router-dom";

import { ProtectedLayout } from "./components/layout/Protected";
import { PublicLayout } from "./components/layout/Public";

import { DashboardPage } from "./pages/Dashboard";
import { LoginPage } from "./pages/Login";
import { ForgotPasswordPage } from "./pages/ForgotPassword";
import { ResetPasswordPage } from "./pages/ResetPassword";
import { OrganizationsPage } from "./pages/Organizations";
import { ContractsPage } from "./pages/Contracts";
import { ServicesPage } from "./pages/Services";
import { ProductsPage } from "./pages/Products";
import { SystemPluginsPage } from "./pages/SystemPlugins";
import { ReportsPage } from "./pages/Reports";
import { LogsPage } from "./pages/Logs";
import { UsersPage } from "./pages/Users";
import { GroupsPage } from "./pages/Groups";
import { NotFoundPage } from "./pages/NotFound";

import { OrganizationProductAnalysisReport } from "./pages/reports/OrganizationProductAnalysis";
import { CreateServicePage } from "./pages/CreateServicePage";
import { AnnouncementsPage } from "./pages/Announcements";
import { AnnouncementDetailPage } from "./pages/AnnouncementDetail";
import { DocNoSettingsPage } from "./pages/DocNoSettings";

export default function AppRoutes() {
    return (
        <Routes>
            <Route element={<PublicLayout />}>
                <Route path="/" element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
                <Route path="*" element={<NotFoundPage />} />
            </Route>

            <Route element={<ProtectedLayout />}>
                <Route path="/dashboard" element={<DashboardPage />} />
                <Route path="/organizations" element={<OrganizationsPage />} />
                <Route path="/contracts" element={<ContractsPage />} />
                <Route path="/services" element={<ServicesPage />} />
                <Route path="/products" element={<ProductsPage />} />
                <Route path="/system-plugins" element={<SystemPluginsPage />} />
                <Route path="/reports" element={<ReportsPage />} />
                <Route path="/reports/organization-product-analysis-report" element={<OrganizationProductAnalysisReport />} />
                <Route path="/logs" element={<LogsPage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/groups" element={<GroupsPage />} />
                <Route path="/servicesssss" element={<CreateServicePage />} />
                <Route path="/announcements" element={<AnnouncementsPage />} />
                <Route path="/announcement-detail" element={<AnnouncementDetailPage />} />
                <Route path="/doc-no-settings" element={<DocNoSettingsPage />} />
            </Route>
        </Routes>
    );
}
