import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, Chip, MenuItem } from "@mui/material";
import { Countries } from "../../../utils/Lookup";

export default function ViewSystemPluginForm({ open, handleClose, rowData }) {
    if (!rowData) return null;

    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">View System Plugin Information</DialogTitle>
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="System Plugin Details" />
                </Divider>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="system_plugin_name"
                    label="Name"
                    type="text"
                    fullWidth
                    required
                    defaultValue={rowData.system_plugin_name}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    InputProps={{ readOnly: true }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="system_plugin_description"
                    label="Description"
                    type="text"
                    multiline
                    rows={3}
                    fullWidth
                    required
                    defaultValue={rowData.system_plugin_description}
                    inputProps={{ style: { textTransform: "uppercase" } }}
                    InputProps={{ readOnly: true }}
                />
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="country_id"
                    select
                    label="Manage By"
                    type="number"
                    fullWidth
                    required
                    defaultValue={rowData.country_id}
                    InputProps={{ readOnly: true }}
                >
                    {Countries.map((country) => (
                        <MenuItem key={country.id} value={country.id}>
                            {country.name}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}
