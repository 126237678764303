import { forwardRef } from 'react';

import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import ViewColumn from '@mui/icons-material/ViewColumn';

// Two Tone
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PasswordTwoToneIcon from '@mui/icons-material/PasswordTwoTone';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import PublishTwoToneIcon from '@mui/icons-material/PublishTwoTone';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import SummarizeTwoToneIcon from '@mui/icons-material/SummarizeTwoTone';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import AccessTimeTwoToneIcon from '@mui/icons-material/AccessTimeTwoTone';
import PhoneForwardedTwoToneIcon from '@mui/icons-material/PhoneForwardedTwoTone';
import ClosedCaptionDisabledTwoToneIcon from '@mui/icons-material/ClosedCaptionDisabledTwoTone';
import PendingTwoToneIcon from '@mui/icons-material/PendingTwoTone';

// Check Box
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const iconColor = { color: '#fe7320' };

export const Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} style={ iconColor } />),
    AddIcon: forwardRef((props, ref) => <AddTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} style={ iconColor } />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} style={ iconColor } />),
    Delete: forwardRef((props, ref) => <DeleteTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={ iconColor } />),
    Edit: forwardRef((props, ref) => <ModeEditTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    Import: forwardRef((props, ref) => <PublishTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} style={ iconColor } />),
    FileDownload: forwardRef((props, ref) => <FileDownloadTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} style={ iconColor } />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} style={ iconColor } />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} style={ iconColor } />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} style={ iconColor } />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} style={ iconColor } />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} style={ iconColor } />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} style={ iconColor } />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} style={ iconColor } />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} style={ iconColor } />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} style={ iconColor } />),
    Password: forwardRef((props, ref) => <PasswordTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    Preview: forwardRef((props, ref) => <VisibilityTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    List: forwardRef((props, ref) => <ListTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    Summarize: forwardRef((props, ref) => <SummarizeTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    VerifiedTwoToneIcon: forwardRef((props, ref) => <VerifiedTwoToneIcon {...props} ref={ref} style={{ color: '#198754' }} />),
    AccessTimeTwoToneIcon: forwardRef((props, ref) => <AccessTimeTwoToneIcon {...props} ref={ref} style={{ color: '#efb60a' }} />),
    PhoneForwardedTwoToneIcon: forwardRef((props, ref) => <PhoneForwardedTwoToneIcon {...props} ref={ref} style={ iconColor } />),
    ClosedCaptionDisabledTwoToneIcon: forwardRef((props, ref) => <ClosedCaptionDisabledTwoToneIcon {...props} ref={ref} style={{ color: '#d32f2f' }} />),
    PendingTwoToneIcon: forwardRef((props, ref) => <PendingTwoToneIcon {...props} ref={ref} style={{ color: '#1976d2' }} />),

    // Check Box
    CheckBoxBlankIcon: (<CheckBoxOutlineBlankIcon fontSize="small" />),
    CheckBoxCheckedIcon: (<CheckBoxIcon fontSize="small" />),
};