import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, MenuItem, Button, Grid, Typography, ButtonGroup, Dialog, CardHeader } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { ContractStatus, ContractOrderBy } from "../utils/Lookup";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import _ from "lodash";
import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import DateRangePicker from "../components/common/DateRangePicker";
import CreateContractForm from "../components/forms/contracts/Create";
import EditContractForm from "../components/forms/contracts/Edit";
import ViewContractForm from "../components/forms/contracts/View";
import DeleteContractForm from "../components/forms/contracts/Delete";
import ImportContractForm from "../components/forms/contracts/Import";
import ContractServicesForm from "../components/forms/contracts/Services";

import CountrySelect from "../components/common/CountrySelect";

export const ContractsPage = () => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const organizationInputRef = useRef(null);
    const subjectInputRef = useRef(null);
    const invoiceNoInputRef = useRef(null);
    const productInputRef = useRef(null);
    const officePhoneInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({
        start_date: null,
        end_date: null,
        status: "",
        country_id: identity.user.country_id,
        order_by: 0
    });
    const [sort, setSort] = useState({});

    const [open, setOpen] = useState({
        create: false,
        edit: false,
        view: false,
        import: false,
        services: false,
    });
    const [selectedRow, setSelectedRow] = useState({});

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ start_date: null, end_date: null, status: "", country_id: identity.user.country_id, order_by: 0 });
        setStartDate(null);
        setEndDate(null);
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const downloadExcelFile = () => {
        const now = moment().format("YYYY-MM-DD_HH-mm-ss");
        const fileName = `Contracts_${now}.xlsx`;

        let customFilter = { ...filter };
        customFilter.start_date = moment(customFilter.start_date).isValid() ? moment(customFilter.start_date).format("YYYY-MM-DD") : "";
        customFilter.end_date = moment(customFilter.end_date).isValid() ? moment(customFilter.end_date).format("YYYY-MM-DD") : "";

        axios({
            url: process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/contracts/export",
            method: "GET",
            responseType: "blob", // important
            params: {
                filter: JSON.stringify(customFilter),
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        });
    };

    const fetchData = useCallback(async () => {
        try {
            let customFilter = { ...filter };
            customFilter.start_date = moment(customFilter.start_date).isValid() ? moment(customFilter.start_date).format("YYYY-MM-DD") : "";
            customFilter.end_date = moment(customFilter.end_date).isValid() ? moment(customFilter.end_date).format("YYYY-MM-DD") : "";
            const customSort = _.filter(ContractOrderBy, { id: customFilter.order_by });
            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(customFilter),
                    sort: JSON.stringify(customSort[0]),
                },
            });
            setData(data.data.contracts);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    useEffect(() => {
        setPage(0);
        setFilter(prevFilter => ({ ...prevFilter, start_date: startDate, end_date: endDate }));
    }, [startDate, endDate]);

    const columns = [
        {
            title: "Organization",
            field: "org_name",
            cellStyle: { width: 400, maxWidth: 400 },
            headerStyle: { width: 400, maxWidth: 400 },
            render: (rowData) => (rowData.org_id === 1 || rowData.org_id === 2 ? `${rowData.org_name} (${rowData.ref_org})` : rowData.org_name),
        },
        // {
        //     title: "Phone",
        //     field: "office_phone",
        //     cellStyle: { width: 150, maxWidth: 150 },
        //     headerStyle: { width: 150, maxWidth: 150 },
        //     render: (rowData) => (rowData.office_phone && rowData.mobile_phone ? `office phone: ${rowData.office_phone} \r\n mobile phone: ${rowData.mobile_phone}` : (rowData.office_phone ? `office phone: ${rowData.office_phone}` : `mobile phone: ${rowData.mobile_phone}`)),
        // },
        {
            title: "Office Phone",
            field: "office_phone",
            cellStyle: { width: 150, maxWidth: 150 },
            headerStyle: { width: 150, maxWidth: 150 },
        },
        {
            title: "Mobile Phone",
            field: "mobile_phone",
            cellStyle: { width: 150, maxWidth: 150 },
            headerStyle: { width: 150, maxWidth: 150 },
        },
        {
            title: "Start Date",
            field: "start_date",
            cellStyle: { width: 120, maxWidth: 120 },
            headerStyle: { width: 120, maxWidth: 120 },
            render: (rowData) => moment(rowData.start_date).format("YYYY-MM-DD"),
        },
        {
            title: "End Date",
            field: "end_date",
            cellStyle: { width: 120, maxWidth: 120 },
            headerStyle: { width: 120, maxWidth: 120 },
            render: (rowData) => moment(rowData.end_date).format("YYYY-MM-DD"),
        },
        { title: "Product", field: "product_description", width: "50%" },
        {
            title: "Status",
            field: "status",
            cellStyle: { width: 80, maxWidth: 80 },
            headerStyle: { width: 80, maxWidth: 80 },
            render: (rowData) => ContractStatus.find((x) => x.id === rowData.status).name,
        },
    ];

    const actions = [];

    // actions.push({
    //     icon: () => <Icons.Summarize />,
    //     tooltip: "View Contract Services",
    //     onClick: (event, rowData) => handleOpen("services", rowData),
    // });

    if (identity.user.role_id === 1 || identity.user.role_id === 2) {
        actions.push({
            icon: () => <Icons.Edit />,
            tooltip: "Edit Contract Information",
            onClick: (event, rowData) => handleOpen("edit", rowData),
        });
        actions.push({
            icon: () => <Icons.Delete />,
            tooltip: "Delete Contract",
            onClick: (event, rowData) => DeleteContractForm(rowData, fetchData),
        });
    } else {
        actions.push({
            icon: () => <Icons.Preview />,
            tooltip: "View Contract Information",
            onClick: (event, rowData) => handleOpen("view", rowData),
        });
    }

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <MaterialTable
                    title="Contracts List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "24px" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false,
                        rowStyle: (rowData) => {
                            let backgroundColor = "";
                            switch (rowData.status) {
                                case 1:
                                    backgroundColor = "#ffffcc";
                                    break;
                                case 2:
                                    backgroundColor = "#ebebeb";
                                    break;
                                case 3:
                                    backgroundColor = "#fbddea";
                                    break;
                                default:
                                    break;
                            }

                            return { backgroundColor };
                        },
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={3}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        Contract List
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                            {(identity.user.role_id === 1 || identity.user.role_id === 2) && (
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => handleOpen("create", null)}
                                                        variant="outlined"
                                                        startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Create
                                                    </Button>
                                                    {identity.user.role_id === 1 && (
                                                        <Button
                                                            onClick={() => handleOpen("import", null)}
                                                            variant="outlined"
                                                            startIcon={<Icons.Import fontWeight="bold" />}
                                                            color="primary"
                                                            sx={{
                                                                fontWeight: 800,
                                                                paddingTop: 0.5,
                                                                paddingBottom: 0.5,
                                                            }}
                                                        >
                                                            Import
                                                        </Button>
                                                    )}
                                                    <Button
                                                        onClick={() => downloadExcelFile()}
                                                        variant="outlined"
                                                        startIcon={<Icons.FileDownload fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Export
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>

                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                        <Grid item xs={6} sm={2} md={1.7} xl={1.7}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Contract No"
                                                name="contract_subject"
                                                variant="outlined"
                                                autoFocus={subjectInputRef.current === document.activeElement}
                                                value={filter.contract_subject}
                                                onChange={handleFilterChange}
                                                inputRef={subjectInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2} md={1.7} xl={1.7}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Invoice No"
                                                name="invoice_no"
                                                variant="outlined"
                                                autoFocus={invoiceNoInputRef.current === document.activeElement}
                                                value={filter.invoice_no}
                                                onChange={handleFilterChange}
                                                inputRef={invoiceNoInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3} xl={2.7}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Organization"
                                                name="org_name"
                                                variant="outlined"
                                                autoFocus={organizationInputRef.current === document.activeElement}
                                                value={filter.org_name}
                                                onChange={handleFilterChange}
                                                inputRef={organizationInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2.6} xl={2.1}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Office Phone or Mobile Phone"
                                                name="office_phone"
                                                variant="outlined"
                                                autoFocus={officePhoneInputRef.current === document.activeElement}
                                                value={filter.office_phone}
                                                onChange={handleFilterChange}
                                                inputRef={officePhoneInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={3} xl={2.7}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Product"
                                                name="product_description"
                                                variant="outlined"
                                                autoFocus={productInputRef.current === document.activeElement}
                                                value={filter.product_description}
                                                onChange={handleFilterChange}
                                                inputRef={productInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2.7} md={2} xl={1.7}>
                                            <TextField
                                                label="End Date (Start)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={startDate ? formatDate(startDate) : ""}
                                                // onChange={(event) => setStartDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                                                <DateRangePicker onClose={handleDialogClose} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                                            </Dialog>
                                        </Grid>
                                        <Grid item xs={6} sm={2.7} md={2} xl={1.7}>
                                            <TextField
                                                label="End Date (End)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={endDate ? formatDate(endDate) : ""}
                                                // onChange={(event) => setEndDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2.6} md={2.4} xl={1.5}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Status"
                                                variant="outlined"
                                                name="status"
                                                value={filter.status}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {ContractStatus.map((status) => (
                                                    <MenuItem key={status.id} value={status.id}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6} sm={3.8} md={2.4} xl={1.5}>
                                            <CountrySelect 
                                                value={filter.country_id === undefined ? "" : filter.country_id} 
                                                onChange={handleFilterChange} 
                                            />
                                            {/* <TextField
                                                fullWidth
                                                select
                                                label="Manage By"
                                                variant="outlined"
                                                name="country_id"
                                                value={filter.country_id}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {Countries.map((country) => (
                                                    <MenuItem key={country.id} value={country.id}>
                                                        {country.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField> */}
                                        </Grid>
                                        <Grid item xs={12} sm={5} md={3.2} xl={1.8}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Sort By"
                                                variant="outlined"
                                                name="order_by"
                                                value={filter.order_by}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                {ContractOrderBy.map((status) => (
                                                    <MenuItem key={status.id} value={status.id}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={6} sm={1.3} md={10.8} xl={2.8} sx={{ paddingTop: "0 !important" }}>
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "2px",
                                                }}
                                                onClick={handleResetFilter}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <CreateContractForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} />
                <EditContractForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
                <ViewContractForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
                <ImportContractForm open={open.import} handleClose={() => handleClose("import")} fetchData={fetchData} />
                <ContractServicesForm open={open.services} handleClose={() => handleClose("services")} rowData={selectedRow} />
            </Container>
        </div>
    );
};
