import { useEffect, useState, useCallback } from "react";
import { TextField, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, MenuItem, Alert, Autocomplete, Divider, Chip } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GroupHeader, GroupItems } from "../../../utils/Styled";
import { Countries, ContractStatus, IsFreeOfCharge } from "../../../utils/Lookup";
import { debounce } from "lodash";

import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export default function EditContractForm({ open, handleClose, rowData, fetchData }) {
    const [editData, setEditData] = useState({});

    const [errors, setErrors] = useState({ contract_subject: false, product_id: false, org_id: false, start_date: false, end_date: false, assigned_to: false, status: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [organizationsData, setOrganizationsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [contractSubjectData, setContractSubjectData] = useState([]);

    const [selectedOrganizationValue, setSelectedOrganizationValue] = useState(null);
    const [selectedProductValue, setSelectedProductValue] = useState(null);
    const [selectedUserValue, setSelectedUserValue] = useState(null);

    const [userContractSubjectInput, setUserContractSubjectInput] = useState("");

    const debouncedOnChange = debounce((inputValue, callback) => {
        if (inputValue.trim() !== "") {
            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/valid-contract-subject?id=${editData.contract_id}&text=${inputValue}`)
                .then((response) => {
                    if (response.data.data.valid) {
                        setErrors((prevErrors) => ({ ...prevErrors, contract_subject: true }));
                        setHelperText((prevHelperText) => ({ ...prevHelperText, contract_subject: "Contract No already exists" }));
                    } else {
                        setErrors((prevErrors) => ({ ...prevErrors, contract_subject: false }));
                        setHelperText((prevHelperText) => ({ ...prevHelperText, contract_subject: "" }));
                    }
                    return response.data.data.valid;
                })
                .catch((error) => {
                    console.error(error);
                    return false;
                });
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, contract_subject: true }));
            setHelperText((prevHelperText) => ({ ...prevHelperText, contract_subject: "Contract No is required." }));
            return false;
        }
    }, 500);

    function handleInputChange(event, newInputValue, callback) {
        setUserContractSubjectInput(newInputValue.toUpperCase());
        setEditData({ ...editData, contract_subject: newInputValue ? newInputValue.toUpperCase() : "", });
        debouncedOnChange(newInputValue, callback);
    }

    const fetchContractSubjectData = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/get-contract-subject?search=${userContractSubjectInput}`);
            setContractSubjectData(response.data.data.texts);
        } catch (error) {
            console.error(error);
        }
    }, [userContractSubjectInput]);
    
    useEffect(() => {
        fetchContractSubjectData();
    }, [fetchContractSubjectData]);

    useEffect(() => {
        if (rowData) {
            setEditData(rowData);
            setUserContractSubjectInput(rowData.contract_subject);
        }
        if (open) {
            setErrors({ contract_subject: false, product_id: false, org_id: false, start_date: false, end_date: false, assigned_to: false, status: false });
            setHelperText({});
            setErrorMessage("");

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/organizations/select")
                .then((response) => {
                    setOrganizationsData(response.data.data.organizations);
                    setSelectedOrganizationValue(response.data.data.organizations.find((option) => option.org_id === rowData.org_id));
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/select")
                .then((response) => {
                    setProductsData(response.data.data.products);
                    setSelectedProductValue(response.data.data.products.find((option) => option.product_id === rowData.product_id));
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/groups/select")
                .then((response) => {
                    setUsersData(response.data.data.users);
                    setSelectedUserValue(response.data.data.users.find((option) => option.id === rowData.assigned_to && option.is_group === rowData.is_assigned_to_group));
                })
                .catch((error) => console.log(error));
        }
    }, [rowData, open]);

    if (!rowData) return null;

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "contract_subject" && value === "") {
            await setError("contract_subject", "Contract No is required.");
        } else if (field === "product_id" && value === "") {
            await setError("product_id", "Product is required.");
        } else if (field === "org_id" && value === "") {
            await setError("org_id", "Organization is required.");
        } else if (field === "start_date" && (value === "" || value === null)) {
            await setError("start_date", "Start Date is required.");
        } else if (field === "start_date" && value !== null && editData["end_date"] <= value) {
            await setError("start_date", "Start Date cannot greater or equal to End Date.");
        } else if (field === "end_date" && (value === "" || value === null)) {
            await setError("end_date", "End Date is required.");
        } else if (field === "end_date" && value !== null && value <= editData["start_date"]) {
            await setError("end_date", "End Date cannot less than or equal to Start Date.");
        } else if (field === "assigned_to" && value === "") {
            await setError("assigned_to", "Assigned To is required.");
        } else if (field === "status" && value === "") {
            await setError("status", "Status is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    const handleEditContract = async () => {
        const { contract_subject, invoice_no, product_id, org_id, is_foc, is_assigned_to_group, assigned_to, remarks, ref_org, status } = editData;
        const start_date = moment(editData.start_date).format("YYYY-MM-DD");
        const end_date = moment(editData.end_date).format("YYYY-MM-DD");
        try {
            const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/${rowData.contract_id}`, {
                contract_subject, invoice_no, product_id, org_id, start_date, end_date, is_foc, is_assigned_to_group, assigned_to, remarks, ref_org, status,
            });

            if (response.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Contract information updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while updating the contract information. Please try again later.");
            }
        }
    };

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit Contract Information</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Contract Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Autocomplete
                            freeSolo
                            disableClearable
                            options={contractSubjectData}
                            getOptionLabel={(option) => option.contract_subject || ""}
                            inputValue={editData.contract_subject || ""}
                            onInputChange={(event, newInputValue) => {
                                handleInputChange(event, newInputValue.toUpperCase(), (isValid) => {
                                    validate("contract_subject", newInputValue.toUpperCase());
                                });
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    margin="dense"
                                    label="Contract No"
                                    required
                                    fullWidth
                                    error={errors.contract_subject}
                                    helperText={helperText.contract_subject}
                                    InputProps={{
                                        ...params.InputProps, type: "search",
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="invoice_no"
                            label="Invoice No"
                            type="text"
                            fullWidth
                            defaultValue={rowData.invoice_no}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            onChange={(e) => {
                                setEditData({ ...editData, invoice_no: e.target.value ? e.target.value.toUpperCase() : "", });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={organizationsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => (option ? option.org_name : "Select Organization")}
                            onChange={(event, value) => {
                                setSelectedOrganizationValue(value);
                                setEditData({ ...editData, org_id: value ? value.org_id : "", });
                                validate("org_id", value ? value.org_id : "");
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.org_id}>
                                        {option.org_name}
                                    </li>
                                );
                            }}
                            isOptionEqualToValue={(option, value) => option.org_id === value.org_id}
                            value={selectedOrganizationValue}
                            renderInput={(params) => <TextField {...params} margin="dense" id="org_id" label="Organization" fullWidth required error={errors.org_id} helperText={helperText.org_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            defaultValue={rowData.email}
                            InputProps={{ readOnly: true }}
                            sx={{ background: "#e7e7e79e" }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="office_phone"
                            label="Office Phone"
                            type="text"
                            fullWidth
                            defaultValue={rowData.office_phone}
                            InputProps={{ readOnly: true }}
                            sx={{ background: "#e7e7e79e" }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="mobile_phone"
                            label="Mobile Phone"
                            type="text"
                            fullWidth
                            defaultValue={rowData.mobile_phone}
                            InputProps={{ readOnly: true }}
                            sx={{ background: "#e7e7e79e" }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pic_name"
                            label="PIC Name"
                            type="text"
                            fullWidth
                            defaultValue={rowData.pic_name}
                            InputProps={{ readOnly: true }}
                            sx={{ background: "#e7e7e79e" }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={productsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => (option ? option.product_description : "Select product")}
                            onChange={(event, value) => {
                                setSelectedProductValue(value);
                                setEditData({ ...editData, product_id: value ? value.product_id : "", });
                                validate("product_id", value ? value.product_id : "");
                            }}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.product_id}>
                                        {option.product_description}
                                    </li>
                                );
                            }}
                            isOptionEqualToValue={(option, value) => option.product_id === value.product_id}
                            value={selectedProductValue}
                            renderInput={(params) => <TextField {...params} margin="dense" id="product_id" label="Product" fullWidth required error={errors.product_id} helperText={helperText.product_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Start Date"
                                inputFormat="YYYY-MM-DD"
                                value={editData.start_date}
                                onChange={(newValue) => {
                                    setEditData({ ...editData, start_date: newValue, });
                                    validate("start_date", newValue);
                                }}
                                renderInput={(params) => <TextField {...params} required fullWidth margin="dense" error={errors.start_date} helperText={helperText.start_date} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="End Date"
                                inputFormat="YYYY-MM-DD"
                                value={editData.end_date}
                                onChange={(newValue) => {
                                    setEditData({ ...editData, end_date: newValue, });
                                    validate("end_date", newValue);
                                }}
                                renderInput={(params) => <TextField {...params} required fullWidth margin="dense" error={errors.end_date} helperText={helperText.end_date} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            label="Status"
                            type="text"
                            select
                            fullWidth
                            required
                            defaultValue={rowData.status}
                            error={errors.status}
                            helperText={helperText.status}
                            onChange={(e) => {
                                setEditData({ ...editData, status: e.target.value, });
                                validate("status", e.target.value);
                            }}
                        >
                            {ContractStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid container item xs={3} alignItems="center" justifyContent="center">
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="is_foc"
                            label="Is Free Of Charge"
                            type="text"
                            select
                            fullWidth
                            required
                            defaultValue={rowData.is_foc}
                            error={errors.is_foc}
                            helperText={helperText.is_foc}
                            onChange={(e) => {
                                setEditData({ ...editData, is_foc: e.target.value, });
                                validate("is_foc", e.target.value);
                            }}
                        >
                            {IsFreeOfCharge.map((element) => (
                                <MenuItem key={element.id} value={element.id}>
                                    {element.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_org"
                            label="Old Organization (Import)"
                            type="text"
                            fullWidth
                            defaultValue={rowData.ref_org}
                            inputProps={{ style: { textTransform: "uppercase" } }}
                            onChange={(e) => {
                                setEditData({ ...editData, ref_org: e.target.value ? e.target.value.toUpperCase() : "", });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={usersData}
                            getOptionLabel={(option) => (option ? option.name : "Select user or group")}
                            groupBy={(option) => option.is_group}
                            onChange={(event, value) => {
                                setSelectedUserValue(value);
                                setEditData({ ...editData, assigned_to: value ? value.id : "", is_assigned_to_group: value ? value.is_group : 0, });
                                validate("assigned_to", value ? value.id : "");
                            }}
                            isOptionEqualToValue={(option, value) => option.id === value.id && option.is_group === value.is_group}
                            value={selectedUserValue}
                            renderInput={(params) => <TextField {...params} margin="dense" id="assigned_to" label="Assigned To" fullWidth required error={errors.assigned_to} helperText={helperText.assigned_to} />}
                            renderGroup={(params) => (
                                <li key={params.key}>
                                    <GroupHeader>{params.group === 1 ? "Groups" : "Users"}</GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                </li>
                            )}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="remarks"
                    label="Remark"
                    type="text"
                    multiline
                    rows={4}
                    fullWidth
                    defaultValue={rowData.remarks}
                    error={errors.remarks}
                    helperText={helperText.remarks}
                    onChange={(e) => {
                        setEditData({ ...editData, remarks: e.target.value, });
                        validate("remarks", e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleEditContract} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Update
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
