import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, Button, Grid, Typography, ButtonGroup, CardHeader } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import MaterialTable from "material-table";

import CreateGroupForm from "../components/forms/groups/Create";
import EditGroupForm from "../components/forms/groups/Edit";
import DeleteGroupForm from "../components/forms/groups/Delete";

export const GroupsPage = () => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const nameInputRef = useRef(null);
    const descriptionInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({});
    const [sort, setSort] = useState({});

    const [open, setOpen] = useState({ create: false, edit: false });
    const [selectedRow, setSelectedRow] = useState({});

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({});
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const fetchData = useCallback(async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/groups`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(filter),
                    sort,
                },
            });
            setData(data.data.groups);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    const columns = [
        { title: "Name", field: "group_name", cellStyle: { width: 348, maxWidth: 348 }, headerStyle: { width: 348, maxWidth: 348 } },
        { title: "Description", field: "group_description", width: "80%" },
    ];

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                {identity.user.role_id === 1 ? (
                    <MaterialTable
                        title="Group List"
                        icons={Icons}
                        columns={columns}
                        data={data}
                        page={page}
                        totalCount={count}
                        style={{ padding: "24px" }}
                        options={{
                            pageSize: perPage,
                            pageSizeOptions: [10, 20, 30, 50],
                            search: false,
                            filtering: false,
                            sorting: false,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                fontWeight: "bold",
                                backgroundColor: "#f1f1f1",
                                color: "#212122",
                            },
                            maxBodyHeight: "600px",
                            paginationType: "stepped",
                            padding: "dense",
                            draggable: false,
                        }}
                        localization={{
                            pagination: {
                                labelRowsPerPage: "",
                                labelDisplayedRows: "{from}-{to} of {count}",
                                labelRowsSelect: "rows",
                            },
                            toolbar: {
                                nRowsSelected: "{0} row(s) selected",
                            },
                            header: {
                                actions: "Actions",
                            },
                            body: {
                                emptyDataSourceMessage: "No data to display",
                                filterRow: {
                                    filterTooltip: "Filter",
                                },
                            },
                        }}
                        actions={[
                            {
                                icon: () => <Icons.Edit />,
                                tooltip: "Edit Group Information",
                                onClick: (event, rowData) => handleOpen("edit", rowData),
                            },
                            {
                                icon: () => <Icons.Delete />,
                                tooltip: "Delete Group",
                                onClick: (event, rowData) => DeleteGroupForm(rowData, fetchData),
                            },
                        ]}
                        onChangePage={(page) => setPage(page)}
                        onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                        components={{
                            Toolbar: (props) => (
                                <>
                                    <div>
                                        <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                            <Grid item xs={12} sm={3}>
                                                <CardHeader
                                                    sx={{ p: 0 }}
                                                    title={
                                                        <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                            Group List
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => handleOpen("create", null)}
                                                        variant="outlined"
                                                        startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Create
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <form>
                                        <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                            <Grid item xs={10.5} sm={3}>
                                                <TextField
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="Name"
                                                    name="group_name"
                                                    variant="outlined"
                                                    autoFocus={nameInputRef.current === document.activeElement}
                                                    value={filter.group_name}
                                                    onChange={handleFilterChange}
                                                    inputRef={nameInputRef}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={10.5} sm={5}>
                                                <TextField
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="Description"
                                                    name="group_description"
                                                    variant="outlined"
                                                    autoFocus={descriptionInputRef.current === document.activeElement}
                                                    value={filter.group_description}
                                                    onChange={handleFilterChange}
                                                    inputRef={descriptionInputRef}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={10.5} sm={1}>
                                                <Button
                                                    fullWidth
                                                    variant="contained"
                                                    color="error"
                                                    sx={{
                                                        color: "#ffffff",
                                                        marginTop: "2px",
                                                    }}
                                                    onClick={handleResetFilter}
                                                >
                                                    Reset
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </>
                            ),
                        }}
                    />
                ) : (
                    <Typography variant="subtitle2">You don't have permission to view groups.</Typography>
                )}
                <CreateGroupForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} />
                <EditGroupForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
            </Container>
        </div>
    );
};
