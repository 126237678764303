import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, Button, Grid, Typography, ButtonGroup, CardHeader, Autocomplete, Checkbox } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Countries } from "../utils/Lookup";
import { Icons } from "../utils/Icon";
import { GroupHeader, GroupItems, CustomStyles } from "../utils/Styled";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import CreateOrganizationForm from "../components/forms/organizations/Create";
import EditOrganizationForm from "../components/forms/organizations/Edit";
import ViewOrganizationForm from "../components/forms/organizations/View";
import DeleteOrganizationForm from "../components/forms/organizations/Delete";
import ImportOrganizationForm from "../components/forms/organizations/Import";
import OrganizationContractsForm from "../components/forms/organizations/Contracts";

import CountrySelect from "../components/common/CountrySelect";

export const OrganizationsPage = () => {
    const classes = CustomStyles;

    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const codeInputRef = useRef(null);
    const nameInputRef = useRef(null);
    const emailInputRef = useRef(null);
    const officePhoneInputRef = useRef(null);
    const picNameInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({ country_id: identity.user.country_id });
    const [sort, setSort] = useState({});

    const [userCountry, setUserCountry] = useState([]);

    useEffect(() => {
        async function fetchCountryData() {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/access-country`);
            setUserCountry(response.data.data.countries);
        }
        fetchCountryData();
    }, []);

    const [productsData, setProductsData] = useState([]);

    const [open, setOpen] = useState({
        create: false,
        edit: false,
        view: false,
        import: false,
        contracts: false,
    });
    const [selectedRow, setSelectedRow] = useState({});

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ country_id: identity.user.country_id });
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
        setFilter((prevFilter) => {
            if (prevFilter.country_id !== filter.country_id) {
                return { ...prevFilter, product_id: [] };
            }
            return prevFilter;
        });
    };

    const handleProductFilterChange = (selectedValue) => {
        setPage(0);
        setFilter({ ...filter, product_id: selectedValue });
    };

    const downloadExcelFile = () => {
        const now = moment().format("YYYY-MM-DD_HH-mm-ss");
        const fileName = `Organizations_${now}.xlsx`;

        let customFilter = { ...filter };

        if (customFilter.product_id && customFilter.product_id.length > 0) customFilter.product_id = customFilter.product_id.map((v) => v.product_id);

        axios({
            url: process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/organizations/export",
            method: "GET",
            responseType: "blob", // important
            params: {
                filter: JSON.stringify(customFilter),
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        });
    };

    const fetchData = useCallback(async () => {
        try {
            let customFilter = { ...filter };

            if (customFilter.product_id && customFilter.product_id.length > 0) customFilter.product_id = customFilter.product_id.map((v) => v.product_id);

            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/organizations`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(customFilter),
                    sort,
                },
            });
            setData(data.data.organizations);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/products/select?country=${filter.country_id}`)
            .then((response) => {
                setProductsData(response.data.data.products);
            })
            .catch((error) => console.log(error));
    }, [filter.country_id]);

    const columns = [
        {
            title: "Code",
            field: "org_code",
            cellStyle: { width: 110, maxWidth: 110 },
            headerStyle: { width: 110, maxWidth: 110 },
        },
        { title: "Name", field: "org_name", width: "80%" },
        { title: "Email", field: "email" },
        { title: "Office Phone", field: "office_phone" },
        { title: "Mobile Phone", field: "mobile_phone" },
    ];

    const actions = [];

    actions.push({
        icon: () => <Icons.Summarize />,
        tooltip: "View Organization Contracts",
        onClick: (event, rowData) => handleOpen("contracts", rowData),
    });

    if (identity.user.role_id === 1 || identity.user.role_id === 2) {
        actions.push({
            icon: () => <Icons.Edit />,
            tooltip: "Edit Organization Information",
            onClick: (event, rowData) => handleOpen("edit", rowData),
        });
        actions.push({
            icon: () => <Icons.Delete />,
            tooltip: "Delete Organization",
            onClick: (event, rowData) => DeleteOrganizationForm(rowData, fetchData),
        });
    } else {
        actions.push({
            icon: () => <Icons.Preview />,
            tooltip: "View Organization Information",
            onClick: (event, rowData) => handleOpen("view", rowData),
        });
    }

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <MaterialTable
                    title="Organization List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "24px" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false,
                        rowStyle: (rowData) => ({
                            backgroundColor: selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                        }),
                    }}
                    onRowClick={(evt, selectedRow) => setSelectedRow(selectedRow.tableData.id)}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={3}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        Organization List
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                            {(identity.user.role_id === 1 || identity.user.role_id === 2) && (
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => handleOpen("create", null)}
                                                        variant="outlined"
                                                        startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Create
                                                    </Button>
                                                    {identity.user.role_id === 1 && (
                                                        <Button
                                                            onClick={() => handleOpen("import", null)}
                                                            variant="outlined"
                                                            startIcon={<Icons.Import fontWeight="bold" />}
                                                            color="primary"
                                                            sx={{
                                                                fontWeight: 800,
                                                                paddingTop: 0.5,
                                                                paddingBottom: 0.5,
                                                            }}
                                                        >
                                                            Import
                                                        </Button>
                                                    )}
                                                    <Button
                                                        onClick={() => downloadExcelFile()}
                                                        variant="outlined"
                                                        startIcon={<Icons.FileDownload fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Export
                                                    </Button>
                                                </ButtonGroup>
                                            )}
                                        </Grid>
                                    </Grid>
                                </div>

                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                        <Grid item xs={12} sm={2.6} md={1.1} xl={1.1}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Code"
                                                name="org_code"
                                                variant="outlined"
                                                autoFocus={codeInputRef.current === document.activeElement}
                                                value={filter.org_code}
                                                onChange={handleFilterChange}
                                                inputRef={codeInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3.7} md={2.5} xl={2.4}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Name"
                                                name="org_name"
                                                variant="outlined"
                                                autoFocus={nameInputRef.current === document.activeElement}
                                                value={filter.org_name}
                                                onChange={handleFilterChange}
                                                inputRef={nameInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3.7} md={2.3} xl={2.4}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Email"
                                                name="email"
                                                variant="outlined"
                                                autoFocus={emailInputRef.current === document.activeElement}
                                                value={filter.email}
                                                onChange={handleFilterChange}
                                                inputRef={emailInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2.1} xl={2.1}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Office Phone or Mobile Phone"
                                                name="office_phone"
                                                variant="outlined"
                                                autoFocus={officePhoneInputRef.current === document.activeElement}
                                                value={filter.office_phone}
                                                onChange={handleFilterChange}
                                                inputRef={officePhoneInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={1.5} xl={1.5}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="PIC Name"
                                                name="pic_name"
                                                variant="outlined"
                                                autoFocus={picNameInputRef.current === document.activeElement}
                                                value={filter.pic_name}
                                                onChange={handleFilterChange}
                                                inputRef={picNameInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={3} md={1.5} xl={1.5}>
                                            <CountrySelect 
                                                value={filter.country_id === undefined ? "" : filter.country_id} 
                                                onChange={handleFilterChange} 
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={10} md={11} style={{ paddingTop: 8.5 }}>
                                            <Autocomplete
                                                multiple
                                                size="small"
                                                name="product_id"
                                                options={productsData}
                                                groupBy={(option) => {
                                                    const country = Countries.find((country) => country.id === option.country_id);
                                                    return country ? country.name : "Unknown";
                                                }}
                                                value={filter.product_id}
                                                getOptionLabel={(option) => option.product_description}
                                                onChange={(_, selectedValue) => handleProductFilterChange(selectedValue)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox icon={Icons.CheckBoxBlankIcon} checkedIcon={Icons.CheckBoxCheckedIcon} style={{ marginRight: 8 }} checked={selected} />
                                                        {option.product_description}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        id="product_id"
                                                        label="Product"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                renderGroup={(params) => {
                                                    return (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>{params.children}</GroupItems>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1} md={1}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "2px",
                                                }}
                                                onClick={handleResetFilter}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <CreateOrganizationForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} userCountry={userCountry} />
                <EditOrganizationForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} userCountry={userCountry} />
                <ViewOrganizationForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
                <ImportOrganizationForm open={open.import} handleClose={() => handleClose("import")} fetchData={fetchData} userCountry={userCountry} />
                <OrganizationContractsForm open={open.contracts} handleClose={() => handleClose("contracts")} rowData={selectedRow} />
            </Container>
        </div>
    );
};
