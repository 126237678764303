import React, { useEffect, useState, useCallback } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Grid, MenuItem, Divider, Chip, Autocomplete } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GroupHeader, GroupItems } from "../../../utils/Styled";
import { Countries, ContractStatus, IsFreeOfCharge } from "../../../utils/Lookup";
import { debounce } from 'lodash';

import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

import EditContractForm from "./Edit";

export default function CreateContractForm({ open, handleClose, rowData, fetchData, handleReOpen }) {
    const [newData, setNewData] = useState({ start_date: moment().toDate(), end_date: moment().add(1, "year").toDate(), status: 0 });

    const [selectedRow, setSelectedRow] = useState(null);

    const [editOpen, setEditOpen] = useState(false);

    const handleEditOpen = (row) => {
        setEditOpen(true);
        setSelectedRow(row);
    };

    const handleEditClose = (type) => {
        setEditOpen(false);
        setSelectedRow(null);
    };

    const [errors, setErrors] = useState({ doc_no_id: false, contract_subject: false, product_id: false, org_id: false, start_date: false, end_date: false, assigned_to: false, status: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [organizationsData, setOrganizationsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [contractSubjectData, setContractSubjectData] = useState([]);
    const [docNoSettingData, setDocNoSettingData] = useState([]);

    const [lastEndDate, setLastEndDate] = useState("");
    const [userContractSubjectInput, setUserContractSubjectInput] = useState("");

    const [selectedOrganizationValue, setSelectedOrganizationValue] = useState(null);
    const [selectedProductValue, setSelectedProductValue] = useState(null);

    const debouncedOnChange = debounce((inputValue, callback) => {
        if (inputValue.trim() !== "") {
            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/valid-contract-subject?text=${inputValue}`)
                .then((response) => {
                    if (response.data.data.valid) {
                        setErrors((prevErrors) => ({ ...prevErrors, contract_subject: true }));
                        setHelperText((prevHelperText) => ({ ...prevHelperText, contract_subject: "Contract No already exists" }));
                     } else {
                        setErrors((prevErrors) => ({ ...prevErrors, contract_subject: false }));
                        setHelperText((prevHelperText) => ({ ...prevHelperText, contract_subject: "" }));
                     }
                    return response.data.data.valid;
                })
                .catch((error) => {
                    console.error(error);
                    return false;
                });
        } else {
            setErrors((prevErrors) => ({ ...prevErrors, contract_subject: true, }));
            setHelperText((prevHelperText) => ({ ...prevHelperText, contract_subject: "Contract No is required.", }));
            return false;
        }
      }, 500);

    function handleInputChange(event, newInputValue, callback) {
        setUserContractSubjectInput(newInputValue.toUpperCase());
        setNewData({ ...newData, contract_subject: newInputValue ? newInputValue.toUpperCase() : '' });
        debouncedOnChange(newInputValue, callback);
    }

    const fetchContractSubjectData = useCallback(async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/get-contract-subject?search=${userContractSubjectInput}`);
            setContractSubjectData(response.data.data.texts);
        } catch (error) {
            console.error(error);
        }
    }, [userContractSubjectInput]);
    
    useEffect(() => {
        fetchContractSubjectData();
    }, [fetchContractSubjectData]);

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const validate = async (field, value) => {
        if (field === "doc_no_id" && value === "") {
            await setError("doc_no_id", "Contract No Prefix is required.");
        } else if (field === "contract_subject" && value === "") {
            await setError("contract_subject", "Contract No is required.");
        } else if (field === "product_id" && value === "") {
            await setError("product_id", "Product is required.");
        } else if (field === "org_id" && value === "") {
            await setError("org_id", "Organization is required.");
        } else if (field === "start_date" && (value === "" || value === null)) {
            await setError("start_date", "Start Date is required.");
        } else if (field === "start_date" && value !== null && newData["end_date"] <= value) {
            await setError("start_date", "Start Date cannot greater or equal to End Date.");
        } else if (field === "end_date" && (value === "" || value === null)) {
            await setError("end_date", "End Date is required.");
        } else if (field === "end_date" && value !== null && value <= newData["start_date"]) {
            await setError("end_date", "End Date cannot less than or equal to Start Date.");
        } else if (field === "assigned_to" && value === "") {
            await setError("assigned_to", "Assigned To is required.");
        } else if (field === "status" && value === "") {
            await setError("status", "Status is required.");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (open) {
            setSelectedOrganizationValue(null);
            setSelectedProductValue(null);
            setUserContractSubjectInput("");
            setNewData({ doc_no_id: "", doc_no_prefix: "", contract_subject: "", invoice_no: "", product_id: rowData && rowData.product_id ? rowData.product_id : "", org_id: rowData ? rowData.org_id : "", start_date: moment().toDate(), end_date: moment().add(1, "year").toDate(), is_foc: 0, is_assigned_to_group: 0, assigned_to: "", remarks: "", ref_org: "", status: 0 });
            setErrors({ doc_no_id: false, contract_subject: false, product_id: false, org_id: false, start_date: false, end_date: false, assigned_to: false, status: false });
            setHelperText({});
            setErrorMessage("");

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/organizations/select")
                .then((response) => {
                    setOrganizationsData(response.data.data.organizations);
                    if (rowData) {
                        setSelectedOrganizationValue(response.data.data.organizations.find((option) => option.org_id === rowData.org_id));
                    }
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/select")
                .then((response) => {
                    setProductsData(response.data.data.products);
                    if (rowData && rowData.product_id) {
                        setSelectedProductValue(response.data.data.products.find((option) => option.product_id === rowData.product_id));
                    }
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/groups/select")
                .then((response) => setUsersData(response.data.data.users))
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/doc-no-settings/select?type=0")
                .then((response) => {
                    setDocNoSettingData(response.data.data.doc_no_settings);
                })
                .catch((error) => console.log(error));
        }
    }, [open, rowData]);

    const handleCreateContract = async (showDetail) => {
        let isValid = true;

        if (newData["doc_no_id"] === "") isValid = false;
        else if (newData["doc_no_id"] !== "" && newData["doc_no_prefix"] === "" && newData["contract_subject"] === "") isValid = false;
        else if (newData["product_id"] === "") isValid = false;
        else if (newData["org_id"] === "") isValid = false;
        else if (newData["start_date"] === "" || newData["start_date"] === null) isValid = false;
        else if (newData["end_date"] === "" || newData["end_date"] === null) isValid = false;
        else if (newData["assigned_to"] === "") isValid = false;
        else if (newData["status"] === "") isValid = false;
        else if (newData["end_date"] <= newData["start_date"]) isValid = false;

        Object.keys(newData).forEach(async (field) => {
            await validate(field, newData[field]);
        });

        if (isValid) {
            await setErrorMessage("");
            await createContract(showDetail);
        } else {
            await setErrorMessage(`Please fill all the required fields.`);
        }
    };

    const createContract = async (showDetail) => {
        try {
            const { doc_no_id, contract_subject, invoice_no, product_id, org_id, is_foc, is_assigned_to_group, assigned_to, remarks, ref_org, status } = newData;
            const start_date = moment(newData.start_date).format("YYYY-MM-DD");
            const end_date = moment(newData.end_date).format("YYYY-MM-DD");

            const response = await axios.post(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/contracts/create", {
                doc_no_id,
                contract_subject,
                invoice_no,
                product_id,
                org_id,
                start_date,
                end_date,
                is_foc,
                is_assigned_to_group,
                assigned_to,
                remarks,
                ref_org,
                status,
            });

            if (response.status === 201) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Contract created successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
                if (showDetail) {
                    setTimeout(() => {
                        handleEditOpen(response.data.data);
                    }, 1800);
                }
            }
        } catch (error) {
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while creating the contract. Please try again later.");
            }
        }
    };

    const checkLastContractEndDate = async (type, value) => {
        let { product_id, org_id } = newData;

        if (type === "product_id") {
            product_id = value;
        } else if (type === "org_id") {
            org_id = value;
        }

        if (product_id > 0 && org_id > 0) {
            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/last-contact-end-date?org_id=${org_id}&product_id=${product_id}`)
                .then((response) => {
                    const lastEndDate = response.data.data.lastEndDate;
                    setLastEndDate(lastEndDate !== "" ? moment(lastEndDate).format("YYYY-MM-DD") : "");
                    if (lastEndDate !== "") {
                        if (type === "product_id") {
                            setNewData({ ...newData, product_id: value, start_date: moment(lastEndDate).toDate(), end_date: moment(lastEndDate).add(1, "year").toDate(), });
                        } else if (type === "org_id") {
                            setNewData({ ...newData, org_id: value, start_date: moment(lastEndDate).toDate(), end_date: moment(lastEndDate).add(1, "year").toDate(), });
                        }
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            setLastEndDate("");
        }
    };

    return (
        <>
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Create New Contract</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Contract Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Autocomplete
                            options={docNoSettingData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.prefix === "" ? option.description : option.prefix + ' - ' + option.description}
                            onChange={(event, value) => {
                                setNewData({ ...newData, doc_no_id: value ? value.doc_no_setting_id : "", doc_no_prefix: value ? value.prefix : ""});
                                validate("doc_no_id", value ? value.doc_no_setting_id : "");
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="doc_no_id" label="Contract No Prefix" fullWidth required error={errors.doc_no_id} helperText={helperText.doc_no_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                    { newData.doc_no_id !== "" && newData.doc_no_prefix === "" &&
                        <Grid item xs={4}>
                            <Autocomplete
                                freeSolo
                                disableClearable
                                options={contractSubjectData}
                                getOptionLabel={(option) => option.contract_subject || ''}
                                inputValue={userContractSubjectInput}
                                onInputChange={(event, newInputValue) => {
                                    handleInputChange(event, newInputValue.toUpperCase(), (isValid) => {
                                        validate('contract_subject', newInputValue.toUpperCase());
                                    });
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        autoFocus
                                        margin="dense"
                                        label="Contract No"
                                        required
                                        fullWidth
                                        error={errors.contract_subject}
                                        helperText={helperText.contract_subject}
                                        InputProps={{
                                            ...params.InputProps, type: 'search',
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    }
                    <Grid item xs={4}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="invoice_no"
                            label="Invoice No"
                            type="text"
                            fullWidth
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                                setNewData({ ...newData, invoice_no: e.target.value ? e.target.value.toUpperCase() : "", });
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={organizationsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.org_name}
                            onChange={(event, value) => {
                                setSelectedOrganizationValue(value);
                                setNewData({ ...newData, org_id: value ? value.org_id : "", });
                                validate("org_id", value ? value.org_id : "");
                                checkLastContractEndDate("org_id", value ? value.org_id : "");
                            }}
                            isOptionEqualToValue={(option, value) => option.org_id === value.org_id}
                            value={selectedOrganizationValue}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.org_id}>
                                        {option.org_name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="org_id" label="Organization" fullWidth required error={errors.org_id} helperText={helperText.org_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={9}>
                        <Autocomplete
                            options={productsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.product_description}
                            onChange={(event, value) => {
                                setSelectedProductValue(value);
                                setNewData({ ...newData, product_id: value ? value.product_id : "", });
                                validate("product_id", value ? value.product_id : "");
                                checkLastContractEndDate("product_id", value ? value.product_id : "");
                            }}
                            isOptionEqualToValue={(option, value) => option.product_id === value.product_id}
                            value={selectedProductValue}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.product_id}>
                                        {option.product_description}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="product_id" label="Product" fullWidth required error={errors.product_id} helperText={helperText.product_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField autoComplete="off" margin="dense" label="Last End Date" value={lastEndDate} type="text" fullWidth InputLabelProps={{ shrink: true, readOnly: true }} style={{ backgroundColor: "#f1f0f0" }} />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Start Date"
                                inputFormat="YYYY-MM-DD"
                                value={newData.start_date}
                                onChange={(newValue) => {
                                    setNewData({ ...newData, start_date: newValue, });
                                    validate("start_date", newValue);
                                }}
                                renderInput={(params) => <TextField {...params} required fullWidth margin="dense" error={errors.start_date} helperText={helperText.start_date} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="End Date"
                                inputFormat="YYYY-MM-DD"
                                value={newData.end_date}
                                onChange={(newValue) => {
                                    setNewData({ ...newData, end_date: newValue, });
                                    validate("end_date", newValue);
                                }}
                                renderInput={(params) => <TextField {...params} required fullWidth margin="dense" error={errors.end_date} helperText={helperText.end_date} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            label="Status"
                            type="text"
                            defaultValue="0"
                            select
                            fullWidth
                            required
                            error={errors.status}
                            helperText={helperText.status}
                            onChange={(e) => {
                                setNewData({ ...newData, status: e.target.value, });
                                validate("status", e.target.value);
                            }}
                        >
                            {ContractStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid container item xs={3} alignItems="center" justifyContent="center">
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="is_foc"
                            label="Is Free Of Charge"
                            type="text"
                            defaultValue="0"
                            select
                            fullWidth
                            required
                            error={errors.is_foc}
                            helperText={helperText.is_foc}
                            onChange={(e) => {
                                setNewData({ ...newData, is_foc: e.target.value, });
                                validate("is_foc", e.target.value);
                            }}
                        >
                            {IsFreeOfCharge.map((element) => (
                                <MenuItem key={element.id} value={element.id}>
                                    {element.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_org"
                            label="Old Organization (Import)"
                            type="text"
                            fullWidth
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                                setNewData({ ...newData, ref_org: e.target.value ? e.target.value.toUpperCase() : "", });
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Autocomplete
                            options={usersData}
                            groupBy={(option) => option.is_group}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                                setNewData({ ...newData, assigned_to: value ? value.id : "", is_assigned_to_group: value ? value.is_group : 0, });
                                validate("assigned_to", value ? value.id : "");
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="assigned_to" label="Assigned To" fullWidth required error={errors.assigned_to} helperText={helperText.assigned_to} />}
                            renderGroup={(params) => (
                                <li key={params.key}>
                                    <GroupHeader>{params.group === 1 ? "Groups" : "Users"}</GroupHeader>
                                    <GroupItems>{params.children}</GroupItems>
                                </li>
                            )}
                        />
                    </Grid>
                </Grid>
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <TextField
                    autoComplete="off"
                    margin="dense"
                    id="remarks"
                    label="Remark"
                    type="text"
                    multiline
                    rows={4}
                    fullWidth
                    error={errors.remarks}
                    helperText={helperText.remarks}
                    onChange={(e) => {
                        setNewData({ ...newData, remarks: e.target.value, });
                        validate("remarks", e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={() => handleCreateContract(false)} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Create
                </Button>
                <Button onClick={() => handleCreateContract(true)} variant="contained" color="blue" style={{ marginRight: "6px", color: "white" }}>
                    Create & Show Detail
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        <EditContractForm open={editOpen} handleClose={() => handleEditClose()} rowData={selectedRow} fetchData={fetchData} />
        </>
    );
}
