import axios from "axios";
import Swal from "sweetalert2";

export default async function DeleteProductForm(rowData, fetchData) {
    const confirmDelete = await Swal.fire({
        title: "Are you sure?",
        html: `Are you sure you want to delete product <b>${rowData.product_name}</b>?`,
        icon: "warning",
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonColor: "#fe7320",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
    });

    if (confirmDelete.value) {
        try {
            const { data } = await axios.delete(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/products/${rowData.product_id}`);

            Swal.fire({
                title: "Deleted!",
                text: data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
            });

            fetchData();
        } catch (error) {
            Swal.fire({
                title: "Error!",
                text: error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined ? error.response.data.message : error.message,
                icon: "error",
                confirmButtonColor: "#fe7320",
            });
        }
    }
}
