import { useEffect, useState, useRef, useCallback } from "react";
import { Container, TextField, MenuItem, Button, Grid, Typography, ButtonGroup, Dialog, CardHeader, Autocomplete, IconButton, Tooltip, Chip, Checkbox, Card, CardContent, CardActions } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Countries, ServiceStatus } from "../utils/Lookup";
import { GroupHeader, GroupItems } from "../utils/Styled";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import moment from "moment";
import MaterialTable from "material-table";

import DateRangePicker from "../components/common/DateRangePicker";
import CreateServiceForm from "../components/forms/services/Create";
import EditServiceForm from "../components/forms/services/Edit";
import ViewServiceForm from "../components/forms/services/View";
import DeleteServiceForm from "../components/forms/services/Delete";
import ImportServiceForm from "../components/forms/services/Import";
import ViewContractForm from "../components/forms/contracts/View";
import RelationshipServicesForm from "../components/forms/services/Services";

import CountrySelect from "../components/common/CountrySelect";

export const ServicesPage = () => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const organizationInputRef = useRef(null);
    const serviceNoInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({
        service_no: "", org_name: "", start_date: null, end_date: null, status: 0, user_id: "", is_request_close: "", country_id: identity.user.country_id, product_id: []
    });
    const [sort, setSort] = useState({});

    const [userCountry, setUserCountry] = useState([]);

    useEffect(() => {
        async function fetchCountryData() {
            const response = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users/access-country`);
            setUserCountry(response.data.data.countries);
        }
        fetchCountryData();
    }, []);

    const [open, setOpen] = useState({
        create: false,
        edit: false,
        view: false,
        import: false,
        viewContract: false,
        viewService: false
    });
    const [selectedRow, setSelectedRow] = useState({});

    const [pendingCount, setPendingCount] = useState(0);
    const [pendingCompleteCount, setpendingCompleteCount] = useState(0);

    const [usersData, setUsersData] = useState([]);
    const [productsData, setProductsData] = useState([]);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const formatDate = (date) => moment(date).format("YYYY-MM-DD");

    const handleDialogClose = () => {
        setIsDialogOpen(false);
    };

    const handleOpen = (type, row) => {
        if (type === "viewContract") {
            axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/contracts/${row.contract_id}`)
            .then((response) => {
                setSelectedRow(response.data.data);
                setOpen({ ...open, [type]: true });
            })
            .catch((error) => console.log(error));
        } else {
            setOpen({ ...open, [type]: true });
            setSelectedRow(row);
        }
    };

    const handleClose = (type) => {
        if (type === "viewService") {
            fetchData();
        }
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ service_no: "", org_name: "", start_date: null, end_date: null, status: 0, user_id: "", is_request_close: "", country_id: identity.user.country_id, product_id: [] });
        setStartDate(null);
        setEndDate(null);
    };

    const handlePendingFilter = () => {
        setPage(0);
        setFilter({ service_no: "", org_name: "", start_date: null, end_date: null, status: 0, user_id: identity.user.user_id, is_request_close: "", country_id: "", product_id: [] });
        setStartDate(null);
        setEndDate(null);
    };

    const handlePendingCompleteFilter = () => {
        setPage(0);
        setFilter({ service_no: "", org_name: "", start_date: null, end_date: null, status: 0, user_id: identity.user.user_id, is_request_close: 1, country_id: "", product_id: [] });
        setStartDate(null);
        setEndDate(null);
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
        setFilter((prevFilter) => {
            if (prevFilter.country_id !== filter.country_id) {
                return { ...prevFilter, product_id: [] };
            }
            return prevFilter;
        });
    };

    const handleProductFilterChange = (selectedValue) => {
        setPage(0);
        setFilter({ ...filter, product_id: selectedValue });
    };

    const handleUserFilterChange = (selectedValue) => {
        setPage(0);
        setFilter({ ...filter, user_id: selectedValue ? selectedValue.user_id : "" });
    };

    const downloadExcelFile = () => {
        const now = moment().format("YYYY-MM-DD_HH-mm-ss");
        const fileName = `Service_${now}.xlsx`;

        let customFilter = { ...filter };
        customFilter.start_date = moment(customFilter.start_date).isValid() ? moment(customFilter.start_date).format("YYYY-MM-DD") : "";
        customFilter.end_date = moment(customFilter.end_date).isValid() ? moment(customFilter.end_date).format("YYYY-MM-DD") : "";
        customFilter.user_id = customFilter.user_id ? customFilter.user_id : "";

        if (customFilter.product_id && customFilter.product_id.length > 0) customFilter.product_id = customFilter.product_id.map((v) => v.product_id);

        axios({
            url: process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/services/export",
            method: "GET",
            responseType: "blob", // important
            params: {
                filter: JSON.stringify(customFilter),
            },
        }).then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        });
    };

    const fetchData = useCallback(async () => {
        try {
            let customFilter = { ...filter };
            customFilter.start_date = moment(customFilter.start_date).isValid() ? moment(customFilter.start_date).format("YYYY-MM-DD") : "";
            customFilter.end_date = moment(customFilter.end_date).isValid() ? moment(customFilter.end_date).format("YYYY-MM-DD") : "";
            customFilter.user_id = customFilter.user_id ? customFilter.user_id : "";

            if (customFilter.product_id && customFilter.product_id.length > 0) customFilter.product_id = customFilter.product_id.map((v) => v.product_id);

            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/services`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(customFilter),
                    sort,
                },
            });

            const pendingResponse = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/services/get-service-pending-count`);
            setPendingCount(pendingResponse ? pendingResponse.data.data.pendingCount.total : 0);
            setpendingCompleteCount(pendingResponse ? pendingResponse.data.data.pendingCompleteCount.total : 0);

            setData(data.data.services);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    useEffect(() => {
        setPage(0);
        setFilter(prevFilter => ({ ...prevFilter, start_date: startDate, end_date: endDate }));
    }, [startDate, endDate]);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/select/access-country")
            .then((response) => {
                setUsersData(response.data.data.users);
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        axios
            .get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/products/select?country=${filter.country_id}`)
            .then((response) => {
                setProductsData(response.data.data.products);
            })
            .catch((error) => console.log(error));
    }, [filter.country_id]);

    const columns = [
        {
            title: "Service Date",
            field: "service_date",
            cellStyle: { width: 130, maxWidth: 130 },
            headerStyle: { width: 130, maxWidth: 130 },
            render: (rowData) => moment(rowData.service_date).format("YYYY-MM-DD"),
        },
        {
            title: "Service No",
            field: "service_no",
            cellStyle: { width: 120, maxWidth: 120 },
            headerStyle: { width: 120, maxWidth: 120 }
        },
        {
            title: "Organization",
            field: "org_name",
            cellStyle: { width: 720, maxWidth: 720 },
            headerStyle: { width: 720, maxWidth: 720 },
            render: (rowData) => 
            <>
                {rowData.org_id === 1 || rowData.org_id === 2 ? `${rowData.org_name} (${rowData.ref_org})` : rowData.org_name}
                <br/>
                {rowData.details && rowData.details.map(detail => (
                    <Chip key={detail.product_id} label={detail.product_description} size="small" variant="outlined" sx={{ mx: 0.3, my: 0.1 }}/>
                ))}
            </>
        },
        // {
        //     title: "Status",
        //     field: "status",
        //     cellStyle: { width: 95, maxWidth: 95 },
        //     headerStyle: { width: 95, maxWidth: 95 },
        //     render: (rowData) => ServiceStatus.find((x) => x.id === rowData.status).name,
        // },
        {   title: "Support By", field: "support_name", width: "80%" },
        {   
            title: "Status", field: "status", 
            align: "center",
            cellStyle: { width: 55, maxWidth: 55 }, headerStyle: { width: 55, maxWidth: 55 },
            render: (rowData) => 
            <>
                { rowData.status === 0 ?
                    <Tooltip title={rowData.is_request_close === 1 ? "Pending & Requested Complete" : "Pending"}>
                        <IconButton color="primary" aria-label="completed" component="label" style={{ padding: 0, marginRight: 2 }} >
                            { rowData.is_request_close === 1 ? <Icons.PendingTwoToneIcon/> : <Icons.AccessTimeTwoToneIcon />}
                        </IconButton>
                    </Tooltip> 
                    :
                    <Tooltip title="Completed">
                        <IconButton color="primary" aria-label="completed" component="label" style={{ padding: 0, marginRight: 2 }} >
                            <Icons.VerifiedTwoToneIcon />
                        </IconButton>
                    </Tooltip> 
                }
            </>
        },
        {   
            title: "Views", field: "status", 
            align: "center",
            cellStyle: { width: 55, maxWidth: 55 }, headerStyle: { width: 55, maxWidth: 55 },
            render: (rowData) => 
            <>
                {rowData.contract_id > 0 && 
                    <Tooltip title="View Contract">
                        <IconButton color="primary" aria-label="view contract detail" component="label" style={{ padding: 0 }} onClick={() => handleOpen("viewContract", rowData)}>
                            <Icons.Summarize />
                        </IconButton>
                    </Tooltip> 
                }
            </>
        },
        {   
            title: "Re.", field: "status", 
            align: "center",
            cellStyle: { width: 55, maxWidth: 55 }, headerStyle: { width: 55, maxWidth: 55 },
            render: (rowData) => 
            <>
                <Tooltip title={rowData.status === 0 ? "Create Continue Service" : "View Continue Service"}>
                    <IconButton color="primary" aria-label="create continur service" component="label" style={{ padding: 0, marginRight: 8 }} onClick={() => handleOpen("viewService", rowData)}>
                        <Icons.PhoneForwardedTwoToneIcon />
                    </IconButton>
                </Tooltip> 
            </>
        },
        {   
            title: "Actions", field: "status", 
            align: "center",
            cellStyle: { width: 100, maxWidth: 100 }, headerStyle: { width: 100, maxWidth: 100 },
            render: (rowData) => 
            <>
                {((rowData.status === 0 && rowData.support_by !== identity.user.user_id) || (rowData.status === 1)) && 
                    <Tooltip title="View Service Information">
                        <IconButton color="primary" aria-label="view service information" component="label" style={{ padding: 0 }} onClick={() => handleOpen("view", rowData)}>
                            <Icons.Preview />
                        </IconButton>
                    </Tooltip> 
                }
                {((rowData.status === 0 && rowData.support_by === identity.user.user_id) || identity.user.role_id === 1) && 
                    <Tooltip title="Edit Service Information">
                        <IconButton color="primary" aria-label="edit service information" component="label" style={{ padding: 0, marginLeft: 8 }} onClick={() => handleOpen("edit", rowData)}>
                            <Icons.Edit />
                        </IconButton>
                    </Tooltip> 
                }
                {rowData.status === 0 && rowData.support_by === identity.user.user_id && 
                    <Tooltip title="Delete Service">
                        <IconButton color="primary" aria-label="delete service" component="label" style={{ padding: 0, marginLeft: 8 }} onClick={() => DeleteServiceForm(rowData, fetchData)}>
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip> 
                }
            </>
        },
    ];

    const actions = [];

    // actions.push({
    //     icon: () => <Icons.Edit />,
    //     tooltip: "Edit Service Information",
    //     onClick: (event, rowData) => handleOpen("edit", rowData),
    // });
    // actions.push({
    //     icon: () => <Icons.Delete />,
    //     tooltip: "Delete Service",
    //     onClick: (event, rowData) => DeleteServiceForm(rowData, fetchData),
    // });

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275, mb: 2 }}>
                            <CardContent sx={{ pt: 1, pb: 0 }}>
                                <Typography variant="h6" component="div">
                                    Your Pending Service 
                                </Typography>
                                <Typography variant="h5" component="div" sx={{ mt: 1 }}>
                                    {pendingCount}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={() => handlePendingFilter()}>Filter Pending Service</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Card sx={{ minWidth: 275, mb: 2 }}>
                            <CardContent sx={{ pt: 1, pb: 0 }}>
                                <Typography variant="h6" component="div">
                                    Your Pending Complete Service 
                                </Typography>
                                <Typography variant="h5" component="div" sx={{ mt: 1 }}>
                                    {pendingCompleteCount}
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" onClick={() => handlePendingCompleteFilter()}>Filter Pending Complete Service</Button>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                <MaterialTable
                    title="Services List"
                    icons={Icons}
                    columns={columns}
                    data={data}
                    page={page}
                    totalCount={count}
                    style={{ padding: "24px" }}
                    options={{
                        pageSize: perPage,
                        pageSizeOptions: [10, 20, 30, 50],
                        search: false,
                        filtering: false,
                        sorting: false,
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontWeight: "bold",
                            backgroundColor: "#f1f1f1",
                            color: "#212122",
                        },
                        maxBodyHeight: "600px",
                        paginationType: "stepped",
                        padding: "dense",
                        draggable: false
                    }}
                    localization={{
                        pagination: {
                            labelRowsPerPage: "",
                            labelDisplayedRows: "{from}-{to} of {count}",
                            labelRowsSelect: "rows",
                        },
                        toolbar: {
                            nRowsSelected: "{0} row(s) selected",
                        },
                        header: {
                            actions: "Actions",
                        },
                        body: {
                            emptyDataSourceMessage: "No data to display",
                            filterRow: {
                                filterTooltip: "Filter",
                            },
                        },
                    }}
                    actions={actions}
                    onChangePage={(page) => setPage(page)}
                    onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                    components={{
                        Toolbar: (props) => (
                            <>
                                <div>
                                    <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                        <Grid item xs={12} sm={3}>
                                            <CardHeader
                                                sx={{ p: 0 }}
                                                title={
                                                    <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                        Services List
                                                    </Typography>
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                            <ButtonGroup>
                                                <Button
                                                    onClick={() => handleOpen("create", null)}
                                                    variant="outlined"
                                                    startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                    color="primary"
                                                    sx={{
                                                        fontWeight: 800,
                                                        paddingTop: 0.5,
                                                        paddingBottom: 0.5,
                                                    }}
                                                >
                                                    Create
                                                </Button>
                                                {identity.user.role_id === 1 && (
                                                    <>
                                                        <Button
                                                            onClick={() => handleOpen("import", null)}
                                                            variant="outlined"
                                                            startIcon={<Icons.Import fontWeight="bold" />}
                                                            color="primary"
                                                            sx={{
                                                                fontWeight: 800,
                                                                paddingTop: 0.5,
                                                                paddingBottom: 0.5,
                                                            }}
                                                        >
                                                            Import
                                                        </Button>
                                                        <Button
                                                            onClick={() => downloadExcelFile()}
                                                            variant="outlined"
                                                            startIcon={<Icons.FileDownload fontWeight="bold" />}
                                                            color="primary"
                                                            sx={{
                                                                fontWeight: 800,
                                                                paddingTop: 0.5,
                                                                paddingBottom: 0.5,
                                                            }}
                                                        >
                                                            Export
                                                        </Button>
                                                    </>
                                                )}
                                            </ButtonGroup>
                                        </Grid>
                                    </Grid>
                                </div>

                                <form>
                                    <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                        <Grid item xs={12} sm={2} md={1.2} xl={1.2}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Service No"
                                                name="service_no"
                                                variant="outlined"
                                                autoFocus={serviceNoInputRef.current === document.activeElement}
                                                value={filter.service_no}
                                                onChange={handleFilterChange}
                                                inputRef={serviceNoInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" },
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6} sm={2} md={1.3} xl={1.3}>
                                            <TextField
                                                label="Service Date (Start)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={startDate ? formatDate(startDate) : ""}
                                                // onChange={(event) => setStartDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                            <Dialog open={isDialogOpen} onClose={handleDialogClose}>
                                                <DateRangePicker onClose={handleDialogClose} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
                                            </Dialog>
                                        </Grid>
                                        <Grid item xs={6} sm={2} md={1.3} xl={1.3}>
                                            <TextField
                                                label="Service Date (End)"
                                                fullWidth
                                                type="text"
                                                size="small"
                                                value={endDate ? formatDate(endDate) : ""}
                                                // onChange={(event) => setEndDate(event.target.value)}
                                                onClick={() => setIsDialogOpen(true)}
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={2.0} xl={2.0}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Organization"
                                                name="org_name"
                                                variant="outlined"
                                                autoFocus={organizationInputRef.current === document.activeElement}
                                                value={filter.org_name}
                                                onChange={handleFilterChange}
                                                inputRef={organizationInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    style: { textTransform: "uppercase" },
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} sm={4} md={2.5} xl={2.5}>
                                            <TextField
                                                autoComplete="off"
                                                fullWidth
                                                label="Product"
                                                name="product_description"
                                                variant="outlined"
                                                autoFocus={ProductInputRef.current === document.activeElement}
                                                value={filter.product_description}
                                                onChange={handleFilterChange}
                                                inputRef={ProductInputRef}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>*/}
                                        {(<Grid item xs={12} sm={4} md={1.9} xl={1.9} style={{ marginTop: "-8px"}}>
                                            <Autocomplete
                                                freeSolo
                                                size="small"
                                                name="user_id"
                                                options={usersData}
                                                groupBy={(option) => {
                                                    const country = Countries.find((country) => country.id === option.country_id);
                                                    return country ? country.name : "Unknown";
                                                }}
                                                value={usersData.filter((user) => user.user_id === filter.user_id)[0] || ""}
                                                getOptionLabel={(option) => option.name || ""}
                                                onChange={(_, selectedValue) => handleUserFilterChange(selectedValue)}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        id="user_id"
                                                        label="Support By"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                renderGroup={(params) => {
                                                    return (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>{params.children}</GroupItems>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Grid>)}
                                        <Grid item xs={6} sm={3} md={1.4} xl={1.4}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Status"
                                                variant="outlined"
                                                name="status"
                                                value={filter.status}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                {ServiceStatus.map((status) => (
                                                    <MenuItem key={status.id} value={status.id}>
                                                        {status.name}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>        
                                        <Grid item xs={6} sm={3} md={1.4} xl={1.4}>
                                            <TextField
                                                fullWidth
                                                select
                                                label="Is Request Complete"
                                                variant="outlined"
                                                name="is_request_close"
                                                value={filter.is_request_close}
                                                onChange={handleFilterChange}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            >
                                                <MenuItem value={""}>All</MenuItem>
                                                <MenuItem value={0}>No</MenuItem>
                                                <MenuItem value={1}>Yes</MenuItem>
                                            </TextField>
                                        </Grid>                  
                                        <Grid item xs={6} sm={3.8} md={1.5} xl={1.5}>
                                            <CountrySelect 
                                                value={filter.country_id === undefined ? "" : filter.country_id} 
                                                onChange={handleFilterChange} 
                                            />
                                        </Grid>                             
                                        <Grid item xs={12} sm={10} md={11} style={{ paddingTop: 8.5 }}>
                                            <Autocomplete
                                                multiple
                                                size="small"
                                                name="product_id"
                                                options={productsData}
                                                groupBy={(option) => {
                                                    const country = Countries.find((country) => country.id === option.country_id);
                                                    return country ? country.name : "Unknown";
                                                }}
                                                value={filter.product_id}
                                                getOptionLabel={(option) => option.product_description}
                                                onChange={(_, selectedValue) => handleProductFilterChange(selectedValue)}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}>
                                                        <Checkbox icon={Icons.CheckBoxBlankIcon} checkedIcon={Icons.CheckBoxCheckedIcon} style={{ marginRight: 8 }} checked={selected} />
                                                        {option.product_description}
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        margin="dense"
                                                        id="product_id"
                                                        label="Product"
                                                        fullWidth
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                )}
                                                renderGroup={(params) => {
                                                    return (
                                                        <li key={params.key}>
                                                            <GroupHeader>{params.group}</GroupHeader>
                                                            <GroupItems>{params.children}</GroupItems>
                                                        </li>
                                                    );
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={1}>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="error"
                                                sx={{
                                                    color: "#ffffff",
                                                    marginTop: "2px",
                                                }}
                                                onClick={handleResetFilter}
                                            >
                                                Reset
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            </>
                        ),
                    }}
                />
                <CreateServiceForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} />
                <EditServiceForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
                <ViewServiceForm open={open.view} handleClose={() => handleClose("view")} rowData={selectedRow} />
                <ImportServiceForm open={open.import} handleClose={() => handleClose("import")} fetchData={fetchData} userCountry={userCountry} />
                <ViewContractForm open={open.viewContract} handleClose={() => handleClose("viewContract")} rowData={selectedRow} />
                <RelationshipServicesForm open={open.viewService} handleClose={() => handleClose("viewService")} rowData={selectedRow} />
            </Container>
        </div>
    );
};
