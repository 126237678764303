import React, { useEffect, useState } from "react";
import { TextField, Dialog, DialogActions, DialogContent, DialogTitle, Button, Alert, Grid, MenuItem, Divider, Chip, Autocomplete, FormControlLabel, Checkbox, Accordion, AccordionSummary, Typography, AccordionDetails } from "@mui/material";
import { useAuth } from "../../../hooks/useAuth";
import { GetTokenIdentity } from "../../../utils/Token";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { GroupHeader, GroupItems } from "../../../utils/Styled";
import { Countries, ServiceStatus } from "../../../utils/Lookup";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

export default function EditServiceForm({ open, handleClose, rowData, fetchData }) {
    const { token } = useAuth();
    const identity = GetTokenIdentity(token);

    const [editData, setEditData] = useState({});

    const [errors, setErrors] = useState({ doc_no_id: false, service_date: false, service_no: false, org_id: false, status: false });
    const [helperText, setHelperText] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    const [organizationsData, setOrganizationsData] = useState([]);
    const [productsData, setProductsData] = useState([]);
    const [systemPluginsData, setSystemPluginsData] = useState([]);
    const [contractsData, setContractsData] = useState([]);
    const [users, setUsers] = useState([]);

    const [selectedUserValues, setSelectedUserValues] = useState([]);
    const [selectedOrganizationValue, setSelectedOrganizationValue] = useState(null);
    const [selectedContractValue, setSelectedContractValue] = useState(null);

    const setError = async (field, message) => {
        await setErrors(prevErrors => ({ ...prevErrors, [field]: true }));
        await setHelperText(prevHelperText => ({ ...prevHelperText, [field]: message }));
    };

    const handleDetailChange = (index, field, value) => {
        const newDetails = [...editData.details];
        newDetails[index][field] = value;
        setEditData({ ...editData, details: newDetails });
    };
    
    const handleAddDetail = () => {
        setEditData({
            ...editData,
            details: [
              ...editData.details,
              {
                product_id: "", 
                is_custom_version: 0, 
                is_upgrade: 0,
                version_date: "", 
                from_version_date: "", 
                version_text: "",
                from_version_text: "",
                is_system_plugin: 0, 
                system_plugin_id: "", 
                system_plugin_note: "", 
                ref_product: ""
              },
            ],
          });
    };
    
    const handleRemoveDetail = (index) => {
        const newDetails = [...editData.details];
        newDetails.splice(index, 1);
        setEditData({ ...editData, details: newDetails });
    };

    const validate = async (field, value) => {
        if (field === "service_date" && value === "") {
            await setError("service_date", "Service Date is required.");
        } else if (field === "service_no" && value === "") {
            await setError("service_no", "Service No is required.");
        } else if (field === "org_id" && value === "") {
            await setError("org_id", "Organization is required.");
        } else if (field === "status" && value === "") {
            await setError("status", "Status is required.");
        }else if (field === "support_by" && value === "") {
            await setError("support_by", "Support By is required");
        } else {
            await setErrors((prevErrors) => ({ ...prevErrors, [field]: false }));
            await setHelperText((prevHelperText) => ({ ...prevHelperText, [field]: "" }));
        }
    };

    useEffect(() => {
        if (open) {
            setSelectedOrganizationValue(null);
            setSelectedContractValue(null);
            setEditData(rowData);
            setErrors({ doc_no_id: false, service_date: false, service_no: false, org_id: false, status: false });
            setHelperText({});
            setErrorMessage("");
            setSelectedUserValues([]);

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/organizations/select")
                .then((response) => {
                    setOrganizationsData(response.data.data.organizations);
                    if (rowData) {
                        setSelectedOrganizationValue(response.data.data.organizations.find((option) => option.org_id === rowData.org_id));
                    }
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/contracts/select")
                .then((response) => {
                    setContractsData(response.data.data.contracts);
                    if (rowData) {
                        setSelectedContractValue(response.data.data.contracts.find((option) => option.contract_id === rowData.contract_id));
                    }
                })
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/users/select").then((response) => {
                setUsers(response.data.data.users);
                setSelectedUserValues(response.data.data.users.find((option) => option.user_id === rowData.support_by));
            });

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/products/select")
                .then((response) => setProductsData(response.data.data.products))
                .catch((error) => console.log(error));

            axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + "api/v1/system-plugins/select")
                .then((response) => setSystemPluginsData(response.data.data.systemPlugins))
                .catch((error) => console.log(error));
        }
    }, [open, rowData, identity.user.role_id, identity.user.user_id]);

    const handleCreateService = async () => {
        let isValid = true;

        if (editData["service_no"] === "") isValid = false;
        else if (editData["org_id"] === "" && editData["org_id"] > 0) isValid = false;
        else if (editData["status"] === "") isValid = false;
        else if (editData["support_by"] === "") isValid = false;

        if (editData.details.length > 0) {
            for (let index = 0; index < editData.details.length; index++) {
                const detailElement = editData.details[index];
                if (detailElement.product_id === "") {
                    isValid = false;
                    break;
                } else if (detailElement.is_system_plugin === 1 && (detailElement.system_plugin_id === "" || detailElement.system_plugin_id === null)) {
                    isValid = false;
                    break;
                }
            }
        }

        Object.keys(editData).forEach(async (field) => {
            await validate(field, editData[field]);
        });

        if (isValid) {
            await setErrorMessage("");
            await createService();
        } else {
            await setErrorMessage(`Please fill all the required fields.`);
        }
    };

    const createService = async () => {
        try {
            const { service_no, org_id, email, office_phone, mobile_phone, pic_name, ref_org, ref_service_id, pending_remarks, remarks, status, support_by, details } = editData;
            const service_date = moment(editData.service_date).format("YYYY-MM-DD");
            const contract_id = selectedContractValue ? selectedContractValue.contract_id : 0;

            const response = await axios.patch(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/services/${rowData.service_id}`, {
                service_date,
                service_no,
                org_id,
                email,
                office_phone,
                mobile_phone,
                pic_name,
                contract_id: contract_id ? contract_id : 0,
                ref_org,
                ref_service_id,
                pending_remarks,
                remarks,
                status,
                support_by,
                details,
            });

            if (response.status === 200) {
                handleClose();
                Swal.fire({
                    title: "Success",
                    text: "Service information updated successfully",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchData();
            }
        } catch (error) {
            console.log(error);
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage("An error occurred while creating the service. Please try again later.");
            }
        }
    };

    if (!rowData) return null;

    return (
        <Dialog
            maxWidth="lg"
            fullWidth
            open={open}
            onClose={(event, reason) => {
                if (reason !== "backdropClick") {
                    handleClose();
                }
            }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Edit Service Information</DialogTitle>
            {errorMessage && (
                <Alert variant="outlined" severity="error" style={{ marginLeft: "24px", marginRight: "24px" }}>
                    {errorMessage}
                </Alert>
            )}
            <DialogContent style={{ paddingBottom: "0px" }}>
                <Divider style={{ paddingTop: "0px", paddingBottom: "4px" }}>
                    <Chip label="Service Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Service Date"
                                inputFormat="YYYY-MM-DD"
                                value={editData.service_date}
                                onChange={(newValue) => {
                                    setEditData({ ...editData, service_date: newValue, });
                                    validate("service_date", newValue);
                                }}
                                renderInput={(params) => <TextField {...params} required fullWidth margin="dense" error={errors.service_date} helperText={helperText.service_date} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="service_no"
                            label="Service No"
                            type="text"
                            fullWidth
                            required
                            value={editData.service_no}
                            error={errors.service_no}
                            helperText={helperText.service_no}
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                                setEditData({ ...editData, service_no: e.target.value ? e.target.value.toUpperCase() : "", });
                                validate("service_no", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            margin="dense"
                            autoComplete="off"
                            id="status"
                            label="Status"
                            type="text"
                            select
                            fullWidth
                            required
                            defaultValue={rowData.status}
                            error={errors.status}
                            helperText={helperText.status}
                            onChange={(e) => {
                                setEditData({ ...editData, status: e.target.value, });
                                validate("status", e.target.value);
                            }}
                        >
                            {ServiceStatus.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                    {status.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={organizationsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.org_name}
                            onChange={(event, value) => {
                                setSelectedOrganizationValue(value);
                                setEditData({ ...editData, org_id: value ? value.org_id : "", });
                                validate("org_id", value ? value.org_id : "");
                            }}
                            isOptionEqualToValue={(option, value) => option.org_id === value.org_id}
                            value={selectedOrganizationValue}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.org_id}>
                                        {option.org_name}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="org_id" label="Organization" fullWidth required error={errors.org_id} helperText={helperText.org_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="email"
                            label="Email"
                            type="text"
                            fullWidth
                            value={editData.email}
                            error={errors.email}
                            helperText={helperText.email}
                            onChange={(e) => {
                                setEditData({ ...editData, email: e.target.value, });
                                validate("email", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="office_phone"
                            label="Office Phone"
                            type="text"
                            fullWidth
                            value={editData.office_phone}
                            error={errors.office_phone}
                            helperText={helperText.office_phone}
                            onChange={(e) => {
                                setEditData({ ...editData, office_phone: e.target.value, });
                                validate("office_phone", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="mobile_phone"
                            label="Mobile Phone"
                            type="text"
                            fullWidth
                            value={editData.mobile_phone}
                            error={errors.mobile_phone}
                            helperText={helperText.mobile_phone}
                            onChange={(e) => {
                                setEditData({ ...editData, mobile_phone: e.target.value, });
                                validate("mobile_phone", e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pic_name"
                            label="PIC Name"
                            type="text"
                            fullWidth
                            value={editData.pic_name}
                            error={errors.pic_name}
                            helperText={helperText.pic_name}
                            onChange={(e) => {
                                setEditData({ ...editData, pic_name: e.target.value, });
                                validate("pic_name", e.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Autocomplete
                            freeSolo
                            options={contractsData}
                            groupBy={(option) => {
                                const country = Countries.find((country) => country.id === option.country_id);
                                return country ? country.name : "Unknown";
                            }}
                            getOptionLabel={(option) => option.contract_description || ""}
                            onChange={(event, value) => {
                                setSelectedContractValue(value);
                                setEditData({ ...editData, contract_id: value ? value.contract_id : "", });
                                validate("contract_id", value ? value.contract_id : "");
                            }}
                            isOptionEqualToValue={(option, value) => option.contract_id === value.contract_id}
                            value={selectedContractValue || ""}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.contract_id}>
                                        {option.contract_description}
                                    </li>
                                );
                            }}
                            renderInput={(params) => <TextField {...params} margin="dense" id="contract_id" label="Contract" fullWidth error={errors.contract_id} helperText={helperText.contract_id} />}
                            renderGroup={(params) => {
                                return (
                                    <li key={params.key}>
                                        <GroupHeader>{params.group}</GroupHeader>
                                        <GroupItems>{params.children}</GroupItems>
                                    </li>
                                );
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_org"
                            label="Old Organization (Import)"
                            type="text"
                            fullWidth
                            value={editData.ref_org}
                            inputProps={{
                                style: { textTransform: "uppercase" },
                            }}
                            onChange={(e) => {
                                setEditData({ ...editData, ref_org: e.target.value ? e.target.value.toUpperCase() : "", });
                            }}
                        />
                    </Grid>
                    { identity.user.role_id === 1 &&
                        <Grid item xs={6}>
                            <Autocomplete
                                options={users}
                                getOptionLabel={(users) => users.name || ""}
                                defaultValue={selectedUserValues}
                                value={selectedUserValues}
                                onChange={(event, value) => {
                                    setSelectedUserValues(value);
                                    setEditData({ ...editData, support_by: value ? value.user_id : "", });
                                    validate("support_by", value ? value.user_id : "");
                                }}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.user_id}>
                                            {option.name}
                                        </li>
                                    );
                                }}
                                renderInput={(params) => <TextField 
                                    {...params} 
                                    margin="dense" 
                                    variant="outlined" 
                                    label="Support By"
                                    required
                                    error={errors.support_by} 
                                    helperText={helperText.support_by}
                                />}
                            />
                        </Grid>
                    }
                    {/* <Grid item xs={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="ref_service_id"
                            label="Reference Service No"
                            type="text"
                            fullWidth
                            required
                            value={editData.ref_service_id}
                            onChange={(e) => {
                                setEditData({ ...editData, ref_service_id: e.target.value, });
                            }}
                        />
                    </Grid> */}
                </Grid>
                <Grid container spacing={2} sx={{ pt: 1 }}>
                    <Grid item xs={12} style={{ textAlign: "center" }}>
                        <Button variant="contained" style={{ backgroundColor: "#1e73dd", color: "white" }} onClick={handleAddDetail}>
                            Add Service Detail
                        </Button>
                    </Grid>
                </Grid>
                {productsData.length > 0 && systemPluginsData.length > 0 && editData && editData.details && editData.details.map((detail, index) => (
                    <Grid item container spacing={2} key={index} sx={{ pt: 0, mt: 1.5, pb: 1.5, border: "1px solid #c4c4c4" }}>
                        <Grid item xs={11}>
                            <Accordion defaultExpanded={true} sx={{ backgroundColor: "#565656" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                    <Typography sx={{ color: "#ffffff" }}>Product #{index + 1}</Typography>
                                </AccordionSummary>
                                <AccordionDetails sx={{ backgroundColor: "#f3f3f3" }}>
                                <Grid item container spacing={2}>
                                    <Grid item xs={12} sx={{ mt: 1 }}>
                                        <Autocomplete
                                            options={productsData}
                                            groupBy={(option) => {
                                                const country = Countries.find((country) => country.id === option.country_id);
                                                return country ? country.name : "Unknown";
                                            }}
                                            defaultValue={productsData.find((option) => option.product_id === detail.product_id)}
                                            isOptionEqualToValue={(option, value) => option.product_id === value.product_id}
                                            getOptionLabel={(option) => option.product_description}
                                            onChange={(e, value) => {
                                                handleDetailChange(index, "product_id", value ? value.product_id : "")
                                            }}
                                            renderOption={(props, option) => {
                                                return (
                                                    <li {...props} key={option.product_id}>
                                                        {option.product_description}
                                                    </li>
                                                );
                                            }}
                                            renderInput={(params) => <TextField 
                                                {...params} 
                                                id="product_id" 
                                                label="Product" 
                                                fullWidth 
                                                required 
                                                error={detail.product_id === "" ? true : false} 
                                                helperText={detail.product_id === "" ? "Product is required." : ""} 
                                            />}
                                            renderGroup={(params) => {
                                                return (
                                                    <li key={params.key}>
                                                        <GroupHeader>{params.group}</GroupHeader>
                                                        <GroupItems>{params.children}</GroupItems>
                                                    </li>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                id={`is_custom_version_${index}`}
                                                margin="dense"
                                                checked={detail.is_custom_version === 1 ? true : false}    
                                                onChange={(e) => {
                                                    handleDetailChange(index, "is_custom_version", e.target.checked ? 1 : 0)
                                                }}
                                            />}
                                            label="Is Custom Version"
                                            sx={{ mt: 1.8 }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <FormControlLabel 
                                            control={<Checkbox 
                                                id={`is_upgrade_${index}`}
                                                margin="dense"
                                                checked={detail.is_upgrade === 1 ? true : false}    
                                                onChange={(e) => {
                                                    handleDetailChange(index, "is_upgrade", e.target.checked ? 1 : 0)
                                                }}
                                            />}
                                            label="Is Upgrade"
                                            sx={{ mt: 1.8 }}
                                        />
                                    </Grid>
                                    { detail.is_custom_version === 0 &&
                                        <Grid item xs={12} sm={3}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="System Version"
                                                    inputFormat="YYYY-MM-DD"
                                                    value={detail.version_date}
                                                    onChange={(newValue) => {
                                                        handleDetailChange(index, "version_date", newValue === null || newValue === "" ? "" : moment(newValue).format("YYYY-MM-DD"))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} fullWidth margin="dense" error={false} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 0 && detail.is_upgrade === 1 &&
                                        <Grid item xs={12} sm={3}>
                                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                                <DatePicker
                                                    label="From System Version"
                                                    inputFormat="YYYY-MM-DD"
                                                    value={detail.from_version_date}
                                                    onChange={(newValue) => {
                                                        handleDetailChange(index, "from_version_date", newValue === null || newValue === "" ? "" : moment(newValue).format("YYYY-MM-DD"))
                                                    }}
                                                    renderInput={(params) => <TextField {...params} fullWidth margin="dense" error={false} />}
                                                />
                                            </LocalizationProvider>
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 1 && 
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id={`version_text_${index}`}
                                                label="System Version (String)"
                                                type="text"
                                                fullWidth
                                                value={detail.version_text}
                                                onChange={(e) => {
                                                    handleDetailChange(index, "version_text", e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    }
                                    { detail.is_custom_version === 1 && detail.is_upgrade === 1 && 
                                        <Grid item xs={12} sm={3}>
                                            <TextField
                                                autoComplete="off"
                                                margin="dense"
                                                id={`from_version_text_${index}`}
                                                label="From System Version (String)"
                                                type="text"
                                                fullWidth
                                                value={detail.from_version_text}
                                                onChange={(e) => {
                                                    handleDetailChange(index, "from_version_text", e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    }
                                    <Grid item xs={12} sm={9}>
                                        <TextField
                                            autoComplete="off"
                                            id={`ref_product_${index}`}
                                            label="Old Product (Import)"
                                            type="text"
                                            fullWidth
                                            value={detail.ref_product}
                                            inputProps={{
                                                style: { textTransform: "uppercase" },
                                            }}
                                            onChange={(e) => {
                                                handleDetailChange(index, "ref_product",  e.target.value ? e.target.value.toUpperCase() : "")
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                    <FormControlLabel
                                        control={
                                        <Checkbox
                                            id={`is_system_plugin_${index}`}
                                            checked={detail.is_system_plugin === 1 ? true : false}
                                            onChange={(e) => {
                                                handleDetailChange(index, "is_system_plugin",  e.target.checked ? 1 : 0)
                                            }}
                                        />
                                        }
                                        label="Is Use System Plugin"
                                        sx={{ mt: 1 }}
                                    />
                                    </Grid>
                                    {detail.is_system_plugin === 1 &&
                                        <>
                                            <Grid item xs={12}>
                                                <Autocomplete
                                                    options={systemPluginsData}
                                                    groupBy={(option) => {
                                                        const country = Countries.find((country) => country.id === option.country_id);
                                                        return country ? country.name : "Unknown";
                                                    }}
                                                    defaultValue={systemPluginsData.find((option) => option.system_plugin_id === detail.system_plugin_id)}
                                                    isOptionEqualToValue={(option, value) => option.system_plugin_id === value.system_plugin_id}
                                                    getOptionLabel={(option) => option.system_plugin_description}
                                                    onChange={(e, value) => {
                                                        handleDetailChange(index, "system_plugin_id", value ? value.system_plugin_id : "")
                                                    }}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <li {...props} key={option.system_plugin_id}>
                                                                {option.system_plugin_description}
                                                            </li>
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField 
                                                        {...params} 
                                                        id="system_plugin_id" 
                                                        label="System Plugin" 
                                                        fullWidth 
                                                        required 
                                                        error={detail.system_plugin_id === "" || detail.system_plugin_id === null ? true : false} 
                                                        helperText={detail.system_plugin_id === "" || detail.system_plugin_id === null ? "System Plugin is required." : ""} 
                                                    />}
                                                    renderGroup={(params) => {
                                                        return (
                                                            <li key={params.key}>
                                                                <GroupHeader>{params.group}</GroupHeader>
                                                                <GroupItems>{params.children}</GroupItems>
                                                            </li>
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField fullWidth label="System Plugin Note" multiline rows={4} value={detail.system_plugin_note} onChange={(e) => handleDetailChange(index, "system_plugin_note", e.target.value)} />
                                            </Grid>
                                        </>
                                    }
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                        <Grid item xs={1}>
                            <Button variant="contained" color="secondary" onClick={() => handleRemoveDetail(index)} >
                                X
                            </Button>
                        </Grid>
                    </Grid>
                ))}
                <Divider style={{ paddingTop: "16px", paddingBottom: "4px" }}>
                    <Chip label="Additional Details" />
                </Divider>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="remarks"
                            label="Remark (Job Description)"
                            type="text"
                            multiline
                            rows={8}
                            fullWidth
                            value={editData.remarks}
                            onChange={(e) => {
                                setEditData({ ...editData, remarks: e.target.value, });
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                        <TextField
                            autoComplete="off"
                            margin="dense"
                            id="pending_remarks"
                            label="Pending Remark (Outstanding Jobs)"
                            type="text"
                            multiline
                            rows={8}
                            fullWidth
                            value={editData.pending_remarks}
                            onChange={(e) => {
                                setEditData({ ...editData, pending_remarks: e.target.value, });
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{ margin: "12px" }}>
                <Button onClick={handleCreateService} variant="contained" color="primary" style={{ marginRight: "6px", color: "white" }}>
                    Update
                </Button>
                <Button onClick={handleClose} variant="contained" color="error" style={{ marginRight: "6px", color: "white" }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}
