import { useEffect, useState, useRef, useCallback } from "react";
import { Container, Chip, TextField, MenuItem, Button, Grid, Typography, ButtonGroup, CardHeader } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import { GetTokenIdentity } from "../utils/Token";
import { Countries } from "../utils/Lookup";
import { Icons } from "../utils/Icon";
import { CustomStyles } from "../utils/Styled";

import axios from "axios";
import MaterialTable from "material-table";

import CreateUserForm from "../components/forms/users/Create";
import EditUserForm from "../components/forms/users/Edit";
import DeleteUserForm from "../components/forms/users/Delete";
import ChangePasswordForm from "../components/forms/users/ChangePassword";

export const UsersPage = () => {
    const { token, logout } = useAuth();
    const identity = GetTokenIdentity(token);

    const classes = CustomStyles;

    const fullNameInputRef = useRef(null);
    const emailInputRef = useRef(null);

    const [data, setData] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [filter, setFilter] = useState({ country_id: "", status: 0 });
    const [sort, setSort] = useState({});

    const [open, setOpen] = useState({
        create: false,
        edit: false,
        changePassword: false,
    });

    const [selectedRow, setSelectedRow] = useState({});

    const handleOpen = (type, row) => {
        setOpen({ ...open, [type]: true });
        setSelectedRow(row);
    };

    const handleClose = (type) => {
        setOpen({ ...open, [type]: false });
        setSelectedRow(null);
    };

    const handleResetFilter = () => {
        setPage(0);
        setFilter({ country_id: "", status: 0 });
    };

    const handleFilterChange = (event) => {
        setPage(0);
        setFilter({ ...filter, [event.target.name]: event.target.value });
    };

    const fetchData = useCallback(async () => {
        try {
            const { data } = await axios.get(process.env.REACT_APP_WEBSITE_BACKEND_URL + `api/v1/users`, {
                params: {
                    page: page + 1,
                    perPage,
                    filter: JSON.stringify(filter),
                    sort,
                },
            });
            setData(data.data.users);
            setCount(data.data.total);
        } catch (error) {
            if (error.response.status === 401) {
                console.log("Unauthorized: ", error);
                logout("Your account has been logged out of a session.");
            } else {
                console.log(error);
            }
        }
    }, [page, perPage, filter, sort, logout]);

    useEffect(() => {
        fetchData();
    }, [fetchData, page, perPage, filter, sort]);

    const columns = [
        { title: "Full Name", field: "full_name" },
        { title: "Email", field: "email" },
        { title: "Role", field: "role_name" },
        { title: "Manage By", field: "country_name" },
        {
            title: "Status",
            field: "status",
            render: (rowData) => <Chip label={rowData.status === 0 ? "Active" : "Inactive"} color={rowData.status === 0 ? "success" : "error"} size="small" style={{ color: "white" }} />,
        },
    ];

    return (
        <div>
            <div className={classes.background} />
            <Container maxWidth="xl" sx={{ py: 2 }} style={{ padding: 14 }}>
                {identity.user.role_id === 1 ? (
                    <MaterialTable
                        title="User List"
                        icons={Icons}
                        columns={columns}
                        data={data}
                        page={page}
                        totalCount={count}
                        style={{ padding: "24px" }}
                        options={{
                            pageSize: perPage,
                            pageSizeOptions: [10, 20, 30, 50],
                            search: false,
                            filtering: false,
                            sorting: false,
                            actionsColumnIndex: -1,
                            headerStyle: {
                                fontWeight: "bold",
                                backgroundColor: "#f1f1f1",
                                color: "#212122",
                            },
                            maxBodyHeight: "600px",
                            paginationType: "stepped",
                            padding: "dense",
                            draggable: false,
                        }}
                        localization={{
                            pagination: {
                                labelRowsPerPage: "",
                                labelDisplayedRows: "{from}-{to} of {count}",
                                labelRowsSelect: "rows",
                            },
                            toolbar: {
                                nRowsSelected: "{0} row(s) selected",
                            },
                            header: {
                                actions: "Actions",
                            },
                            body: {
                                emptyDataSourceMessage: "No data to display",
                            },
                        }}
                        actions={[
                            {
                                icon: () => <Icons.Edit />,
                                tooltip: "Edit User Information",
                                onClick: (event, rowData) => handleOpen("edit", rowData),
                            },
                            {
                                icon: () => <Icons.Password />,
                                tooltip: "Change User Password",
                                onClick: (event, rowData) => handleOpen("changePassword", rowData),
                            },
                            {
                                icon: () => <Icons.Delete />,
                                tooltip: "Delete User",
                                onClick: (event, rowData) => DeleteUserForm(rowData, fetchData),
                            },
                        ]}
                        onChangePage={(page) => setPage(page)}
                        onChangeRowsPerPage={(perPage) => setPerPage(perPage)}
                        components={{
                            Toolbar: (props) => (
                                <>
                                    <div>
                                        <Grid container spacing={2} sx={{ px: 0.1, pb: 3.5 }}>
                                            <Grid item xs={12} sm={3}>
                                                <CardHeader
                                                    sx={{ p: 0 }}
                                                    title={
                                                        <Typography component="h1" variant="h5" color="primary" fontWeight="bold">
                                                            User List
                                                        </Typography>
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={9} style={{ textAlign: "right" }}>
                                                <ButtonGroup>
                                                    <Button
                                                        onClick={() => handleOpen("create", null)}
                                                        variant="outlined"
                                                        startIcon={<Icons.AddIcon fontWeight="bold" />}
                                                        color="primary"
                                                        sx={{
                                                            fontWeight: 800,
                                                            paddingTop: 0.5,
                                                            paddingBottom: 0.5,
                                                        }}
                                                    >
                                                        Create
                                                    </Button>
                                                </ButtonGroup>
                                            </Grid>
                                        </Grid>
                                    </div>

                                    <form>
                                        <Grid container spacing={2} sx={{ paddingBottom: 1.5 }}>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="Full Name"
                                                    name="full_name"
                                                    variant="outlined"
                                                    autoFocus={fullNameInputRef.current === document.activeElement}
                                                    value={filter.full_name}
                                                    onChange={handleFilterChange}
                                                    inputRef={fullNameInputRef}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <TextField
                                                    autoComplete="off"
                                                    fullWidth
                                                    label="Email"
                                                    name="email"
                                                    variant="outlined"
                                                    autoFocus={emailInputRef.current === document.activeElement}
                                                    value={filter.email}
                                                    onChange={handleFilterChange}
                                                    inputRef={emailInputRef}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={1.5}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    label="Status"
                                                    variant="outlined"
                                                    name="status"
                                                    value={filter.status}
                                                    onChange={handleFilterChange}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                    <MenuItem value={""}>All</MenuItem>
                                                    <MenuItem value={0}>Active</MenuItem>
                                                    <MenuItem value={1}>Inactive</MenuItem>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={1.5}>
                                                <TextField
                                                    fullWidth
                                                    select
                                                    label="Manage By"
                                                    variant="outlined"
                                                    name="country_id"
                                                    value={filter.country_id}
                                                    onChange={handleFilterChange}
                                                    size="small"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                >
                                                    <MenuItem value={""}>All</MenuItem>
                                                    {Countries.map((country) => (
                                                        <MenuItem key={country.id} value={country.id}>
                                                            {country.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12} sm={1}>
                                                <Button fullWidth variant="contained" color="error" sx={{ color: "#ffffff", marginTop: "2px" }} onClick={handleResetFilter}>
                                                    Reset
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </>
                            ),
                        }}
                    />
                ) : (
                    <Typography variant="subtitle2">You don't have permission to view users.</Typography>
                )}
                <CreateUserForm open={open.create} handleClose={() => handleClose("create")} fetchData={fetchData} />
                <EditUserForm open={open.edit} handleClose={() => handleClose("edit")} rowData={selectedRow} fetchData={fetchData} />
                <ChangePasswordForm open={open.changePassword} handleClose={() => handleClose("changePassword")} rowData={selectedRow} />
            </Container>
        </div>
    );
};
